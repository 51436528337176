/*!------------------------------------------------------------------
[MAIN STYLESHEET]
PROJECT:	JACKCREATIVE
-------------------------------------------------------------------*/
/* ====== index =====
1. Default Styles
2. Button Styles
3.  Spacing Styles
4.  Grid Styles
5.  Navbar Styles
6.  Hero Section Styles
7.  Services Page Styles
8.  Resume Section Styles
9.  Project Page Styles
10. Counter Up Section Styles
11. Feedback Section Styles
12. Blog Page Styles
13. Pagination Section Styles
14. contact Page Styles
15. 404 Page Styles
16. Hero inner Page Section Styles
17. Footer Section Styles


===================== */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
/********** 
 1. Default  Styles 
**********/
.row {
  --bs-gutter-x: 1.75rem;
}

/*Font */
body {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: #404152;
  background: #fff;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #060A11;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  line-height: 1.3;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1rem;
  color: #404152;
  line-height: 2;
}

a {
  font-size: 1rem;
  color: #404152;
  line-height: 1.5;
  text-decoration: none;
  display: inline-block;
}
a a:hover {
  color: #404152;
}

/********** 
   Custom Styles 
**********/
/* Dropdown Styles */
.dropdown-item {
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #060a11 !important;
  background: none !important;
  background-color: #e9ecef;
}

/* Card Styles */
.card {
  border: none;
}

/* section-header style */
.section-header {
  padding: 4rem 0 3rem;
}
@media (max-width: 991.98px) {
  .section-header {
    text-align: center;
  }
}
.section-header h2 {
  position: relative;
  margin-bottom: 12px;
}
@media (max-width: 575.98px) {
  .section-header h2 {
    margin-bottom: 0;
  }
}

/* load more style */
.next-more,
.load-more {
  color: #060A11;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 500;
}
.next-more i,
.load-more i {
  margin-right: 10px;
}

.lode-next,
.next-blog {
  display: none;
}

/********** 
  2. Button Styles 
**********/
.btn {
  letter-spacing: 0.5px;
  font-weight: 700;
}
.btn:hover {
  color: #fff;
}
.btn-check:focus + .btn, .btn:focus {
  color: #fff !important;
  outline: 0;
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active {
  color: #fff !important;
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  color: #fff !important;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.1;
}
@media (max-width: 575.98px) {
  .btn {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
}

.btn-primary {
  position: relative;
  color: #fff;
  height: 55px;
  text-align: center;
  line-height: 55px;
  text-transform: capitalize;
  background: #060A11;
  border: 1px solid transparent;
  overflow: hidden;
  transition: 0.7s all ease-in-out;
}
.btn-primary span {
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.7s all ease-in-out;
  z-index: 2;
}
.btn-primary span svg {
  transform: translateY(-2px);
  margin-left: 5px;
}
.btn-primary span:nth-child(1) {
  transition: 0.7s all ease-in-out;
  transform: translateY(-16px);
}
.btn-primary::before {
  content: "";
  position: absolute;
  bottom: -250px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 250px;
  background: #404152;
  border-radius: 50%;
  transition: 0.7s all ease-in-out;
}
.btn-primary:hover {
  border: 1px solid transparent;
  background: #404152;
}
.btn-primary:hover span {
  transform: translateY(-100%);
}
.btn-primary:hover span:nth-child(1) {
  transform: translateY(-150px);
}
.btn-primary:hover span:nth-child(2) {
  transform: translateY(-36px);
}
.btn-primary:hover::before {
  bottom: -150px;
}
btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary:active, .btn-primary:active:focus, .btn-primary:hover:focus {
  color: #fff !important;
  background: #060A11;
  border: 1px solid transparent;
}
.btn-secondary {
  background: rgba(7, 12, 19, 0.04);
  color: #060A11;
  border: 1px solid transparent;
  text-transform: capitalize;
  position: relative;
  height: 55px;
  text-align: center;
  line-height: 55px;
  overflow: hidden;
  transition: 0.7s all ease-in-out;
}
.btn-secondary span {
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.7s all ease-in-out;
  z-index: 2;
}
.btn-secondary span:nth-child(1) {
  color: #060A11;
  transition: 0.7s all ease-in-out;
  transform: translateY(-18px);
}
.btn-secondary span:nth-child(2) {
  color: #fff;
}
.btn-secondary::before {
  content: "";
  position: absolute;
  bottom: -260px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 250px;
  background: #060A11;
  border-radius: 50%;
  transition: 0.7s all ease-in-out;
}
.btn-secondary:hover {
  border: 1px solid transparent;
  background: #404152;
}
.btn-secondary:hover span {
  transform: translateY(-100%);
}
.btn-secondary:hover span:nth-child(1) {
  transform: translateY(-150px);
}
.btn-secondary:hover span:nth-child(2) {
  transform: translateY(-39px);
}
.btn-secondary:hover::before {
  bottom: -150px;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus, .btn-secondary:active, .btn-secondary:active:focus, .btn-secondary:hover:focus {
  color: #060A11 !important;
  background: rgba(7, 12, 19, 0.04);
  border: 1px solid transparent;
}
.btn-links {
  background: rgba(7, 12, 19, 0.04);
  color: #060A11;
  border: 1px solid transparent;
  position: relative;
  font-weight: 700;
  text-transform: capitalize;
  height: 50px;
  text-align: center;
  line-height: 50px;
  overflow: hidden;
  transition: 0.7s all ease-in-out;
}
.btn-links span {
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.7s all ease-in-out;
  z-index: 2;
}
.btn-links span:nth-child(1) {
  transition: 0.7s all ease-in-out;
  transform: translateY(-16px);
}
.btn-links span:nth-child(2) svg path {
  stroke: #fff;
}
.btn-links span svg {
  transform: translateY(-2px);
  margin-left: 4px;
}
.btn-links::before {
  content: "";
  position: absolute;
  bottom: -250px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 250px;
  background: #404152;
  border-radius: 50%;
  transition: 0.7s all ease-in-out;
}
.btn-links:hover {
  border: 1px solid transparent;
  background: #404152;
}
.btn-links:hover span {
  transform: translateY(-100%);
}
.btn-links:hover span:nth-child(1) {
  color: #060A11;
  transform: translateY(-150px);
}
.btn-links:hover span:nth-child(2) {
  color: #fff;
  transform: translateY(-32px);
}
.btn-links:hover::before {
  bottom: -150px;
}
.btn-check:focus + .btn-links, .btn-links:focus, .btn-links:active, .btn-links:active:focus, .btn-links:hover:focus {
  color: #060A11 !important;
  background: rgba(7, 12, 19, 0.04);
  border: 1px solid transparent;
}
.btn-white {
  background: rgba(255, 255, 255, 0.06);
  color: #fff;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
  position: relative;
  height: 50px;
  text-align: center;
  line-height: 50px;
  overflow: hidden;
}
.btn-white span {
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.7s all ease-in-out;
  z-index: 2;
}
.btn-white span:nth-child(1) {
  color: #fff;
  transition: 0.7s all ease-in-out;
  transform: translateY(-16px);
}
.btn-white span:nth-child(2) {
  color: #fff;
}
.btn-white::before {
  content: "";
  position: absolute;
  bottom: -260px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 250px;
  background: rgba(255, 255, 255, 0.0048);
  border-radius: 50%;
  transition: 0.7s all ease-in-out;
}
@media (max-width: 575.98px) {
  .btn-white::before {
    width: 500px;
  }
}
.btn-white:hover {
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.06);
}
.btn-white:hover span {
  transform: translateY(-100%);
}
.btn-white:hover span:nth-child(1) {
  transform: translateY(-150px);
}
.btn-white:hover span:nth-child(2) {
  transform: translateY(-32px);
}
.btn-white:hover::before {
  bottom: -150px;
}
.btn-check:focus + .btn-white, .btn-white:focus, .btn-white:active, .btn-white:active:focus, .btn-white:hover:focus {
  color: #060A11 !important;
  background: rgba(7, 12, 19, 0.04);
  border: 1px solid transparent;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 1rem 1.3rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
}

btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary:active, .btn-primary:active:focus, .btn-primary:hover:focus {
  color: #fff !important;
  background: #060a11;
  border: 1px solid transparent;
}

/********** 
 3. Grid  Styles 
**********/
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.875rem);
  padding-left: var(--bs-gutter-x, 0.875rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
.container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
.container-sm,
.container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
.container-md,
.container-sm,
.container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
    max-width: 1080px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
    max-width: 1170px;
  }
}
.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-10 {
    width: 10% !important;
  }

  .w-sm-15 {
    width: 15% !important;
  }

  .w-sm-20 {
    width: 20% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-30 {
    width: 30% !important;
  }

  .w-sm-35 {
    width: 35% !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-45 {
    width: 45% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-55 {
    width: 55% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-65 {
    width: 65% !important;
  }

  .w-sm-70 {
    width: 70% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-80 {
    width: 80% !important;
  }

  .w-sm-85 {
    width: 85% !important;
  }

  .w-sm-90 {
    width: 90% !important;
  }

  .w-sm-95 {
    width: 95% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-md-10 {
    width: 10% !important;
  }

  .w-md-15 {
    width: 15% !important;
  }

  .w-md-20 {
    width: 20% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-45 {
    width: 45% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-55 {
    width: 55% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-85 {
    width: 85% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .w-md-95 {
    width: 95% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-10 {
    width: 10% !important;
  }

  .w-lg-15 {
    width: 15% !important;
  }

  .w-lg-20 {
    width: 20% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-35 {
    width: 35% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-45 {
    width: 45% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-55 {
    width: 55% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-65 {
    width: 65% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-85 {
    width: 85% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }

  .w-lg-95 {
    width: 95% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-10 {
    width: 10% !important;
  }

  .w-xl-15 {
    width: 15% !important;
  }

  .w-xl-20 {
    width: 20% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-30 {
    width: 30% !important;
  }

  .w-xl-35 {
    width: 35% !important;
  }

  .w-xl-40 {
    width: 40% !important;
  }

  .w-xl-45 {
    width: 45% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-55 {
    width: 55% !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-65 {
    width: 65% !important;
  }

  .w-xl-70 {
    width: 70% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-80 {
    width: 80% !important;
  }

  .w-xl-85 {
    width: 85% !important;
  }

  .w-xl-90 {
    width: 90% !important;
  }

  .w-xl-95 {
    width: 95% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-10 {
    width: 10% !important;
  }

  .w-xxl-15 {
    width: 15% !important;
  }

  .w-xxl-20 {
    width: 20% !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-30 {
    width: 30% !important;
  }

  .w-xxl-35 {
    width: 35% !important;
  }

  .w-xxl-40 {
    width: 40% !important;
  }

  .w-xxl-45 {
    width: 45% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-55 {
    width: 55% !important;
  }

  .w-xxl-60 {
    width: 60% !important;
  }

  .w-xxl-65 {
    width: 65% !important;
  }

  .w-xxl-70 {
    width: 70% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-80 {
    width: 80% !important;
  }

  .w-xxl-85 {
    width: 85% !important;
  }

  .w-xxl-90 {
    width: 90% !important;
  }

  .w-xxl-95 {
    width: 95% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }
}
/********** 
 4. Spacing  Styles 
**********/
.m-0 {
  margin: 0rem !important;
}

.mx-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.me-0 {
  margin-right: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.ms-0 {
  margin-left: 0rem !important;
}

.m-n0 {
  margin: -0rem !important;
}

.mx-n0 {
  margin-right: -0rem !important;
  margin-left: -0rem !important;
}

.my-n0 {
  margin-top: -0rem !important;
  margin-bottom: -0rem !important;
}

.mt-n0 {
  margin-top: -0rem !important;
}

.me-n0 {
  margin-right: -0rem !important;
}

.mb-n0 {
  margin-bottom: -0rem !important;
}

.ms-n0 {
  margin-left: -0rem !important;
}

.p-0 {
  padding: 0rem !important;
}

.px-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pe-0 {
  padding-right: 0rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.ps-0 {
  padding-left: 0rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.m-3 {
  margin: 0.625rem !important;
}

.mx-3 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.my-3 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.mt-3 {
  margin-top: 0.625rem !important;
}

.me-3 {
  margin-right: 0.625rem !important;
}

.mb-3 {
  margin-bottom: 0.625rem !important;
}

.ms-3 {
  margin-left: 0.625rem !important;
}

.m-n3 {
  margin: -0.625rem !important;
}

.mx-n3 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.my-n3 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.mt-n3 {
  margin-top: -0.625rem !important;
}

.me-n3 {
  margin-right: -0.625rem !important;
}

.mb-n3 {
  margin-bottom: -0.625rem !important;
}

.ms-n3 {
  margin-left: -0.625rem !important;
}

.p-3 {
  padding: 0.625rem !important;
}

.px-3 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.py-3 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.pt-3 {
  padding-top: 0.625rem !important;
}

.pe-3 {
  padding-right: 0.625rem !important;
}

.pb-3 {
  padding-bottom: 0.625rem !important;
}

.ps-3 {
  padding-left: 0.625rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.mx-n6 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.mt-n6 {
  margin-top: -2.5rem !important;
}

.me-n6 {
  margin-right: -2.5rem !important;
}

.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.ms-n6 {
  margin-left: -2.5rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mx-n7 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n7 {
  margin-top: -3rem !important;
}

.me-n7 {
  margin-right: -3rem !important;
}

.mb-n7 {
  margin-bottom: -3rem !important;
}

.ms-n7 {
  margin-left: -3rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.m-8 {
  margin: 4.5rem !important;
}

.mx-8 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.my-8 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.mt-8 {
  margin-top: 4.5rem !important;
}

.me-8 {
  margin-right: 4.5rem !important;
}

.mb-8 {
  margin-bottom: 4.5rem !important;
}

.ms-8 {
  margin-left: 4.5rem !important;
}

.m-n8 {
  margin: -4.5rem !important;
}

.mx-n8 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.my-n8 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.mt-n8 {
  margin-top: -4.5rem !important;
}

.me-n8 {
  margin-right: -4.5rem !important;
}

.mb-n8 {
  margin-bottom: -4.5rem !important;
}

.ms-n8 {
  margin-left: -4.5rem !important;
}

.p-8 {
  padding: 4.5rem !important;
}

.px-8 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.py-8 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-8 {
  padding-top: 4.5rem !important;
}

.pe-8 {
  padding-right: 4.5rem !important;
}

.pb-8 {
  padding-bottom: 4.5rem !important;
}

.ps-8 {
  padding-left: 4.5rem !important;
}

.m-9 {
  margin: 6.25rem !important;
}

.mx-9 {
  margin-right: 6.25rem !important;
  margin-left: 6.25rem !important;
}

.my-9 {
  margin-top: 6.25rem !important;
  margin-bottom: 6.25rem !important;
}

.mt-9 {
  margin-top: 6.25rem !important;
}

.me-9 {
  margin-right: 6.25rem !important;
}

.mb-9 {
  margin-bottom: 6.25rem !important;
}

.ms-9 {
  margin-left: 6.25rem !important;
}

.m-n9 {
  margin: -6.25rem !important;
}

.mx-n9 {
  margin-right: -6.25rem !important;
  margin-left: -6.25rem !important;
}

.my-n9 {
  margin-top: -6.25rem !important;
  margin-bottom: -6.25rem !important;
}

.mt-n9 {
  margin-top: -6.25rem !important;
}

.me-n9 {
  margin-right: -6.25rem !important;
}

.mb-n9 {
  margin-bottom: -6.25rem !important;
}

.ms-n9 {
  margin-left: -6.25rem !important;
}

.p-9 {
  padding: 6.25rem !important;
}

.px-9 {
  padding-right: 6.25rem !important;
  padding-left: 6.25rem !important;
}

.py-9 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

.pt-9 {
  padding-top: 6.25rem !important;
}

.pe-9 {
  padding-right: 6.25rem !important;
}

.pb-9 {
  padding-bottom: 6.25rem !important;
}

.ps-9 {
  padding-left: 6.25rem !important;
}

.m-10 {
  margin: 9.375rem !important;
}

.mx-10 {
  margin-right: 9.375rem !important;
  margin-left: 9.375rem !important;
}

.my-10 {
  margin-top: 9.375rem !important;
  margin-bottom: 9.375rem !important;
}

.mt-10 {
  margin-top: 9.375rem !important;
}

.me-10 {
  margin-right: 9.375rem !important;
}

.mb-10 {
  margin-bottom: 9.375rem !important;
}

.ms-10 {
  margin-left: 9.375rem !important;
}

.m-n10 {
  margin: -9.375rem !important;
}

.mx-n10 {
  margin-right: -9.375rem !important;
  margin-left: -9.375rem !important;
}

.my-n10 {
  margin-top: -9.375rem !important;
  margin-bottom: -9.375rem !important;
}

.mt-n10 {
  margin-top: -9.375rem !important;
}

.me-n10 {
  margin-right: -9.375rem !important;
}

.mb-n10 {
  margin-bottom: -9.375rem !important;
}

.ms-n10 {
  margin-left: -9.375rem !important;
}

.p-10 {
  padding: 9.375rem !important;
}

.px-10 {
  padding-right: 9.375rem !important;
  padding-left: 9.375rem !important;
}

.py-10 {
  padding-top: 9.375rem !important;
  padding-bottom: 9.375rem !important;
}

.pt-10 {
  padding-top: 9.375rem !important;
}

.pe-10 {
  padding-right: 9.375rem !important;
}

.pb-10 {
  padding-bottom: 9.375rem !important;
}

.ps-10 {
  padding-left: 9.375rem !important;
}

.m-11 {
  margin: 10rem !important;
}

.mx-11 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.my-11 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.mt-11 {
  margin-top: 10rem !important;
}

.me-11 {
  margin-right: 10rem !important;
}

.mb-11 {
  margin-bottom: 10rem !important;
}

.ms-11 {
  margin-left: 10rem !important;
}

.m-n11 {
  margin: -10rem !important;
}

.mx-n11 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.my-n11 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.mt-n11 {
  margin-top: -10rem !important;
}

.me-n11 {
  margin-right: -10rem !important;
}

.mb-n11 {
  margin-bottom: -10rem !important;
}

.ms-n11 {
  margin-left: -10rem !important;
}

.p-11 {
  padding: 10rem !important;
}

.px-11 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.py-11 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.pt-11 {
  padding-top: 10rem !important;
}

.pe-11 {
  padding-right: 10rem !important;
}

.pb-11 {
  padding-bottom: 10rem !important;
}

.ps-11 {
  padding-left: 10rem !important;
}

.m-12 {
  margin: 10.5rem !important;
}

.mx-12 {
  margin-right: 10.5rem !important;
  margin-left: 10.5rem !important;
}

.my-12 {
  margin-top: 10.5rem !important;
  margin-bottom: 10.5rem !important;
}

.mt-12 {
  margin-top: 10.5rem !important;
}

.me-12 {
  margin-right: 10.5rem !important;
}

.mb-12 {
  margin-bottom: 10.5rem !important;
}

.ms-12 {
  margin-left: 10.5rem !important;
}

.m-n12 {
  margin: -10.5rem !important;
}

.mx-n12 {
  margin-right: -10.5rem !important;
  margin-left: -10.5rem !important;
}

.my-n12 {
  margin-top: -10.5rem !important;
  margin-bottom: -10.5rem !important;
}

.mt-n12 {
  margin-top: -10.5rem !important;
}

.me-n12 {
  margin-right: -10.5rem !important;
}

.mb-n12 {
  margin-bottom: -10.5rem !important;
}

.ms-n12 {
  margin-left: -10.5rem !important;
}

.p-12 {
  padding: 10.5rem !important;
}

.px-12 {
  padding-right: 10.5rem !important;
  padding-left: 10.5rem !important;
}

.py-12 {
  padding-top: 10.5rem !important;
  padding-bottom: 10.5rem !important;
}

.pt-12 {
  padding-top: 10.5rem !important;
}

.pe-12 {
  padding-right: 10.5rem !important;
}

.pb-12 {
  padding-bottom: 10.5rem !important;
}

.ps-12 {
  padding-left: 10.5rem !important;
}

.m-13 {
  margin: 12rem !important;
}

.mx-13 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.my-13 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.mt-13 {
  margin-top: 12rem !important;
}

.me-13 {
  margin-right: 12rem !important;
}

.mb-13 {
  margin-bottom: 12rem !important;
}

.ms-13 {
  margin-left: 12rem !important;
}

.m-n13 {
  margin: -12rem !important;
}

.mx-n13 {
  margin-right: -12rem !important;
  margin-left: -12rem !important;
}

.my-n13 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.mt-n13 {
  margin-top: -12rem !important;
}

.me-n13 {
  margin-right: -12rem !important;
}

.mb-n13 {
  margin-bottom: -12rem !important;
}

.ms-n13 {
  margin-left: -12rem !important;
}

.p-13 {
  padding: 12rem !important;
}

.px-13 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.py-13 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.pt-13 {
  padding-top: 12rem !important;
}

.pe-13 {
  padding-right: 12rem !important;
}

.pb-13 {
  padding-bottom: 12rem !important;
}

.ps-13 {
  padding-left: 12rem !important;
}

.m-14 {
  margin: 12.5rem !important;
}

.mx-14 {
  margin-right: 12.5rem !important;
  margin-left: 12.5rem !important;
}

.my-14 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important;
}

.mt-14 {
  margin-top: 12.5rem !important;
}

.me-14 {
  margin-right: 12.5rem !important;
}

.mb-14 {
  margin-bottom: 12.5rem !important;
}

.ms-14 {
  margin-left: 12.5rem !important;
}

.m-n14 {
  margin: -12.5rem !important;
}

.mx-n14 {
  margin-right: -12.5rem !important;
  margin-left: -12.5rem !important;
}

.my-n14 {
  margin-top: -12.5rem !important;
  margin-bottom: -12.5rem !important;
}

.mt-n14 {
  margin-top: -12.5rem !important;
}

.me-n14 {
  margin-right: -12.5rem !important;
}

.mb-n14 {
  margin-bottom: -12.5rem !important;
}

.ms-n14 {
  margin-left: -12.5rem !important;
}

.p-14 {
  padding: 12.5rem !important;
}

.px-14 {
  padding-right: 12.5rem !important;
  padding-left: 12.5rem !important;
}

.py-14 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important;
}

.pt-14 {
  padding-top: 12.5rem !important;
}

.pe-14 {
  padding-right: 12.5rem !important;
}

.pb-14 {
  padding-bottom: 12.5rem !important;
}

.ps-14 {
  padding-left: 12.5rem !important;
}

.m-15 {
  margin: 13rem !important;
}

.mx-15 {
  margin-right: 13rem !important;
  margin-left: 13rem !important;
}

.my-15 {
  margin-top: 13rem !important;
  margin-bottom: 13rem !important;
}

.mt-15 {
  margin-top: 13rem !important;
}

.me-15 {
  margin-right: 13rem !important;
}

.mb-15 {
  margin-bottom: 13rem !important;
}

.ms-15 {
  margin-left: 13rem !important;
}

.m-n15 {
  margin: -13rem !important;
}

.mx-n15 {
  margin-right: -13rem !important;
  margin-left: -13rem !important;
}

.my-n15 {
  margin-top: -13rem !important;
  margin-bottom: -13rem !important;
}

.mt-n15 {
  margin-top: -13rem !important;
}

.me-n15 {
  margin-right: -13rem !important;
}

.mb-n15 {
  margin-bottom: -13rem !important;
}

.ms-n15 {
  margin-left: -13rem !important;
}

.p-15 {
  padding: 13rem !important;
}

.px-15 {
  padding-right: 13rem !important;
  padding-left: 13rem !important;
}

.py-15 {
  padding-top: 13rem !important;
  padding-bottom: 13rem !important;
}

.pt-15 {
  padding-top: 13rem !important;
}

.pe-15 {
  padding-right: 13rem !important;
}

.pb-15 {
  padding-bottom: 13rem !important;
}

.ps-15 {
  padding-left: 13rem !important;
}

.m-16 {
  margin: 13.5rem !important;
}

.mx-16 {
  margin-right: 13.5rem !important;
  margin-left: 13.5rem !important;
}

.my-16 {
  margin-top: 13.5rem !important;
  margin-bottom: 13.5rem !important;
}

.mt-16 {
  margin-top: 13.5rem !important;
}

.me-16 {
  margin-right: 13.5rem !important;
}

.mb-16 {
  margin-bottom: 13.5rem !important;
}

.ms-16 {
  margin-left: 13.5rem !important;
}

.m-n16 {
  margin: -13.5rem !important;
}

.mx-n16 {
  margin-right: -13.5rem !important;
  margin-left: -13.5rem !important;
}

.my-n16 {
  margin-top: -13.5rem !important;
  margin-bottom: -13.5rem !important;
}

.mt-n16 {
  margin-top: -13.5rem !important;
}

.me-n16 {
  margin-right: -13.5rem !important;
}

.mb-n16 {
  margin-bottom: -13.5rem !important;
}

.ms-n16 {
  margin-left: -13.5rem !important;
}

.p-16 {
  padding: 13.5rem !important;
}

.px-16 {
  padding-right: 13.5rem !important;
  padding-left: 13.5rem !important;
}

.py-16 {
  padding-top: 13.5rem !important;
  padding-bottom: 13.5rem !important;
}

.pt-16 {
  padding-top: 13.5rem !important;
}

.pe-16 {
  padding-right: 13.5rem !important;
}

.pb-16 {
  padding-bottom: 13.5rem !important;
}

.ps-16 {
  padding-left: 13.5rem !important;
}

.m-17 {
  margin: 14rem !important;
}

.mx-17 {
  margin-right: 14rem !important;
  margin-left: 14rem !important;
}

.my-17 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.mt-17 {
  margin-top: 14rem !important;
}

.me-17 {
  margin-right: 14rem !important;
}

.mb-17 {
  margin-bottom: 14rem !important;
}

.ms-17 {
  margin-left: 14rem !important;
}

.m-n17 {
  margin: -14rem !important;
}

.mx-n17 {
  margin-right: -14rem !important;
  margin-left: -14rem !important;
}

.my-n17 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.mt-n17 {
  margin-top: -14rem !important;
}

.me-n17 {
  margin-right: -14rem !important;
}

.mb-n17 {
  margin-bottom: -14rem !important;
}

.ms-n17 {
  margin-left: -14rem !important;
}

.p-17 {
  padding: 14rem !important;
}

.px-17 {
  padding-right: 14rem !important;
  padding-left: 14rem !important;
}

.py-17 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.pt-17 {
  padding-top: 14rem !important;
}

.pe-17 {
  padding-right: 14rem !important;
}

.pb-17 {
  padding-bottom: 14rem !important;
}

.ps-17 {
  padding-left: 14rem !important;
}

.m-18 {
  margin: 14.5rem !important;
}

.mx-18 {
  margin-right: 14.5rem !important;
  margin-left: 14.5rem !important;
}

.my-18 {
  margin-top: 14.5rem !important;
  margin-bottom: 14.5rem !important;
}

.mt-18 {
  margin-top: 14.5rem !important;
}

.me-18 {
  margin-right: 14.5rem !important;
}

.mb-18 {
  margin-bottom: 14.5rem !important;
}

.ms-18 {
  margin-left: 14.5rem !important;
}

.m-n18 {
  margin: -14.5rem !important;
}

.mx-n18 {
  margin-right: -14.5rem !important;
  margin-left: -14.5rem !important;
}

.my-n18 {
  margin-top: -14.5rem !important;
  margin-bottom: -14.5rem !important;
}

.mt-n18 {
  margin-top: -14.5rem !important;
}

.me-n18 {
  margin-right: -14.5rem !important;
}

.mb-n18 {
  margin-bottom: -14.5rem !important;
}

.ms-n18 {
  margin-left: -14.5rem !important;
}

.p-18 {
  padding: 14.5rem !important;
}

.px-18 {
  padding-right: 14.5rem !important;
  padding-left: 14.5rem !important;
}

.py-18 {
  padding-top: 14.5rem !important;
  padding-bottom: 14.5rem !important;
}

.pt-18 {
  padding-top: 14.5rem !important;
}

.pe-18 {
  padding-right: 14.5rem !important;
}

.pb-18 {
  padding-bottom: 14.5rem !important;
}

.ps-18 {
  padding-left: 14.5rem !important;
}

.m-19 {
  margin: 15rem !important;
}

.mx-19 {
  margin-right: 15rem !important;
  margin-left: 15rem !important;
}

.my-19 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.mt-19 {
  margin-top: 15rem !important;
}

.me-19 {
  margin-right: 15rem !important;
}

.mb-19 {
  margin-bottom: 15rem !important;
}

.ms-19 {
  margin-left: 15rem !important;
}

.m-n19 {
  margin: -15rem !important;
}

.mx-n19 {
  margin-right: -15rem !important;
  margin-left: -15rem !important;
}

.my-n19 {
  margin-top: -15rem !important;
  margin-bottom: -15rem !important;
}

.mt-n19 {
  margin-top: -15rem !important;
}

.me-n19 {
  margin-right: -15rem !important;
}

.mb-n19 {
  margin-bottom: -15rem !important;
}

.ms-n19 {
  margin-left: -15rem !important;
}

.p-19 {
  padding: 15rem !important;
}

.px-19 {
  padding-right: 15rem !important;
  padding-left: 15rem !important;
}

.py-19 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.pt-19 {
  padding-top: 15rem !important;
}

.pe-19 {
  padding-right: 15rem !important;
}

.pb-19 {
  padding-bottom: 15rem !important;
}

.ps-19 {
  padding-left: 15rem !important;
}

.m-20 {
  margin: 15.5rem !important;
}

.mx-20 {
  margin-right: 15.5rem !important;
  margin-left: 15.5rem !important;
}

.my-20 {
  margin-top: 15.5rem !important;
  margin-bottom: 15.5rem !important;
}

.mt-20 {
  margin-top: 15.5rem !important;
}

.me-20 {
  margin-right: 15.5rem !important;
}

.mb-20 {
  margin-bottom: 15.5rem !important;
}

.ms-20 {
  margin-left: 15.5rem !important;
}

.m-n20 {
  margin: -15.5rem !important;
}

.mx-n20 {
  margin-right: -15.5rem !important;
  margin-left: -15.5rem !important;
}

.my-n20 {
  margin-top: -15.5rem !important;
  margin-bottom: -15.5rem !important;
}

.mt-n20 {
  margin-top: -15.5rem !important;
}

.me-n20 {
  margin-right: -15.5rem !important;
}

.mb-n20 {
  margin-bottom: -15.5rem !important;
}

.ms-n20 {
  margin-left: -15.5rem !important;
}

.p-20 {
  padding: 15.5rem !important;
}

.px-20 {
  padding-right: 15.5rem !important;
  padding-left: 15.5rem !important;
}

.py-20 {
  padding-top: 15.5rem !important;
  padding-bottom: 15.5rem !important;
}

.pt-20 {
  padding-top: 15.5rem !important;
}

.pe-20 {
  padding-right: 15.5rem !important;
}

.pb-20 {
  padding-bottom: 15.5rem !important;
}

.ps-20 {
  padding-left: 15.5rem !important;
}

.m-21 {
  margin: 18.5rem !important;
}

.mx-21 {
  margin-right: 18.5rem !important;
  margin-left: 18.5rem !important;
}

.my-21 {
  margin-top: 18.5rem !important;
  margin-bottom: 18.5rem !important;
}

.mt-21 {
  margin-top: 18.5rem !important;
}

.me-21 {
  margin-right: 18.5rem !important;
}

.mb-21 {
  margin-bottom: 18.5rem !important;
}

.ms-21 {
  margin-left: 18.5rem !important;
}

.m-n21 {
  margin: -18.5rem !important;
}

.mx-n21 {
  margin-right: -18.5rem !important;
  margin-left: -18.5rem !important;
}

.my-n21 {
  margin-top: -18.5rem !important;
  margin-bottom: -18.5rem !important;
}

.mt-n21 {
  margin-top: -18.5rem !important;
}

.me-n21 {
  margin-right: -18.5rem !important;
}

.mb-n21 {
  margin-bottom: -18.5rem !important;
}

.ms-n21 {
  margin-left: -18.5rem !important;
}

.p-21 {
  padding: 18.5rem !important;
}

.px-21 {
  padding-right: 18.5rem !important;
  padding-left: 18.5rem !important;
}

.py-21 {
  padding-top: 18.5rem !important;
  padding-bottom: 18.5rem !important;
}

.pt-21 {
  padding-top: 18.5rem !important;
}

.pe-21 {
  padding-right: 18.5rem !important;
}

.pb-21 {
  padding-bottom: 18.5rem !important;
}

.ps-21 {
  padding-left: 18.5rem !important;
}

.m-22 {
  margin: 3.75rem !important;
}

.mx-22 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.my-22 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.mt-22 {
  margin-top: 3.75rem !important;
}

.me-22 {
  margin-right: 3.75rem !important;
}

.mb-22 {
  margin-bottom: 3.75rem !important;
}

.ms-22 {
  margin-left: 3.75rem !important;
}

.m-n22 {
  margin: -3.75rem !important;
}

.mx-n22 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.my-n22 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.mt-n22 {
  margin-top: -3.75rem !important;
}

.me-n22 {
  margin-right: -3.75rem !important;
}

.mb-n22 {
  margin-bottom: -3.75rem !important;
}

.ms-n22 {
  margin-left: -3.75rem !important;
}

.p-22 {
  padding: 3.75rem !important;
}

.px-22 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.py-22 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.pt-22 {
  padding-top: 3.75rem !important;
}

.pe-22 {
  padding-right: 3.75rem !important;
}

.pb-22 {
  padding-bottom: 3.75rem !important;
}

.ps-22 {
  padding-left: 3.75rem !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0rem !important;
  }

  .mx-sm-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }

  .my-sm-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  .mt-sm-0 {
    margin-top: 0rem !important;
  }

  .me-sm-0 {
    margin-right: 0rem !important;
  }

  .mb-sm-0 {
    margin-bottom: 0rem !important;
  }

  .ms-sm-0 {
    margin-left: 0rem !important;
  }

  .m-sm-n0 {
    margin: -0rem !important;
  }

  .mx-sm-n0 {
    margin-right: -0rem !important;
    margin-left: -0rem !important;
  }

  .my-sm-n0 {
    margin-top: -0rem !important;
    margin-bottom: -0rem !important;
  }

  .mt-sm-n0 {
    margin-top: -0rem !important;
  }

  .me-sm-n0 {
    margin-right: -0rem !important;
  }

  .mb-sm-n0 {
    margin-bottom: -0rem !important;
  }

  .ms-sm-n0 {
    margin-left: -0rem !important;
  }

  .p-sm-0 {
    padding: 0rem !important;
  }

  .px-sm-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .py-sm-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .pt-sm-0 {
    padding-top: 0rem !important;
  }

  .pe-sm-0 {
    padding-right: 0rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0rem !important;
  }

  .ps-sm-0 {
    padding-left: 0rem !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.625rem !important;
  }

  .mx-sm-3 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }

  .my-sm-3 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .mt-sm-3 {
    margin-top: 0.625rem !important;
  }

  .me-sm-3 {
    margin-right: 0.625rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 0.625rem !important;
  }

  .ms-sm-3 {
    margin-left: 0.625rem !important;
  }

  .m-sm-n3 {
    margin: -0.625rem !important;
  }

  .mx-sm-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }

  .my-sm-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .mt-sm-n3 {
    margin-top: -0.625rem !important;
  }

  .me-sm-n3 {
    margin-right: -0.625rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -0.625rem !important;
  }

  .ms-sm-n3 {
    margin-left: -0.625rem !important;
  }

  .p-sm-3 {
    padding: 0.625rem !important;
  }

  .px-sm-3 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }

  .py-sm-3 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .pt-sm-3 {
    padding-top: 0.625rem !important;
  }

  .pe-sm-3 {
    padding-right: 0.625rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 0.625rem !important;
  }

  .ps-sm-3 {
    padding-left: 0.625rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 2rem !important;
  }

  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .mt-sm-5 {
    margin-top: 2rem !important;
  }

  .me-sm-5 {
    margin-right: 2rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .ms-sm-5 {
    margin-left: 2rem !important;
  }

  .m-sm-n5 {
    margin: -2rem !important;
  }

  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .mt-sm-n5 {
    margin-top: -2rem !important;
  }

  .me-sm-n5 {
    margin-right: -2rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }

  .ms-sm-n5 {
    margin-left: -2rem !important;
  }

  .p-sm-5 {
    padding: 2rem !important;
  }

  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .pt-sm-5 {
    padding-top: 2rem !important;
  }

  .pe-sm-5 {
    padding-right: 2rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }

  .ps-sm-5 {
    padding-left: 2rem !important;
  }

  .m-sm-6 {
    margin: 2.5rem !important;
  }

  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }

  .me-sm-6 {
    margin-right: 2.5rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }

  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }

  .m-sm-n6 {
    margin: -2.5rem !important;
  }

  .mx-sm-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-sm-n6 {
    margin-top: -2.5rem !important;
  }

  .me-sm-n6 {
    margin-right: -2.5rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -2.5rem !important;
  }

  .ms-sm-n6 {
    margin-left: -2.5rem !important;
  }

  .p-sm-6 {
    padding: 2.5rem !important;
  }

  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }

  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }

  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }

  .m-sm-7 {
    margin: 3rem !important;
  }

  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .mt-sm-7 {
    margin-top: 3rem !important;
  }

  .me-sm-7 {
    margin-right: 3rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }

  .ms-sm-7 {
    margin-left: 3rem !important;
  }

  .m-sm-n7 {
    margin: -3rem !important;
  }

  .mx-sm-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n7 {
    margin-top: -3rem !important;
  }

  .me-sm-n7 {
    margin-right: -3rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n7 {
    margin-left: -3rem !important;
  }

  .p-sm-7 {
    padding: 3rem !important;
  }

  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-7 {
    padding-top: 3rem !important;
  }

  .pe-sm-7 {
    padding-right: 3rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-7 {
    padding-left: 3rem !important;
  }

  .m-sm-8 {
    margin: 4.5rem !important;
  }

  .mx-sm-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .my-sm-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .mt-sm-8 {
    margin-top: 4.5rem !important;
  }

  .me-sm-8 {
    margin-right: 4.5rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 4.5rem !important;
  }

  .ms-sm-8 {
    margin-left: 4.5rem !important;
  }

  .m-sm-n8 {
    margin: -4.5rem !important;
  }

  .mx-sm-n8 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-sm-n8 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-sm-n8 {
    margin-top: -4.5rem !important;
  }

  .me-sm-n8 {
    margin-right: -4.5rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -4.5rem !important;
  }

  .ms-sm-n8 {
    margin-left: -4.5rem !important;
  }

  .p-sm-8 {
    padding: 4.5rem !important;
  }

  .px-sm-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-sm-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-sm-8 {
    padding-top: 4.5rem !important;
  }

  .pe-sm-8 {
    padding-right: 4.5rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 4.5rem !important;
  }

  .ps-sm-8 {
    padding-left: 4.5rem !important;
  }

  .m-sm-9 {
    margin: 6.25rem !important;
  }

  .mx-sm-9 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }

  .my-sm-9 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }

  .mt-sm-9 {
    margin-top: 6.25rem !important;
  }

  .me-sm-9 {
    margin-right: 6.25rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 6.25rem !important;
  }

  .ms-sm-9 {
    margin-left: 6.25rem !important;
  }

  .m-sm-n9 {
    margin: -6.25rem !important;
  }

  .mx-sm-n9 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }

  .my-sm-n9 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }

  .mt-sm-n9 {
    margin-top: -6.25rem !important;
  }

  .me-sm-n9 {
    margin-right: -6.25rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -6.25rem !important;
  }

  .ms-sm-n9 {
    margin-left: -6.25rem !important;
  }

  .p-sm-9 {
    padding: 6.25rem !important;
  }

  .px-sm-9 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }

  .py-sm-9 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }

  .pt-sm-9 {
    padding-top: 6.25rem !important;
  }

  .pe-sm-9 {
    padding-right: 6.25rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 6.25rem !important;
  }

  .ps-sm-9 {
    padding-left: 6.25rem !important;
  }

  .m-sm-10 {
    margin: 9.375rem !important;
  }

  .mx-sm-10 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }

  .my-sm-10 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }

  .mt-sm-10 {
    margin-top: 9.375rem !important;
  }

  .me-sm-10 {
    margin-right: 9.375rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 9.375rem !important;
  }

  .ms-sm-10 {
    margin-left: 9.375rem !important;
  }

  .m-sm-n10 {
    margin: -9.375rem !important;
  }

  .mx-sm-n10 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }

  .my-sm-n10 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }

  .mt-sm-n10 {
    margin-top: -9.375rem !important;
  }

  .me-sm-n10 {
    margin-right: -9.375rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -9.375rem !important;
  }

  .ms-sm-n10 {
    margin-left: -9.375rem !important;
  }

  .p-sm-10 {
    padding: 9.375rem !important;
  }

  .px-sm-10 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }

  .py-sm-10 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }

  .pt-sm-10 {
    padding-top: 9.375rem !important;
  }

  .pe-sm-10 {
    padding-right: 9.375rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 9.375rem !important;
  }

  .ps-sm-10 {
    padding-left: 9.375rem !important;
  }

  .m-sm-11 {
    margin: 10rem !important;
  }

  .mx-sm-11 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .my-sm-11 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .mt-sm-11 {
    margin-top: 10rem !important;
  }

  .me-sm-11 {
    margin-right: 10rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 10rem !important;
  }

  .ms-sm-11 {
    margin-left: 10rem !important;
  }

  .m-sm-n11 {
    margin: -10rem !important;
  }

  .mx-sm-n11 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-sm-n11 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-sm-n11 {
    margin-top: -10rem !important;
  }

  .me-sm-n11 {
    margin-right: -10rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -10rem !important;
  }

  .ms-sm-n11 {
    margin-left: -10rem !important;
  }

  .p-sm-11 {
    padding: 10rem !important;
  }

  .px-sm-11 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-sm-11 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-sm-11 {
    padding-top: 10rem !important;
  }

  .pe-sm-11 {
    padding-right: 10rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 10rem !important;
  }

  .ps-sm-11 {
    padding-left: 10rem !important;
  }

  .m-sm-12 {
    margin: 10.5rem !important;
  }

  .mx-sm-12 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }

  .my-sm-12 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }

  .mt-sm-12 {
    margin-top: 10.5rem !important;
  }

  .me-sm-12 {
    margin-right: 10.5rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 10.5rem !important;
  }

  .ms-sm-12 {
    margin-left: 10.5rem !important;
  }

  .m-sm-n12 {
    margin: -10.5rem !important;
  }

  .mx-sm-n12 {
    margin-right: -10.5rem !important;
    margin-left: -10.5rem !important;
  }

  .my-sm-n12 {
    margin-top: -10.5rem !important;
    margin-bottom: -10.5rem !important;
  }

  .mt-sm-n12 {
    margin-top: -10.5rem !important;
  }

  .me-sm-n12 {
    margin-right: -10.5rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -10.5rem !important;
  }

  .ms-sm-n12 {
    margin-left: -10.5rem !important;
  }

  .p-sm-12 {
    padding: 10.5rem !important;
  }

  .px-sm-12 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }

  .py-sm-12 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }

  .pt-sm-12 {
    padding-top: 10.5rem !important;
  }

  .pe-sm-12 {
    padding-right: 10.5rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 10.5rem !important;
  }

  .ps-sm-12 {
    padding-left: 10.5rem !important;
  }

  .m-sm-13 {
    margin: 12rem !important;
  }

  .mx-sm-13 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .my-sm-13 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .mt-sm-13 {
    margin-top: 12rem !important;
  }

  .me-sm-13 {
    margin-right: 12rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 12rem !important;
  }

  .ms-sm-13 {
    margin-left: 12rem !important;
  }

  .m-sm-n13 {
    margin: -12rem !important;
  }

  .mx-sm-n13 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .my-sm-n13 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .mt-sm-n13 {
    margin-top: -12rem !important;
  }

  .me-sm-n13 {
    margin-right: -12rem !important;
  }

  .mb-sm-n13 {
    margin-bottom: -12rem !important;
  }

  .ms-sm-n13 {
    margin-left: -12rem !important;
  }

  .p-sm-13 {
    padding: 12rem !important;
  }

  .px-sm-13 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .py-sm-13 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .pt-sm-13 {
    padding-top: 12rem !important;
  }

  .pe-sm-13 {
    padding-right: 12rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 12rem !important;
  }

  .ps-sm-13 {
    padding-left: 12rem !important;
  }

  .m-sm-14 {
    margin: 12.5rem !important;
  }

  .mx-sm-14 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }

  .my-sm-14 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }

  .mt-sm-14 {
    margin-top: 12.5rem !important;
  }

  .me-sm-14 {
    margin-right: 12.5rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 12.5rem !important;
  }

  .ms-sm-14 {
    margin-left: 12.5rem !important;
  }

  .m-sm-n14 {
    margin: -12.5rem !important;
  }

  .mx-sm-n14 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }

  .my-sm-n14 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }

  .mt-sm-n14 {
    margin-top: -12.5rem !important;
  }

  .me-sm-n14 {
    margin-right: -12.5rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -12.5rem !important;
  }

  .ms-sm-n14 {
    margin-left: -12.5rem !important;
  }

  .p-sm-14 {
    padding: 12.5rem !important;
  }

  .px-sm-14 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }

  .py-sm-14 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  .pt-sm-14 {
    padding-top: 12.5rem !important;
  }

  .pe-sm-14 {
    padding-right: 12.5rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 12.5rem !important;
  }

  .ps-sm-14 {
    padding-left: 12.5rem !important;
  }

  .m-sm-15 {
    margin: 13rem !important;
  }

  .mx-sm-15 {
    margin-right: 13rem !important;
    margin-left: 13rem !important;
  }

  .my-sm-15 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .mt-sm-15 {
    margin-top: 13rem !important;
  }

  .me-sm-15 {
    margin-right: 13rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 13rem !important;
  }

  .ms-sm-15 {
    margin-left: 13rem !important;
  }

  .m-sm-n15 {
    margin: -13rem !important;
  }

  .mx-sm-n15 {
    margin-right: -13rem !important;
    margin-left: -13rem !important;
  }

  .my-sm-n15 {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .mt-sm-n15 {
    margin-top: -13rem !important;
  }

  .me-sm-n15 {
    margin-right: -13rem !important;
  }

  .mb-sm-n15 {
    margin-bottom: -13rem !important;
  }

  .ms-sm-n15 {
    margin-left: -13rem !important;
  }

  .p-sm-15 {
    padding: 13rem !important;
  }

  .px-sm-15 {
    padding-right: 13rem !important;
    padding-left: 13rem !important;
  }

  .py-sm-15 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .pt-sm-15 {
    padding-top: 13rem !important;
  }

  .pe-sm-15 {
    padding-right: 13rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 13rem !important;
  }

  .ps-sm-15 {
    padding-left: 13rem !important;
  }

  .m-sm-16 {
    margin: 13.5rem !important;
  }

  .mx-sm-16 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important;
  }

  .my-sm-16 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important;
  }

  .mt-sm-16 {
    margin-top: 13.5rem !important;
  }

  .me-sm-16 {
    margin-right: 13.5rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 13.5rem !important;
  }

  .ms-sm-16 {
    margin-left: 13.5rem !important;
  }

  .m-sm-n16 {
    margin: -13.5rem !important;
  }

  .mx-sm-n16 {
    margin-right: -13.5rem !important;
    margin-left: -13.5rem !important;
  }

  .my-sm-n16 {
    margin-top: -13.5rem !important;
    margin-bottom: -13.5rem !important;
  }

  .mt-sm-n16 {
    margin-top: -13.5rem !important;
  }

  .me-sm-n16 {
    margin-right: -13.5rem !important;
  }

  .mb-sm-n16 {
    margin-bottom: -13.5rem !important;
  }

  .ms-sm-n16 {
    margin-left: -13.5rem !important;
  }

  .p-sm-16 {
    padding: 13.5rem !important;
  }

  .px-sm-16 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important;
  }

  .py-sm-16 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important;
  }

  .pt-sm-16 {
    padding-top: 13.5rem !important;
  }

  .pe-sm-16 {
    padding-right: 13.5rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 13.5rem !important;
  }

  .ps-sm-16 {
    padding-left: 13.5rem !important;
  }

  .m-sm-17 {
    margin: 14rem !important;
  }

  .mx-sm-17 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .my-sm-17 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .mt-sm-17 {
    margin-top: 14rem !important;
  }

  .me-sm-17 {
    margin-right: 14rem !important;
  }

  .mb-sm-17 {
    margin-bottom: 14rem !important;
  }

  .ms-sm-17 {
    margin-left: 14rem !important;
  }

  .m-sm-n17 {
    margin: -14rem !important;
  }

  .mx-sm-n17 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .my-sm-n17 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .mt-sm-n17 {
    margin-top: -14rem !important;
  }

  .me-sm-n17 {
    margin-right: -14rem !important;
  }

  .mb-sm-n17 {
    margin-bottom: -14rem !important;
  }

  .ms-sm-n17 {
    margin-left: -14rem !important;
  }

  .p-sm-17 {
    padding: 14rem !important;
  }

  .px-sm-17 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .py-sm-17 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .pt-sm-17 {
    padding-top: 14rem !important;
  }

  .pe-sm-17 {
    padding-right: 14rem !important;
  }

  .pb-sm-17 {
    padding-bottom: 14rem !important;
  }

  .ps-sm-17 {
    padding-left: 14rem !important;
  }

  .m-sm-18 {
    margin: 14.5rem !important;
  }

  .mx-sm-18 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important;
  }

  .my-sm-18 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important;
  }

  .mt-sm-18 {
    margin-top: 14.5rem !important;
  }

  .me-sm-18 {
    margin-right: 14.5rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 14.5rem !important;
  }

  .ms-sm-18 {
    margin-left: 14.5rem !important;
  }

  .m-sm-n18 {
    margin: -14.5rem !important;
  }

  .mx-sm-n18 {
    margin-right: -14.5rem !important;
    margin-left: -14.5rem !important;
  }

  .my-sm-n18 {
    margin-top: -14.5rem !important;
    margin-bottom: -14.5rem !important;
  }

  .mt-sm-n18 {
    margin-top: -14.5rem !important;
  }

  .me-sm-n18 {
    margin-right: -14.5rem !important;
  }

  .mb-sm-n18 {
    margin-bottom: -14.5rem !important;
  }

  .ms-sm-n18 {
    margin-left: -14.5rem !important;
  }

  .p-sm-18 {
    padding: 14.5rem !important;
  }

  .px-sm-18 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important;
  }

  .py-sm-18 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important;
  }

  .pt-sm-18 {
    padding-top: 14.5rem !important;
  }

  .pe-sm-18 {
    padding-right: 14.5rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 14.5rem !important;
  }

  .ps-sm-18 {
    padding-left: 14.5rem !important;
  }

  .m-sm-19 {
    margin: 15rem !important;
  }

  .mx-sm-19 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .my-sm-19 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .mt-sm-19 {
    margin-top: 15rem !important;
  }

  .me-sm-19 {
    margin-right: 15rem !important;
  }

  .mb-sm-19 {
    margin-bottom: 15rem !important;
  }

  .ms-sm-19 {
    margin-left: 15rem !important;
  }

  .m-sm-n19 {
    margin: -15rem !important;
  }

  .mx-sm-n19 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }

  .my-sm-n19 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .mt-sm-n19 {
    margin-top: -15rem !important;
  }

  .me-sm-n19 {
    margin-right: -15rem !important;
  }

  .mb-sm-n19 {
    margin-bottom: -15rem !important;
  }

  .ms-sm-n19 {
    margin-left: -15rem !important;
  }

  .p-sm-19 {
    padding: 15rem !important;
  }

  .px-sm-19 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .py-sm-19 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .pt-sm-19 {
    padding-top: 15rem !important;
  }

  .pe-sm-19 {
    padding-right: 15rem !important;
  }

  .pb-sm-19 {
    padding-bottom: 15rem !important;
  }

  .ps-sm-19 {
    padding-left: 15rem !important;
  }

  .m-sm-20 {
    margin: 15.5rem !important;
  }

  .mx-sm-20 {
    margin-right: 15.5rem !important;
    margin-left: 15.5rem !important;
  }

  .my-sm-20 {
    margin-top: 15.5rem !important;
    margin-bottom: 15.5rem !important;
  }

  .mt-sm-20 {
    margin-top: 15.5rem !important;
  }

  .me-sm-20 {
    margin-right: 15.5rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 15.5rem !important;
  }

  .ms-sm-20 {
    margin-left: 15.5rem !important;
  }

  .m-sm-n20 {
    margin: -15.5rem !important;
  }

  .mx-sm-n20 {
    margin-right: -15.5rem !important;
    margin-left: -15.5rem !important;
  }

  .my-sm-n20 {
    margin-top: -15.5rem !important;
    margin-bottom: -15.5rem !important;
  }

  .mt-sm-n20 {
    margin-top: -15.5rem !important;
  }

  .me-sm-n20 {
    margin-right: -15.5rem !important;
  }

  .mb-sm-n20 {
    margin-bottom: -15.5rem !important;
  }

  .ms-sm-n20 {
    margin-left: -15.5rem !important;
  }

  .p-sm-20 {
    padding: 15.5rem !important;
  }

  .px-sm-20 {
    padding-right: 15.5rem !important;
    padding-left: 15.5rem !important;
  }

  .py-sm-20 {
    padding-top: 15.5rem !important;
    padding-bottom: 15.5rem !important;
  }

  .pt-sm-20 {
    padding-top: 15.5rem !important;
  }

  .pe-sm-20 {
    padding-right: 15.5rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 15.5rem !important;
  }

  .ps-sm-20 {
    padding-left: 15.5rem !important;
  }

  .m-sm-21 {
    margin: 18.5rem !important;
  }

  .mx-sm-21 {
    margin-right: 18.5rem !important;
    margin-left: 18.5rem !important;
  }

  .my-sm-21 {
    margin-top: 18.5rem !important;
    margin-bottom: 18.5rem !important;
  }

  .mt-sm-21 {
    margin-top: 18.5rem !important;
  }

  .me-sm-21 {
    margin-right: 18.5rem !important;
  }

  .mb-sm-21 {
    margin-bottom: 18.5rem !important;
  }

  .ms-sm-21 {
    margin-left: 18.5rem !important;
  }

  .m-sm-n21 {
    margin: -18.5rem !important;
  }

  .mx-sm-n21 {
    margin-right: -18.5rem !important;
    margin-left: -18.5rem !important;
  }

  .my-sm-n21 {
    margin-top: -18.5rem !important;
    margin-bottom: -18.5rem !important;
  }

  .mt-sm-n21 {
    margin-top: -18.5rem !important;
  }

  .me-sm-n21 {
    margin-right: -18.5rem !important;
  }

  .mb-sm-n21 {
    margin-bottom: -18.5rem !important;
  }

  .ms-sm-n21 {
    margin-left: -18.5rem !important;
  }

  .p-sm-21 {
    padding: 18.5rem !important;
  }

  .px-sm-21 {
    padding-right: 18.5rem !important;
    padding-left: 18.5rem !important;
  }

  .py-sm-21 {
    padding-top: 18.5rem !important;
    padding-bottom: 18.5rem !important;
  }

  .pt-sm-21 {
    padding-top: 18.5rem !important;
  }

  .pe-sm-21 {
    padding-right: 18.5rem !important;
  }

  .pb-sm-21 {
    padding-bottom: 18.5rem !important;
  }

  .ps-sm-21 {
    padding-left: 18.5rem !important;
  }

  .m-sm-22 {
    margin: 3.75rem !important;
  }

  .mx-sm-22 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .my-sm-22 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .mt-sm-22 {
    margin-top: 3.75rem !important;
  }

  .me-sm-22 {
    margin-right: 3.75rem !important;
  }

  .mb-sm-22 {
    margin-bottom: 3.75rem !important;
  }

  .ms-sm-22 {
    margin-left: 3.75rem !important;
  }

  .m-sm-n22 {
    margin: -3.75rem !important;
  }

  .mx-sm-n22 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .my-sm-n22 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .mt-sm-n22 {
    margin-top: -3.75rem !important;
  }

  .me-sm-n22 {
    margin-right: -3.75rem !important;
  }

  .mb-sm-n22 {
    margin-bottom: -3.75rem !important;
  }

  .ms-sm-n22 {
    margin-left: -3.75rem !important;
  }

  .p-sm-22 {
    padding: 3.75rem !important;
  }

  .px-sm-22 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .py-sm-22 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .pt-sm-22 {
    padding-top: 3.75rem !important;
  }

  .pe-sm-22 {
    padding-right: 3.75rem !important;
  }

  .pb-sm-22 {
    padding-bottom: 3.75rem !important;
  }

  .ps-sm-22 {
    padding-left: 3.75rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0rem !important;
  }

  .mx-md-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }

  .my-md-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  .mt-md-0 {
    margin-top: 0rem !important;
  }

  .me-md-0 {
    margin-right: 0rem !important;
  }

  .mb-md-0 {
    margin-bottom: 0rem !important;
  }

  .ms-md-0 {
    margin-left: 0rem !important;
  }

  .m-md-n0 {
    margin: -0rem !important;
  }

  .mx-md-n0 {
    margin-right: -0rem !important;
    margin-left: -0rem !important;
  }

  .my-md-n0 {
    margin-top: -0rem !important;
    margin-bottom: -0rem !important;
  }

  .mt-md-n0 {
    margin-top: -0rem !important;
  }

  .me-md-n0 {
    margin-right: -0rem !important;
  }

  .mb-md-n0 {
    margin-bottom: -0rem !important;
  }

  .ms-md-n0 {
    margin-left: -0rem !important;
  }

  .p-md-0 {
    padding: 0rem !important;
  }

  .px-md-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .py-md-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .pt-md-0 {
    padding-top: 0rem !important;
  }

  .pe-md-0 {
    padding-right: 0rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0rem !important;
  }

  .ps-md-0 {
    padding-left: 0rem !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.625rem !important;
  }

  .mx-md-3 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }

  .my-md-3 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .mt-md-3 {
    margin-top: 0.625rem !important;
  }

  .me-md-3 {
    margin-right: 0.625rem !important;
  }

  .mb-md-3 {
    margin-bottom: 0.625rem !important;
  }

  .ms-md-3 {
    margin-left: 0.625rem !important;
  }

  .m-md-n3 {
    margin: -0.625rem !important;
  }

  .mx-md-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }

  .my-md-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .mt-md-n3 {
    margin-top: -0.625rem !important;
  }

  .me-md-n3 {
    margin-right: -0.625rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -0.625rem !important;
  }

  .ms-md-n3 {
    margin-left: -0.625rem !important;
  }

  .p-md-3 {
    padding: 0.625rem !important;
  }

  .px-md-3 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }

  .py-md-3 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .pt-md-3 {
    padding-top: 0.625rem !important;
  }

  .pe-md-3 {
    padding-right: 0.625rem !important;
  }

  .pb-md-3 {
    padding-bottom: 0.625rem !important;
  }

  .ps-md-3 {
    padding-left: 0.625rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 2rem !important;
  }

  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .mt-md-5 {
    margin-top: 2rem !important;
  }

  .me-md-5 {
    margin-right: 2rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .ms-md-5 {
    margin-left: 2rem !important;
  }

  .m-md-n5 {
    margin: -2rem !important;
  }

  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .mt-md-n5 {
    margin-top: -2rem !important;
  }

  .me-md-n5 {
    margin-right: -2rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }

  .ms-md-n5 {
    margin-left: -2rem !important;
  }

  .p-md-5 {
    padding: 2rem !important;
  }

  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .pt-md-5 {
    padding-top: 2rem !important;
  }

  .pe-md-5 {
    padding-right: 2rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2rem !important;
  }

  .ps-md-5 {
    padding-left: 2rem !important;
  }

  .m-md-6 {
    margin: 2.5rem !important;
  }

  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .mt-md-6 {
    margin-top: 2.5rem !important;
  }

  .me-md-6 {
    margin-right: 2.5rem !important;
  }

  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }

  .ms-md-6 {
    margin-left: 2.5rem !important;
  }

  .m-md-n6 {
    margin: -2.5rem !important;
  }

  .mx-md-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-md-n6 {
    margin-top: -2.5rem !important;
  }

  .me-md-n6 {
    margin-right: -2.5rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -2.5rem !important;
  }

  .ms-md-n6 {
    margin-left: -2.5rem !important;
  }

  .p-md-6 {
    padding: 2.5rem !important;
  }

  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-md-6 {
    padding-top: 2.5rem !important;
  }

  .pe-md-6 {
    padding-right: 2.5rem !important;
  }

  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }

  .ps-md-6 {
    padding-left: 2.5rem !important;
  }

  .m-md-7 {
    margin: 3rem !important;
  }

  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .mt-md-7 {
    margin-top: 3rem !important;
  }

  .me-md-7 {
    margin-right: 3rem !important;
  }

  .mb-md-7 {
    margin-bottom: 3rem !important;
  }

  .ms-md-7 {
    margin-left: 3rem !important;
  }

  .m-md-n7 {
    margin: -3rem !important;
  }

  .mx-md-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n7 {
    margin-top: -3rem !important;
  }

  .me-md-n7 {
    margin-right: -3rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n7 {
    margin-left: -3rem !important;
  }

  .p-md-7 {
    padding: 3rem !important;
  }

  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-7 {
    padding-top: 3rem !important;
  }

  .pe-md-7 {
    padding-right: 3rem !important;
  }

  .pb-md-7 {
    padding-bottom: 3rem !important;
  }

  .ps-md-7 {
    padding-left: 3rem !important;
  }

  .m-md-8 {
    margin: 4.5rem !important;
  }

  .mx-md-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .my-md-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .mt-md-8 {
    margin-top: 4.5rem !important;
  }

  .me-md-8 {
    margin-right: 4.5rem !important;
  }

  .mb-md-8 {
    margin-bottom: 4.5rem !important;
  }

  .ms-md-8 {
    margin-left: 4.5rem !important;
  }

  .m-md-n8 {
    margin: -4.5rem !important;
  }

  .mx-md-n8 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-md-n8 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-md-n8 {
    margin-top: -4.5rem !important;
  }

  .me-md-n8 {
    margin-right: -4.5rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -4.5rem !important;
  }

  .ms-md-n8 {
    margin-left: -4.5rem !important;
  }

  .p-md-8 {
    padding: 4.5rem !important;
  }

  .px-md-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-md-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-md-8 {
    padding-top: 4.5rem !important;
  }

  .pe-md-8 {
    padding-right: 4.5rem !important;
  }

  .pb-md-8 {
    padding-bottom: 4.5rem !important;
  }

  .ps-md-8 {
    padding-left: 4.5rem !important;
  }

  .m-md-9 {
    margin: 6.25rem !important;
  }

  .mx-md-9 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }

  .my-md-9 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }

  .mt-md-9 {
    margin-top: 6.25rem !important;
  }

  .me-md-9 {
    margin-right: 6.25rem !important;
  }

  .mb-md-9 {
    margin-bottom: 6.25rem !important;
  }

  .ms-md-9 {
    margin-left: 6.25rem !important;
  }

  .m-md-n9 {
    margin: -6.25rem !important;
  }

  .mx-md-n9 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }

  .my-md-n9 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }

  .mt-md-n9 {
    margin-top: -6.25rem !important;
  }

  .me-md-n9 {
    margin-right: -6.25rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -6.25rem !important;
  }

  .ms-md-n9 {
    margin-left: -6.25rem !important;
  }

  .p-md-9 {
    padding: 6.25rem !important;
  }

  .px-md-9 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }

  .py-md-9 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }

  .pt-md-9 {
    padding-top: 6.25rem !important;
  }

  .pe-md-9 {
    padding-right: 6.25rem !important;
  }

  .pb-md-9 {
    padding-bottom: 6.25rem !important;
  }

  .ps-md-9 {
    padding-left: 6.25rem !important;
  }

  .m-md-10 {
    margin: 9.375rem !important;
  }

  .mx-md-10 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }

  .my-md-10 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }

  .mt-md-10 {
    margin-top: 9.375rem !important;
  }

  .me-md-10 {
    margin-right: 9.375rem !important;
  }

  .mb-md-10 {
    margin-bottom: 9.375rem !important;
  }

  .ms-md-10 {
    margin-left: 9.375rem !important;
  }

  .m-md-n10 {
    margin: -9.375rem !important;
  }

  .mx-md-n10 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }

  .my-md-n10 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }

  .mt-md-n10 {
    margin-top: -9.375rem !important;
  }

  .me-md-n10 {
    margin-right: -9.375rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -9.375rem !important;
  }

  .ms-md-n10 {
    margin-left: -9.375rem !important;
  }

  .p-md-10 {
    padding: 9.375rem !important;
  }

  .px-md-10 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }

  .py-md-10 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }

  .pt-md-10 {
    padding-top: 9.375rem !important;
  }

  .pe-md-10 {
    padding-right: 9.375rem !important;
  }

  .pb-md-10 {
    padding-bottom: 9.375rem !important;
  }

  .ps-md-10 {
    padding-left: 9.375rem !important;
  }

  .m-md-11 {
    margin: 10rem !important;
  }

  .mx-md-11 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .my-md-11 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .mt-md-11 {
    margin-top: 10rem !important;
  }

  .me-md-11 {
    margin-right: 10rem !important;
  }

  .mb-md-11 {
    margin-bottom: 10rem !important;
  }

  .ms-md-11 {
    margin-left: 10rem !important;
  }

  .m-md-n11 {
    margin: -10rem !important;
  }

  .mx-md-n11 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-md-n11 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-md-n11 {
    margin-top: -10rem !important;
  }

  .me-md-n11 {
    margin-right: -10rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -10rem !important;
  }

  .ms-md-n11 {
    margin-left: -10rem !important;
  }

  .p-md-11 {
    padding: 10rem !important;
  }

  .px-md-11 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-md-11 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-md-11 {
    padding-top: 10rem !important;
  }

  .pe-md-11 {
    padding-right: 10rem !important;
  }

  .pb-md-11 {
    padding-bottom: 10rem !important;
  }

  .ps-md-11 {
    padding-left: 10rem !important;
  }

  .m-md-12 {
    margin: 10.5rem !important;
  }

  .mx-md-12 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }

  .my-md-12 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }

  .mt-md-12 {
    margin-top: 10.5rem !important;
  }

  .me-md-12 {
    margin-right: 10.5rem !important;
  }

  .mb-md-12 {
    margin-bottom: 10.5rem !important;
  }

  .ms-md-12 {
    margin-left: 10.5rem !important;
  }

  .m-md-n12 {
    margin: -10.5rem !important;
  }

  .mx-md-n12 {
    margin-right: -10.5rem !important;
    margin-left: -10.5rem !important;
  }

  .my-md-n12 {
    margin-top: -10.5rem !important;
    margin-bottom: -10.5rem !important;
  }

  .mt-md-n12 {
    margin-top: -10.5rem !important;
  }

  .me-md-n12 {
    margin-right: -10.5rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -10.5rem !important;
  }

  .ms-md-n12 {
    margin-left: -10.5rem !important;
  }

  .p-md-12 {
    padding: 10.5rem !important;
  }

  .px-md-12 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }

  .py-md-12 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }

  .pt-md-12 {
    padding-top: 10.5rem !important;
  }

  .pe-md-12 {
    padding-right: 10.5rem !important;
  }

  .pb-md-12 {
    padding-bottom: 10.5rem !important;
  }

  .ps-md-12 {
    padding-left: 10.5rem !important;
  }

  .m-md-13 {
    margin: 12rem !important;
  }

  .mx-md-13 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .my-md-13 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .mt-md-13 {
    margin-top: 12rem !important;
  }

  .me-md-13 {
    margin-right: 12rem !important;
  }

  .mb-md-13 {
    margin-bottom: 12rem !important;
  }

  .ms-md-13 {
    margin-left: 12rem !important;
  }

  .m-md-n13 {
    margin: -12rem !important;
  }

  .mx-md-n13 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .my-md-n13 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .mt-md-n13 {
    margin-top: -12rem !important;
  }

  .me-md-n13 {
    margin-right: -12rem !important;
  }

  .mb-md-n13 {
    margin-bottom: -12rem !important;
  }

  .ms-md-n13 {
    margin-left: -12rem !important;
  }

  .p-md-13 {
    padding: 12rem !important;
  }

  .px-md-13 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .py-md-13 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .pt-md-13 {
    padding-top: 12rem !important;
  }

  .pe-md-13 {
    padding-right: 12rem !important;
  }

  .pb-md-13 {
    padding-bottom: 12rem !important;
  }

  .ps-md-13 {
    padding-left: 12rem !important;
  }

  .m-md-14 {
    margin: 12.5rem !important;
  }

  .mx-md-14 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }

  .my-md-14 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }

  .mt-md-14 {
    margin-top: 12.5rem !important;
  }

  .me-md-14 {
    margin-right: 12.5rem !important;
  }

  .mb-md-14 {
    margin-bottom: 12.5rem !important;
  }

  .ms-md-14 {
    margin-left: 12.5rem !important;
  }

  .m-md-n14 {
    margin: -12.5rem !important;
  }

  .mx-md-n14 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }

  .my-md-n14 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }

  .mt-md-n14 {
    margin-top: -12.5rem !important;
  }

  .me-md-n14 {
    margin-right: -12.5rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -12.5rem !important;
  }

  .ms-md-n14 {
    margin-left: -12.5rem !important;
  }

  .p-md-14 {
    padding: 12.5rem !important;
  }

  .px-md-14 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }

  .py-md-14 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  .pt-md-14 {
    padding-top: 12.5rem !important;
  }

  .pe-md-14 {
    padding-right: 12.5rem !important;
  }

  .pb-md-14 {
    padding-bottom: 12.5rem !important;
  }

  .ps-md-14 {
    padding-left: 12.5rem !important;
  }

  .m-md-15 {
    margin: 13rem !important;
  }

  .mx-md-15 {
    margin-right: 13rem !important;
    margin-left: 13rem !important;
  }

  .my-md-15 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .mt-md-15 {
    margin-top: 13rem !important;
  }

  .me-md-15 {
    margin-right: 13rem !important;
  }

  .mb-md-15 {
    margin-bottom: 13rem !important;
  }

  .ms-md-15 {
    margin-left: 13rem !important;
  }

  .m-md-n15 {
    margin: -13rem !important;
  }

  .mx-md-n15 {
    margin-right: -13rem !important;
    margin-left: -13rem !important;
  }

  .my-md-n15 {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .mt-md-n15 {
    margin-top: -13rem !important;
  }

  .me-md-n15 {
    margin-right: -13rem !important;
  }

  .mb-md-n15 {
    margin-bottom: -13rem !important;
  }

  .ms-md-n15 {
    margin-left: -13rem !important;
  }

  .p-md-15 {
    padding: 13rem !important;
  }

  .px-md-15 {
    padding-right: 13rem !important;
    padding-left: 13rem !important;
  }

  .py-md-15 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .pt-md-15 {
    padding-top: 13rem !important;
  }

  .pe-md-15 {
    padding-right: 13rem !important;
  }

  .pb-md-15 {
    padding-bottom: 13rem !important;
  }

  .ps-md-15 {
    padding-left: 13rem !important;
  }

  .m-md-16 {
    margin: 13.5rem !important;
  }

  .mx-md-16 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important;
  }

  .my-md-16 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important;
  }

  .mt-md-16 {
    margin-top: 13.5rem !important;
  }

  .me-md-16 {
    margin-right: 13.5rem !important;
  }

  .mb-md-16 {
    margin-bottom: 13.5rem !important;
  }

  .ms-md-16 {
    margin-left: 13.5rem !important;
  }

  .m-md-n16 {
    margin: -13.5rem !important;
  }

  .mx-md-n16 {
    margin-right: -13.5rem !important;
    margin-left: -13.5rem !important;
  }

  .my-md-n16 {
    margin-top: -13.5rem !important;
    margin-bottom: -13.5rem !important;
  }

  .mt-md-n16 {
    margin-top: -13.5rem !important;
  }

  .me-md-n16 {
    margin-right: -13.5rem !important;
  }

  .mb-md-n16 {
    margin-bottom: -13.5rem !important;
  }

  .ms-md-n16 {
    margin-left: -13.5rem !important;
  }

  .p-md-16 {
    padding: 13.5rem !important;
  }

  .px-md-16 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important;
  }

  .py-md-16 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important;
  }

  .pt-md-16 {
    padding-top: 13.5rem !important;
  }

  .pe-md-16 {
    padding-right: 13.5rem !important;
  }

  .pb-md-16 {
    padding-bottom: 13.5rem !important;
  }

  .ps-md-16 {
    padding-left: 13.5rem !important;
  }

  .m-md-17 {
    margin: 14rem !important;
  }

  .mx-md-17 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .my-md-17 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .mt-md-17 {
    margin-top: 14rem !important;
  }

  .me-md-17 {
    margin-right: 14rem !important;
  }

  .mb-md-17 {
    margin-bottom: 14rem !important;
  }

  .ms-md-17 {
    margin-left: 14rem !important;
  }

  .m-md-n17 {
    margin: -14rem !important;
  }

  .mx-md-n17 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .my-md-n17 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .mt-md-n17 {
    margin-top: -14rem !important;
  }

  .me-md-n17 {
    margin-right: -14rem !important;
  }

  .mb-md-n17 {
    margin-bottom: -14rem !important;
  }

  .ms-md-n17 {
    margin-left: -14rem !important;
  }

  .p-md-17 {
    padding: 14rem !important;
  }

  .px-md-17 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .py-md-17 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .pt-md-17 {
    padding-top: 14rem !important;
  }

  .pe-md-17 {
    padding-right: 14rem !important;
  }

  .pb-md-17 {
    padding-bottom: 14rem !important;
  }

  .ps-md-17 {
    padding-left: 14rem !important;
  }

  .m-md-18 {
    margin: 14.5rem !important;
  }

  .mx-md-18 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important;
  }

  .my-md-18 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important;
  }

  .mt-md-18 {
    margin-top: 14.5rem !important;
  }

  .me-md-18 {
    margin-right: 14.5rem !important;
  }

  .mb-md-18 {
    margin-bottom: 14.5rem !important;
  }

  .ms-md-18 {
    margin-left: 14.5rem !important;
  }

  .m-md-n18 {
    margin: -14.5rem !important;
  }

  .mx-md-n18 {
    margin-right: -14.5rem !important;
    margin-left: -14.5rem !important;
  }

  .my-md-n18 {
    margin-top: -14.5rem !important;
    margin-bottom: -14.5rem !important;
  }

  .mt-md-n18 {
    margin-top: -14.5rem !important;
  }

  .me-md-n18 {
    margin-right: -14.5rem !important;
  }

  .mb-md-n18 {
    margin-bottom: -14.5rem !important;
  }

  .ms-md-n18 {
    margin-left: -14.5rem !important;
  }

  .p-md-18 {
    padding: 14.5rem !important;
  }

  .px-md-18 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important;
  }

  .py-md-18 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important;
  }

  .pt-md-18 {
    padding-top: 14.5rem !important;
  }

  .pe-md-18 {
    padding-right: 14.5rem !important;
  }

  .pb-md-18 {
    padding-bottom: 14.5rem !important;
  }

  .ps-md-18 {
    padding-left: 14.5rem !important;
  }

  .m-md-19 {
    margin: 15rem !important;
  }

  .mx-md-19 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .my-md-19 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .mt-md-19 {
    margin-top: 15rem !important;
  }

  .me-md-19 {
    margin-right: 15rem !important;
  }

  .mb-md-19 {
    margin-bottom: 15rem !important;
  }

  .ms-md-19 {
    margin-left: 15rem !important;
  }

  .m-md-n19 {
    margin: -15rem !important;
  }

  .mx-md-n19 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }

  .my-md-n19 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .mt-md-n19 {
    margin-top: -15rem !important;
  }

  .me-md-n19 {
    margin-right: -15rem !important;
  }

  .mb-md-n19 {
    margin-bottom: -15rem !important;
  }

  .ms-md-n19 {
    margin-left: -15rem !important;
  }

  .p-md-19 {
    padding: 15rem !important;
  }

  .px-md-19 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .py-md-19 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .pt-md-19 {
    padding-top: 15rem !important;
  }

  .pe-md-19 {
    padding-right: 15rem !important;
  }

  .pb-md-19 {
    padding-bottom: 15rem !important;
  }

  .ps-md-19 {
    padding-left: 15rem !important;
  }

  .m-md-20 {
    margin: 15.5rem !important;
  }

  .mx-md-20 {
    margin-right: 15.5rem !important;
    margin-left: 15.5rem !important;
  }

  .my-md-20 {
    margin-top: 15.5rem !important;
    margin-bottom: 15.5rem !important;
  }

  .mt-md-20 {
    margin-top: 15.5rem !important;
  }

  .me-md-20 {
    margin-right: 15.5rem !important;
  }

  .mb-md-20 {
    margin-bottom: 15.5rem !important;
  }

  .ms-md-20 {
    margin-left: 15.5rem !important;
  }

  .m-md-n20 {
    margin: -15.5rem !important;
  }

  .mx-md-n20 {
    margin-right: -15.5rem !important;
    margin-left: -15.5rem !important;
  }

  .my-md-n20 {
    margin-top: -15.5rem !important;
    margin-bottom: -15.5rem !important;
  }

  .mt-md-n20 {
    margin-top: -15.5rem !important;
  }

  .me-md-n20 {
    margin-right: -15.5rem !important;
  }

  .mb-md-n20 {
    margin-bottom: -15.5rem !important;
  }

  .ms-md-n20 {
    margin-left: -15.5rem !important;
  }

  .p-md-20 {
    padding: 15.5rem !important;
  }

  .px-md-20 {
    padding-right: 15.5rem !important;
    padding-left: 15.5rem !important;
  }

  .py-md-20 {
    padding-top: 15.5rem !important;
    padding-bottom: 15.5rem !important;
  }

  .pt-md-20 {
    padding-top: 15.5rem !important;
  }

  .pe-md-20 {
    padding-right: 15.5rem !important;
  }

  .pb-md-20 {
    padding-bottom: 15.5rem !important;
  }

  .ps-md-20 {
    padding-left: 15.5rem !important;
  }

  .m-md-21 {
    margin: 18.5rem !important;
  }

  .mx-md-21 {
    margin-right: 18.5rem !important;
    margin-left: 18.5rem !important;
  }

  .my-md-21 {
    margin-top: 18.5rem !important;
    margin-bottom: 18.5rem !important;
  }

  .mt-md-21 {
    margin-top: 18.5rem !important;
  }

  .me-md-21 {
    margin-right: 18.5rem !important;
  }

  .mb-md-21 {
    margin-bottom: 18.5rem !important;
  }

  .ms-md-21 {
    margin-left: 18.5rem !important;
  }

  .m-md-n21 {
    margin: -18.5rem !important;
  }

  .mx-md-n21 {
    margin-right: -18.5rem !important;
    margin-left: -18.5rem !important;
  }

  .my-md-n21 {
    margin-top: -18.5rem !important;
    margin-bottom: -18.5rem !important;
  }

  .mt-md-n21 {
    margin-top: -18.5rem !important;
  }

  .me-md-n21 {
    margin-right: -18.5rem !important;
  }

  .mb-md-n21 {
    margin-bottom: -18.5rem !important;
  }

  .ms-md-n21 {
    margin-left: -18.5rem !important;
  }

  .p-md-21 {
    padding: 18.5rem !important;
  }

  .px-md-21 {
    padding-right: 18.5rem !important;
    padding-left: 18.5rem !important;
  }

  .py-md-21 {
    padding-top: 18.5rem !important;
    padding-bottom: 18.5rem !important;
  }

  .pt-md-21 {
    padding-top: 18.5rem !important;
  }

  .pe-md-21 {
    padding-right: 18.5rem !important;
  }

  .pb-md-21 {
    padding-bottom: 18.5rem !important;
  }

  .ps-md-21 {
    padding-left: 18.5rem !important;
  }

  .m-md-22 {
    margin: 3.75rem !important;
  }

  .mx-md-22 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .my-md-22 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .mt-md-22 {
    margin-top: 3.75rem !important;
  }

  .me-md-22 {
    margin-right: 3.75rem !important;
  }

  .mb-md-22 {
    margin-bottom: 3.75rem !important;
  }

  .ms-md-22 {
    margin-left: 3.75rem !important;
  }

  .m-md-n22 {
    margin: -3.75rem !important;
  }

  .mx-md-n22 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .my-md-n22 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .mt-md-n22 {
    margin-top: -3.75rem !important;
  }

  .me-md-n22 {
    margin-right: -3.75rem !important;
  }

  .mb-md-n22 {
    margin-bottom: -3.75rem !important;
  }

  .ms-md-n22 {
    margin-left: -3.75rem !important;
  }

  .p-md-22 {
    padding: 3.75rem !important;
  }

  .px-md-22 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .py-md-22 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .pt-md-22 {
    padding-top: 3.75rem !important;
  }

  .pe-md-22 {
    padding-right: 3.75rem !important;
  }

  .pb-md-22 {
    padding-bottom: 3.75rem !important;
  }

  .ps-md-22 {
    padding-left: 3.75rem !important;
  }

  .lh-md-sm {
    line-height: 120% !important;
  }

  .lh-md-md {
    line-height: 150% !important;
  }

  .lh-md-lg {
    line-height: 170% !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0rem !important;
  }

  .mx-lg-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }

  .my-lg-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  .mt-lg-0 {
    margin-top: 0rem !important;
  }

  .me-lg-0 {
    margin-right: 0rem !important;
  }

  .mb-lg-0 {
    margin-bottom: 0rem !important;
  }

  .ms-lg-0 {
    margin-left: 0rem !important;
  }

  .m-lg-n0 {
    margin: -0rem !important;
  }

  .mx-lg-n0 {
    margin-right: -0rem !important;
    margin-left: -0rem !important;
  }

  .my-lg-n0 {
    margin-top: -0rem !important;
    margin-bottom: -0rem !important;
  }

  .mt-lg-n0 {
    margin-top: -0rem !important;
  }

  .me-lg-n0 {
    margin-right: -0rem !important;
  }

  .mb-lg-n0 {
    margin-bottom: -0rem !important;
  }

  .ms-lg-n0 {
    margin-left: -0rem !important;
  }

  .p-lg-0 {
    padding: 0rem !important;
  }

  .px-lg-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .py-lg-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .pt-lg-0 {
    padding-top: 0rem !important;
  }

  .pe-lg-0 {
    padding-right: 0rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0rem !important;
  }

  .ps-lg-0 {
    padding-left: 0rem !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.625rem !important;
  }

  .mx-lg-3 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }

  .my-lg-3 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .mt-lg-3 {
    margin-top: 0.625rem !important;
  }

  .me-lg-3 {
    margin-right: 0.625rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 0.625rem !important;
  }

  .ms-lg-3 {
    margin-left: 0.625rem !important;
  }

  .m-lg-n3 {
    margin: -0.625rem !important;
  }

  .mx-lg-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }

  .my-lg-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .mt-lg-n3 {
    margin-top: -0.625rem !important;
  }

  .me-lg-n3 {
    margin-right: -0.625rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -0.625rem !important;
  }

  .ms-lg-n3 {
    margin-left: -0.625rem !important;
  }

  .p-lg-3 {
    padding: 0.625rem !important;
  }

  .px-lg-3 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }

  .py-lg-3 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .pt-lg-3 {
    padding-top: 0.625rem !important;
  }

  .pe-lg-3 {
    padding-right: 0.625rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 0.625rem !important;
  }

  .ps-lg-3 {
    padding-left: 0.625rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 2rem !important;
  }

  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .mt-lg-5 {
    margin-top: 2rem !important;
  }

  .me-lg-5 {
    margin-right: 2rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .ms-lg-5 {
    margin-left: 2rem !important;
  }

  .m-lg-n5 {
    margin: -2rem !important;
  }

  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .mt-lg-n5 {
    margin-top: -2rem !important;
  }

  .me-lg-n5 {
    margin-right: -2rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }

  .ms-lg-n5 {
    margin-left: -2rem !important;
  }

  .p-lg-5 {
    padding: 2rem !important;
  }

  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .pt-lg-5 {
    padding-top: 2rem !important;
  }

  .pe-lg-5 {
    padding-right: 2rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }

  .ps-lg-5 {
    padding-left: 2rem !important;
  }

  .m-lg-6 {
    margin: 2.5rem !important;
  }

  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }

  .me-lg-6 {
    margin-right: 2.5rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }

  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }

  .m-lg-n6 {
    margin: -2.5rem !important;
  }

  .mx-lg-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-lg-n6 {
    margin-top: -2.5rem !important;
  }

  .me-lg-n6 {
    margin-right: -2.5rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -2.5rem !important;
  }

  .ms-lg-n6 {
    margin-left: -2.5rem !important;
  }

  .p-lg-6 {
    padding: 2.5rem !important;
  }

  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }

  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }

  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }

  .m-lg-7 {
    margin: 3rem !important;
  }

  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .mt-lg-7 {
    margin-top: 3rem !important;
  }

  .me-lg-7 {
    margin-right: 3rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }

  .ms-lg-7 {
    margin-left: 3rem !important;
  }

  .m-lg-n7 {
    margin: -3rem !important;
  }

  .mx-lg-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n7 {
    margin-top: -3rem !important;
  }

  .me-lg-n7 {
    margin-right: -3rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n7 {
    margin-left: -3rem !important;
  }

  .p-lg-7 {
    padding: 3rem !important;
  }

  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-7 {
    padding-top: 3rem !important;
  }

  .pe-lg-7 {
    padding-right: 3rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-7 {
    padding-left: 3rem !important;
  }

  .m-lg-8 {
    margin: 4.5rem !important;
  }

  .mx-lg-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .my-lg-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .mt-lg-8 {
    margin-top: 4.5rem !important;
  }

  .me-lg-8 {
    margin-right: 4.5rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 4.5rem !important;
  }

  .ms-lg-8 {
    margin-left: 4.5rem !important;
  }

  .m-lg-n8 {
    margin: -4.5rem !important;
  }

  .mx-lg-n8 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-lg-n8 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-lg-n8 {
    margin-top: -4.5rem !important;
  }

  .me-lg-n8 {
    margin-right: -4.5rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -4.5rem !important;
  }

  .ms-lg-n8 {
    margin-left: -4.5rem !important;
  }

  .p-lg-8 {
    padding: 4.5rem !important;
  }

  .px-lg-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-lg-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-lg-8 {
    padding-top: 4.5rem !important;
  }

  .pe-lg-8 {
    padding-right: 4.5rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 4.5rem !important;
  }

  .ps-lg-8 {
    padding-left: 4.5rem !important;
  }

  .m-lg-9 {
    margin: 6.25rem !important;
  }

  .mx-lg-9 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }

  .my-lg-9 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }

  .mt-lg-9 {
    margin-top: 6.25rem !important;
  }

  .me-lg-9 {
    margin-right: 6.25rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 6.25rem !important;
  }

  .ms-lg-9 {
    margin-left: 6.25rem !important;
  }

  .m-lg-n9 {
    margin: -6.25rem !important;
  }

  .mx-lg-n9 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }

  .my-lg-n9 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }

  .mt-lg-n9 {
    margin-top: -6.25rem !important;
  }

  .me-lg-n9 {
    margin-right: -6.25rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -6.25rem !important;
  }

  .ms-lg-n9 {
    margin-left: -6.25rem !important;
  }

  .p-lg-9 {
    padding: 6.25rem !important;
  }

  .px-lg-9 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }

  .py-lg-9 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }

  .pt-lg-9 {
    padding-top: 6.25rem !important;
  }

  .pe-lg-9 {
    padding-right: 6.25rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 6.25rem !important;
  }

  .ps-lg-9 {
    padding-left: 6.25rem !important;
  }

  .m-lg-10 {
    margin: 9.375rem !important;
  }

  .mx-lg-10 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }

  .my-lg-10 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }

  .mt-lg-10 {
    margin-top: 9.375rem !important;
  }

  .me-lg-10 {
    margin-right: 9.375rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 9.375rem !important;
  }

  .ms-lg-10 {
    margin-left: 9.375rem !important;
  }

  .m-lg-n10 {
    margin: -9.375rem !important;
  }

  .mx-lg-n10 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }

  .my-lg-n10 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }

  .mt-lg-n10 {
    margin-top: -9.375rem !important;
  }

  .me-lg-n10 {
    margin-right: -9.375rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -9.375rem !important;
  }

  .ms-lg-n10 {
    margin-left: -9.375rem !important;
  }

  .p-lg-10 {
    padding: 9.375rem !important;
  }

  .px-lg-10 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }

  .py-lg-10 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }

  .pt-lg-10 {
    padding-top: 9.375rem !important;
  }

  .pe-lg-10 {
    padding-right: 9.375rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 9.375rem !important;
  }

  .ps-lg-10 {
    padding-left: 9.375rem !important;
  }

  .m-lg-11 {
    margin: 10rem !important;
  }

  .mx-lg-11 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .my-lg-11 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .mt-lg-11 {
    margin-top: 10rem !important;
  }

  .me-lg-11 {
    margin-right: 10rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 10rem !important;
  }

  .ms-lg-11 {
    margin-left: 10rem !important;
  }

  .m-lg-n11 {
    margin: -10rem !important;
  }

  .mx-lg-n11 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-lg-n11 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-lg-n11 {
    margin-top: -10rem !important;
  }

  .me-lg-n11 {
    margin-right: -10rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -10rem !important;
  }

  .ms-lg-n11 {
    margin-left: -10rem !important;
  }

  .p-lg-11 {
    padding: 10rem !important;
  }

  .px-lg-11 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-lg-11 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-lg-11 {
    padding-top: 10rem !important;
  }

  .pe-lg-11 {
    padding-right: 10rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 10rem !important;
  }

  .ps-lg-11 {
    padding-left: 10rem !important;
  }

  .m-lg-12 {
    margin: 10.5rem !important;
  }

  .mx-lg-12 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }

  .my-lg-12 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }

  .mt-lg-12 {
    margin-top: 10.5rem !important;
  }

  .me-lg-12 {
    margin-right: 10.5rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 10.5rem !important;
  }

  .ms-lg-12 {
    margin-left: 10.5rem !important;
  }

  .m-lg-n12 {
    margin: -10.5rem !important;
  }

  .mx-lg-n12 {
    margin-right: -10.5rem !important;
    margin-left: -10.5rem !important;
  }

  .my-lg-n12 {
    margin-top: -10.5rem !important;
    margin-bottom: -10.5rem !important;
  }

  .mt-lg-n12 {
    margin-top: -10.5rem !important;
  }

  .me-lg-n12 {
    margin-right: -10.5rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -10.5rem !important;
  }

  .ms-lg-n12 {
    margin-left: -10.5rem !important;
  }

  .p-lg-12 {
    padding: 10.5rem !important;
  }

  .px-lg-12 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }

  .py-lg-12 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }

  .pt-lg-12 {
    padding-top: 10.5rem !important;
  }

  .pe-lg-12 {
    padding-right: 10.5rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 10.5rem !important;
  }

  .ps-lg-12 {
    padding-left: 10.5rem !important;
  }

  .m-lg-13 {
    margin: 12rem !important;
  }

  .mx-lg-13 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .my-lg-13 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .mt-lg-13 {
    margin-top: 12rem !important;
  }

  .me-lg-13 {
    margin-right: 12rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 12rem !important;
  }

  .ms-lg-13 {
    margin-left: 12rem !important;
  }

  .m-lg-n13 {
    margin: -12rem !important;
  }

  .mx-lg-n13 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .my-lg-n13 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .mt-lg-n13 {
    margin-top: -12rem !important;
  }

  .me-lg-n13 {
    margin-right: -12rem !important;
  }

  .mb-lg-n13 {
    margin-bottom: -12rem !important;
  }

  .ms-lg-n13 {
    margin-left: -12rem !important;
  }

  .p-lg-13 {
    padding: 12rem !important;
  }

  .px-lg-13 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .py-lg-13 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .pt-lg-13 {
    padding-top: 12rem !important;
  }

  .pe-lg-13 {
    padding-right: 12rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 12rem !important;
  }

  .ps-lg-13 {
    padding-left: 12rem !important;
  }

  .m-lg-14 {
    margin: 12.5rem !important;
  }

  .mx-lg-14 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }

  .my-lg-14 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }

  .mt-lg-14 {
    margin-top: 12.5rem !important;
  }

  .me-lg-14 {
    margin-right: 12.5rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 12.5rem !important;
  }

  .ms-lg-14 {
    margin-left: 12.5rem !important;
  }

  .m-lg-n14 {
    margin: -12.5rem !important;
  }

  .mx-lg-n14 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }

  .my-lg-n14 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }

  .mt-lg-n14 {
    margin-top: -12.5rem !important;
  }

  .me-lg-n14 {
    margin-right: -12.5rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -12.5rem !important;
  }

  .ms-lg-n14 {
    margin-left: -12.5rem !important;
  }

  .p-lg-14 {
    padding: 12.5rem !important;
  }

  .px-lg-14 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }

  .py-lg-14 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  .pt-lg-14 {
    padding-top: 12.5rem !important;
  }

  .pe-lg-14 {
    padding-right: 12.5rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 12.5rem !important;
  }

  .ps-lg-14 {
    padding-left: 12.5rem !important;
  }

  .m-lg-15 {
    margin: 13rem !important;
  }

  .mx-lg-15 {
    margin-right: 13rem !important;
    margin-left: 13rem !important;
  }

  .my-lg-15 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .mt-lg-15 {
    margin-top: 13rem !important;
  }

  .me-lg-15 {
    margin-right: 13rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 13rem !important;
  }

  .ms-lg-15 {
    margin-left: 13rem !important;
  }

  .m-lg-n15 {
    margin: -13rem !important;
  }

  .mx-lg-n15 {
    margin-right: -13rem !important;
    margin-left: -13rem !important;
  }

  .my-lg-n15 {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .mt-lg-n15 {
    margin-top: -13rem !important;
  }

  .me-lg-n15 {
    margin-right: -13rem !important;
  }

  .mb-lg-n15 {
    margin-bottom: -13rem !important;
  }

  .ms-lg-n15 {
    margin-left: -13rem !important;
  }

  .p-lg-15 {
    padding: 13rem !important;
  }

  .px-lg-15 {
    padding-right: 13rem !important;
    padding-left: 13rem !important;
  }

  .py-lg-15 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .pt-lg-15 {
    padding-top: 13rem !important;
  }

  .pe-lg-15 {
    padding-right: 13rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 13rem !important;
  }

  .ps-lg-15 {
    padding-left: 13rem !important;
  }

  .m-lg-16 {
    margin: 13.5rem !important;
  }

  .mx-lg-16 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important;
  }

  .my-lg-16 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important;
  }

  .mt-lg-16 {
    margin-top: 13.5rem !important;
  }

  .me-lg-16 {
    margin-right: 13.5rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 13.5rem !important;
  }

  .ms-lg-16 {
    margin-left: 13.5rem !important;
  }

  .m-lg-n16 {
    margin: -13.5rem !important;
  }

  .mx-lg-n16 {
    margin-right: -13.5rem !important;
    margin-left: -13.5rem !important;
  }

  .my-lg-n16 {
    margin-top: -13.5rem !important;
    margin-bottom: -13.5rem !important;
  }

  .mt-lg-n16 {
    margin-top: -13.5rem !important;
  }

  .me-lg-n16 {
    margin-right: -13.5rem !important;
  }

  .mb-lg-n16 {
    margin-bottom: -13.5rem !important;
  }

  .ms-lg-n16 {
    margin-left: -13.5rem !important;
  }

  .p-lg-16 {
    padding: 13.5rem !important;
  }

  .px-lg-16 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important;
  }

  .py-lg-16 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important;
  }

  .pt-lg-16 {
    padding-top: 13.5rem !important;
  }

  .pe-lg-16 {
    padding-right: 13.5rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 13.5rem !important;
  }

  .ps-lg-16 {
    padding-left: 13.5rem !important;
  }

  .m-lg-17 {
    margin: 14rem !important;
  }

  .mx-lg-17 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .my-lg-17 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .mt-lg-17 {
    margin-top: 14rem !important;
  }

  .me-lg-17 {
    margin-right: 14rem !important;
  }

  .mb-lg-17 {
    margin-bottom: 14rem !important;
  }

  .ms-lg-17 {
    margin-left: 14rem !important;
  }

  .m-lg-n17 {
    margin: -14rem !important;
  }

  .mx-lg-n17 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .my-lg-n17 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .mt-lg-n17 {
    margin-top: -14rem !important;
  }

  .me-lg-n17 {
    margin-right: -14rem !important;
  }

  .mb-lg-n17 {
    margin-bottom: -14rem !important;
  }

  .ms-lg-n17 {
    margin-left: -14rem !important;
  }

  .p-lg-17 {
    padding: 14rem !important;
  }

  .px-lg-17 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .py-lg-17 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .pt-lg-17 {
    padding-top: 14rem !important;
  }

  .pe-lg-17 {
    padding-right: 14rem !important;
  }

  .pb-lg-17 {
    padding-bottom: 14rem !important;
  }

  .ps-lg-17 {
    padding-left: 14rem !important;
  }

  .m-lg-18 {
    margin: 14.5rem !important;
  }

  .mx-lg-18 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important;
  }

  .my-lg-18 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important;
  }

  .mt-lg-18 {
    margin-top: 14.5rem !important;
  }

  .me-lg-18 {
    margin-right: 14.5rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 14.5rem !important;
  }

  .ms-lg-18 {
    margin-left: 14.5rem !important;
  }

  .m-lg-n18 {
    margin: -14.5rem !important;
  }

  .mx-lg-n18 {
    margin-right: -14.5rem !important;
    margin-left: -14.5rem !important;
  }

  .my-lg-n18 {
    margin-top: -14.5rem !important;
    margin-bottom: -14.5rem !important;
  }

  .mt-lg-n18 {
    margin-top: -14.5rem !important;
  }

  .me-lg-n18 {
    margin-right: -14.5rem !important;
  }

  .mb-lg-n18 {
    margin-bottom: -14.5rem !important;
  }

  .ms-lg-n18 {
    margin-left: -14.5rem !important;
  }

  .p-lg-18 {
    padding: 14.5rem !important;
  }

  .px-lg-18 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important;
  }

  .py-lg-18 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important;
  }

  .pt-lg-18 {
    padding-top: 14.5rem !important;
  }

  .pe-lg-18 {
    padding-right: 14.5rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 14.5rem !important;
  }

  .ps-lg-18 {
    padding-left: 14.5rem !important;
  }

  .m-lg-19 {
    margin: 15rem !important;
  }

  .mx-lg-19 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .my-lg-19 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .mt-lg-19 {
    margin-top: 15rem !important;
  }

  .me-lg-19 {
    margin-right: 15rem !important;
  }

  .mb-lg-19 {
    margin-bottom: 15rem !important;
  }

  .ms-lg-19 {
    margin-left: 15rem !important;
  }

  .m-lg-n19 {
    margin: -15rem !important;
  }

  .mx-lg-n19 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }

  .my-lg-n19 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .mt-lg-n19 {
    margin-top: -15rem !important;
  }

  .me-lg-n19 {
    margin-right: -15rem !important;
  }

  .mb-lg-n19 {
    margin-bottom: -15rem !important;
  }

  .ms-lg-n19 {
    margin-left: -15rem !important;
  }

  .p-lg-19 {
    padding: 15rem !important;
  }

  .px-lg-19 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .py-lg-19 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .pt-lg-19 {
    padding-top: 15rem !important;
  }

  .pe-lg-19 {
    padding-right: 15rem !important;
  }

  .pb-lg-19 {
    padding-bottom: 15rem !important;
  }

  .ps-lg-19 {
    padding-left: 15rem !important;
  }

  .m-lg-20 {
    margin: 15.5rem !important;
  }

  .mx-lg-20 {
    margin-right: 15.5rem !important;
    margin-left: 15.5rem !important;
  }

  .my-lg-20 {
    margin-top: 15.5rem !important;
    margin-bottom: 15.5rem !important;
  }

  .mt-lg-20 {
    margin-top: 15.5rem !important;
  }

  .me-lg-20 {
    margin-right: 15.5rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 15.5rem !important;
  }

  .ms-lg-20 {
    margin-left: 15.5rem !important;
  }

  .m-lg-n20 {
    margin: -15.5rem !important;
  }

  .mx-lg-n20 {
    margin-right: -15.5rem !important;
    margin-left: -15.5rem !important;
  }

  .my-lg-n20 {
    margin-top: -15.5rem !important;
    margin-bottom: -15.5rem !important;
  }

  .mt-lg-n20 {
    margin-top: -15.5rem !important;
  }

  .me-lg-n20 {
    margin-right: -15.5rem !important;
  }

  .mb-lg-n20 {
    margin-bottom: -15.5rem !important;
  }

  .ms-lg-n20 {
    margin-left: -15.5rem !important;
  }

  .p-lg-20 {
    padding: 15.5rem !important;
  }

  .px-lg-20 {
    padding-right: 15.5rem !important;
    padding-left: 15.5rem !important;
  }

  .py-lg-20 {
    padding-top: 15.5rem !important;
    padding-bottom: 15.5rem !important;
  }

  .pt-lg-20 {
    padding-top: 15.5rem !important;
  }

  .pe-lg-20 {
    padding-right: 15.5rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 15.5rem !important;
  }

  .ps-lg-20 {
    padding-left: 15.5rem !important;
  }

  .m-lg-21 {
    margin: 18.5rem !important;
  }

  .mx-lg-21 {
    margin-right: 18.5rem !important;
    margin-left: 18.5rem !important;
  }

  .my-lg-21 {
    margin-top: 18.5rem !important;
    margin-bottom: 18.5rem !important;
  }

  .mt-lg-21 {
    margin-top: 18.5rem !important;
  }

  .me-lg-21 {
    margin-right: 18.5rem !important;
  }

  .mb-lg-21 {
    margin-bottom: 18.5rem !important;
  }

  .ms-lg-21 {
    margin-left: 18.5rem !important;
  }

  .m-lg-n21 {
    margin: -18.5rem !important;
  }

  .mx-lg-n21 {
    margin-right: -18.5rem !important;
    margin-left: -18.5rem !important;
  }

  .my-lg-n21 {
    margin-top: -18.5rem !important;
    margin-bottom: -18.5rem !important;
  }

  .mt-lg-n21 {
    margin-top: -18.5rem !important;
  }

  .me-lg-n21 {
    margin-right: -18.5rem !important;
  }

  .mb-lg-n21 {
    margin-bottom: -18.5rem !important;
  }

  .ms-lg-n21 {
    margin-left: -18.5rem !important;
  }

  .p-lg-21 {
    padding: 18.5rem !important;
  }

  .px-lg-21 {
    padding-right: 18.5rem !important;
    padding-left: 18.5rem !important;
  }

  .py-lg-21 {
    padding-top: 18.5rem !important;
    padding-bottom: 18.5rem !important;
  }

  .pt-lg-21 {
    padding-top: 18.5rem !important;
  }

  .pe-lg-21 {
    padding-right: 18.5rem !important;
  }

  .pb-lg-21 {
    padding-bottom: 18.5rem !important;
  }

  .ps-lg-21 {
    padding-left: 18.5rem !important;
  }

  .m-lg-22 {
    margin: 3.75rem !important;
  }

  .mx-lg-22 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .my-lg-22 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .mt-lg-22 {
    margin-top: 3.75rem !important;
  }

  .me-lg-22 {
    margin-right: 3.75rem !important;
  }

  .mb-lg-22 {
    margin-bottom: 3.75rem !important;
  }

  .ms-lg-22 {
    margin-left: 3.75rem !important;
  }

  .m-lg-n22 {
    margin: -3.75rem !important;
  }

  .mx-lg-n22 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .my-lg-n22 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .mt-lg-n22 {
    margin-top: -3.75rem !important;
  }

  .me-lg-n22 {
    margin-right: -3.75rem !important;
  }

  .mb-lg-n22 {
    margin-bottom: -3.75rem !important;
  }

  .ms-lg-n22 {
    margin-left: -3.75rem !important;
  }

  .p-lg-22 {
    padding: 3.75rem !important;
  }

  .px-lg-22 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .py-lg-22 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .pt-lg-22 {
    padding-top: 3.75rem !important;
  }

  .pe-lg-22 {
    padding-right: 3.75rem !important;
  }

  .pb-lg-22 {
    padding-bottom: 3.75rem !important;
  }

  .ps-lg-22 {
    padding-left: 3.75rem !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0rem !important;
  }

  .mx-xl-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }

  .my-xl-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  .mt-xl-0 {
    margin-top: 0rem !important;
  }

  .me-xl-0 {
    margin-right: 0rem !important;
  }

  .mb-xl-0 {
    margin-bottom: 0rem !important;
  }

  .ms-xl-0 {
    margin-left: 0rem !important;
  }

  .m-xl-n0 {
    margin: -0rem !important;
  }

  .mx-xl-n0 {
    margin-right: -0rem !important;
    margin-left: -0rem !important;
  }

  .my-xl-n0 {
    margin-top: -0rem !important;
    margin-bottom: -0rem !important;
  }

  .mt-xl-n0 {
    margin-top: -0rem !important;
  }

  .me-xl-n0 {
    margin-right: -0rem !important;
  }

  .mb-xl-n0 {
    margin-bottom: -0rem !important;
  }

  .ms-xl-n0 {
    margin-left: -0rem !important;
  }

  .p-xl-0 {
    padding: 0rem !important;
  }

  .px-xl-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .py-xl-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .pt-xl-0 {
    padding-top: 0rem !important;
  }

  .pe-xl-0 {
    padding-right: 0rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0rem !important;
  }

  .ps-xl-0 {
    padding-left: 0rem !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.625rem !important;
  }

  .mx-xl-3 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }

  .my-xl-3 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .mt-xl-3 {
    margin-top: 0.625rem !important;
  }

  .me-xl-3 {
    margin-right: 0.625rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 0.625rem !important;
  }

  .ms-xl-3 {
    margin-left: 0.625rem !important;
  }

  .m-xl-n3 {
    margin: -0.625rem !important;
  }

  .mx-xl-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }

  .my-xl-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .mt-xl-n3 {
    margin-top: -0.625rem !important;
  }

  .me-xl-n3 {
    margin-right: -0.625rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -0.625rem !important;
  }

  .ms-xl-n3 {
    margin-left: -0.625rem !important;
  }

  .p-xl-3 {
    padding: 0.625rem !important;
  }

  .px-xl-3 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }

  .py-xl-3 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .pt-xl-3 {
    padding-top: 0.625rem !important;
  }

  .pe-xl-3 {
    padding-right: 0.625rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 0.625rem !important;
  }

  .ps-xl-3 {
    padding-left: 0.625rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 2rem !important;
  }

  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .mt-xl-5 {
    margin-top: 2rem !important;
  }

  .me-xl-5 {
    margin-right: 2rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .ms-xl-5 {
    margin-left: 2rem !important;
  }

  .m-xl-n5 {
    margin: -2rem !important;
  }

  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .mt-xl-n5 {
    margin-top: -2rem !important;
  }

  .me-xl-n5 {
    margin-right: -2rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }

  .ms-xl-n5 {
    margin-left: -2rem !important;
  }

  .p-xl-5 {
    padding: 2rem !important;
  }

  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .pt-xl-5 {
    padding-top: 2rem !important;
  }

  .pe-xl-5 {
    padding-right: 2rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }

  .ps-xl-5 {
    padding-left: 2rem !important;
  }

  .m-xl-6 {
    margin: 2.5rem !important;
  }

  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }

  .me-xl-6 {
    margin-right: 2.5rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }

  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }

  .m-xl-n6 {
    margin: -2.5rem !important;
  }

  .mx-xl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-xl-n6 {
    margin-top: -2.5rem !important;
  }

  .me-xl-n6 {
    margin-right: -2.5rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .ms-xl-n6 {
    margin-left: -2.5rem !important;
  }

  .p-xl-6 {
    padding: 2.5rem !important;
  }

  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }

  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }

  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }

  .m-xl-7 {
    margin: 3rem !important;
  }

  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .mt-xl-7 {
    margin-top: 3rem !important;
  }

  .me-xl-7 {
    margin-right: 3rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }

  .ms-xl-7 {
    margin-left: 3rem !important;
  }

  .m-xl-n7 {
    margin: -3rem !important;
  }

  .mx-xl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n7 {
    margin-top: -3rem !important;
  }

  .me-xl-n7 {
    margin-right: -3rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n7 {
    margin-left: -3rem !important;
  }

  .p-xl-7 {
    padding: 3rem !important;
  }

  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-7 {
    padding-top: 3rem !important;
  }

  .pe-xl-7 {
    padding-right: 3rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-7 {
    padding-left: 3rem !important;
  }

  .m-xl-8 {
    margin: 4.5rem !important;
  }

  .mx-xl-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .my-xl-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .mt-xl-8 {
    margin-top: 4.5rem !important;
  }

  .me-xl-8 {
    margin-right: 4.5rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 4.5rem !important;
  }

  .ms-xl-8 {
    margin-left: 4.5rem !important;
  }

  .m-xl-n8 {
    margin: -4.5rem !important;
  }

  .mx-xl-n8 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-xl-n8 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-xl-n8 {
    margin-top: -4.5rem !important;
  }

  .me-xl-n8 {
    margin-right: -4.5rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -4.5rem !important;
  }

  .ms-xl-n8 {
    margin-left: -4.5rem !important;
  }

  .p-xl-8 {
    padding: 4.5rem !important;
  }

  .px-xl-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-xl-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xl-8 {
    padding-top: 4.5rem !important;
  }

  .pe-xl-8 {
    padding-right: 4.5rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xl-8 {
    padding-left: 4.5rem !important;
  }

  .m-xl-9 {
    margin: 6.25rem !important;
  }

  .mx-xl-9 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }

  .my-xl-9 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }

  .mt-xl-9 {
    margin-top: 6.25rem !important;
  }

  .me-xl-9 {
    margin-right: 6.25rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 6.25rem !important;
  }

  .ms-xl-9 {
    margin-left: 6.25rem !important;
  }

  .m-xl-n9 {
    margin: -6.25rem !important;
  }

  .mx-xl-n9 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }

  .my-xl-n9 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }

  .mt-xl-n9 {
    margin-top: -6.25rem !important;
  }

  .me-xl-n9 {
    margin-right: -6.25rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -6.25rem !important;
  }

  .ms-xl-n9 {
    margin-left: -6.25rem !important;
  }

  .p-xl-9 {
    padding: 6.25rem !important;
  }

  .px-xl-9 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }

  .py-xl-9 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }

  .pt-xl-9 {
    padding-top: 6.25rem !important;
  }

  .pe-xl-9 {
    padding-right: 6.25rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 6.25rem !important;
  }

  .ps-xl-9 {
    padding-left: 6.25rem !important;
  }

  .m-xl-10 {
    margin: 9.375rem !important;
  }

  .mx-xl-10 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }

  .my-xl-10 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }

  .mt-xl-10 {
    margin-top: 9.375rem !important;
  }

  .me-xl-10 {
    margin-right: 9.375rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 9.375rem !important;
  }

  .ms-xl-10 {
    margin-left: 9.375rem !important;
  }

  .m-xl-n10 {
    margin: -9.375rem !important;
  }

  .mx-xl-n10 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }

  .my-xl-n10 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }

  .mt-xl-n10 {
    margin-top: -9.375rem !important;
  }

  .me-xl-n10 {
    margin-right: -9.375rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -9.375rem !important;
  }

  .ms-xl-n10 {
    margin-left: -9.375rem !important;
  }

  .p-xl-10 {
    padding: 9.375rem !important;
  }

  .px-xl-10 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }

  .py-xl-10 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }

  .pt-xl-10 {
    padding-top: 9.375rem !important;
  }

  .pe-xl-10 {
    padding-right: 9.375rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 9.375rem !important;
  }

  .ps-xl-10 {
    padding-left: 9.375rem !important;
  }

  .m-xl-11 {
    margin: 10rem !important;
  }

  .mx-xl-11 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .my-xl-11 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .mt-xl-11 {
    margin-top: 10rem !important;
  }

  .me-xl-11 {
    margin-right: 10rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 10rem !important;
  }

  .ms-xl-11 {
    margin-left: 10rem !important;
  }

  .m-xl-n11 {
    margin: -10rem !important;
  }

  .mx-xl-n11 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-xl-n11 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-xl-n11 {
    margin-top: -10rem !important;
  }

  .me-xl-n11 {
    margin-right: -10rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -10rem !important;
  }

  .ms-xl-n11 {
    margin-left: -10rem !important;
  }

  .p-xl-11 {
    padding: 10rem !important;
  }

  .px-xl-11 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-xl-11 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-xl-11 {
    padding-top: 10rem !important;
  }

  .pe-xl-11 {
    padding-right: 10rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 10rem !important;
  }

  .ps-xl-11 {
    padding-left: 10rem !important;
  }

  .m-xl-12 {
    margin: 10.5rem !important;
  }

  .mx-xl-12 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }

  .my-xl-12 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }

  .mt-xl-12 {
    margin-top: 10.5rem !important;
  }

  .me-xl-12 {
    margin-right: 10.5rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 10.5rem !important;
  }

  .ms-xl-12 {
    margin-left: 10.5rem !important;
  }

  .m-xl-n12 {
    margin: -10.5rem !important;
  }

  .mx-xl-n12 {
    margin-right: -10.5rem !important;
    margin-left: -10.5rem !important;
  }

  .my-xl-n12 {
    margin-top: -10.5rem !important;
    margin-bottom: -10.5rem !important;
  }

  .mt-xl-n12 {
    margin-top: -10.5rem !important;
  }

  .me-xl-n12 {
    margin-right: -10.5rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -10.5rem !important;
  }

  .ms-xl-n12 {
    margin-left: -10.5rem !important;
  }

  .p-xl-12 {
    padding: 10.5rem !important;
  }

  .px-xl-12 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }

  .py-xl-12 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }

  .pt-xl-12 {
    padding-top: 10.5rem !important;
  }

  .pe-xl-12 {
    padding-right: 10.5rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 10.5rem !important;
  }

  .ps-xl-12 {
    padding-left: 10.5rem !important;
  }

  .m-xl-13 {
    margin: 12rem !important;
  }

  .mx-xl-13 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .my-xl-13 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .mt-xl-13 {
    margin-top: 12rem !important;
  }

  .me-xl-13 {
    margin-right: 12rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 12rem !important;
  }

  .ms-xl-13 {
    margin-left: 12rem !important;
  }

  .m-xl-n13 {
    margin: -12rem !important;
  }

  .mx-xl-n13 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .my-xl-n13 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .mt-xl-n13 {
    margin-top: -12rem !important;
  }

  .me-xl-n13 {
    margin-right: -12rem !important;
  }

  .mb-xl-n13 {
    margin-bottom: -12rem !important;
  }

  .ms-xl-n13 {
    margin-left: -12rem !important;
  }

  .p-xl-13 {
    padding: 12rem !important;
  }

  .px-xl-13 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .py-xl-13 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .pt-xl-13 {
    padding-top: 12rem !important;
  }

  .pe-xl-13 {
    padding-right: 12rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 12rem !important;
  }

  .ps-xl-13 {
    padding-left: 12rem !important;
  }

  .m-xl-14 {
    margin: 12.5rem !important;
  }

  .mx-xl-14 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }

  .my-xl-14 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }

  .mt-xl-14 {
    margin-top: 12.5rem !important;
  }

  .me-xl-14 {
    margin-right: 12.5rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 12.5rem !important;
  }

  .ms-xl-14 {
    margin-left: 12.5rem !important;
  }

  .m-xl-n14 {
    margin: -12.5rem !important;
  }

  .mx-xl-n14 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }

  .my-xl-n14 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }

  .mt-xl-n14 {
    margin-top: -12.5rem !important;
  }

  .me-xl-n14 {
    margin-right: -12.5rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -12.5rem !important;
  }

  .ms-xl-n14 {
    margin-left: -12.5rem !important;
  }

  .p-xl-14 {
    padding: 12.5rem !important;
  }

  .px-xl-14 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }

  .py-xl-14 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  .pt-xl-14 {
    padding-top: 12.5rem !important;
  }

  .pe-xl-14 {
    padding-right: 12.5rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 12.5rem !important;
  }

  .ps-xl-14 {
    padding-left: 12.5rem !important;
  }

  .m-xl-15 {
    margin: 13rem !important;
  }

  .mx-xl-15 {
    margin-right: 13rem !important;
    margin-left: 13rem !important;
  }

  .my-xl-15 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .mt-xl-15 {
    margin-top: 13rem !important;
  }

  .me-xl-15 {
    margin-right: 13rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 13rem !important;
  }

  .ms-xl-15 {
    margin-left: 13rem !important;
  }

  .m-xl-n15 {
    margin: -13rem !important;
  }

  .mx-xl-n15 {
    margin-right: -13rem !important;
    margin-left: -13rem !important;
  }

  .my-xl-n15 {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .mt-xl-n15 {
    margin-top: -13rem !important;
  }

  .me-xl-n15 {
    margin-right: -13rem !important;
  }

  .mb-xl-n15 {
    margin-bottom: -13rem !important;
  }

  .ms-xl-n15 {
    margin-left: -13rem !important;
  }

  .p-xl-15 {
    padding: 13rem !important;
  }

  .px-xl-15 {
    padding-right: 13rem !important;
    padding-left: 13rem !important;
  }

  .py-xl-15 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .pt-xl-15 {
    padding-top: 13rem !important;
  }

  .pe-xl-15 {
    padding-right: 13rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 13rem !important;
  }

  .ps-xl-15 {
    padding-left: 13rem !important;
  }

  .m-xl-16 {
    margin: 13.5rem !important;
  }

  .mx-xl-16 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important;
  }

  .my-xl-16 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important;
  }

  .mt-xl-16 {
    margin-top: 13.5rem !important;
  }

  .me-xl-16 {
    margin-right: 13.5rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 13.5rem !important;
  }

  .ms-xl-16 {
    margin-left: 13.5rem !important;
  }

  .m-xl-n16 {
    margin: -13.5rem !important;
  }

  .mx-xl-n16 {
    margin-right: -13.5rem !important;
    margin-left: -13.5rem !important;
  }

  .my-xl-n16 {
    margin-top: -13.5rem !important;
    margin-bottom: -13.5rem !important;
  }

  .mt-xl-n16 {
    margin-top: -13.5rem !important;
  }

  .me-xl-n16 {
    margin-right: -13.5rem !important;
  }

  .mb-xl-n16 {
    margin-bottom: -13.5rem !important;
  }

  .ms-xl-n16 {
    margin-left: -13.5rem !important;
  }

  .p-xl-16 {
    padding: 13.5rem !important;
  }

  .px-xl-16 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important;
  }

  .py-xl-16 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important;
  }

  .pt-xl-16 {
    padding-top: 13.5rem !important;
  }

  .pe-xl-16 {
    padding-right: 13.5rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 13.5rem !important;
  }

  .ps-xl-16 {
    padding-left: 13.5rem !important;
  }

  .m-xl-17 {
    margin: 14rem !important;
  }

  .mx-xl-17 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .my-xl-17 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .mt-xl-17 {
    margin-top: 14rem !important;
  }

  .me-xl-17 {
    margin-right: 14rem !important;
  }

  .mb-xl-17 {
    margin-bottom: 14rem !important;
  }

  .ms-xl-17 {
    margin-left: 14rem !important;
  }

  .m-xl-n17 {
    margin: -14rem !important;
  }

  .mx-xl-n17 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .my-xl-n17 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .mt-xl-n17 {
    margin-top: -14rem !important;
  }

  .me-xl-n17 {
    margin-right: -14rem !important;
  }

  .mb-xl-n17 {
    margin-bottom: -14rem !important;
  }

  .ms-xl-n17 {
    margin-left: -14rem !important;
  }

  .p-xl-17 {
    padding: 14rem !important;
  }

  .px-xl-17 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .py-xl-17 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .pt-xl-17 {
    padding-top: 14rem !important;
  }

  .pe-xl-17 {
    padding-right: 14rem !important;
  }

  .pb-xl-17 {
    padding-bottom: 14rem !important;
  }

  .ps-xl-17 {
    padding-left: 14rem !important;
  }

  .m-xl-18 {
    margin: 14.5rem !important;
  }

  .mx-xl-18 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important;
  }

  .my-xl-18 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important;
  }

  .mt-xl-18 {
    margin-top: 14.5rem !important;
  }

  .me-xl-18 {
    margin-right: 14.5rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 14.5rem !important;
  }

  .ms-xl-18 {
    margin-left: 14.5rem !important;
  }

  .m-xl-n18 {
    margin: -14.5rem !important;
  }

  .mx-xl-n18 {
    margin-right: -14.5rem !important;
    margin-left: -14.5rem !important;
  }

  .my-xl-n18 {
    margin-top: -14.5rem !important;
    margin-bottom: -14.5rem !important;
  }

  .mt-xl-n18 {
    margin-top: -14.5rem !important;
  }

  .me-xl-n18 {
    margin-right: -14.5rem !important;
  }

  .mb-xl-n18 {
    margin-bottom: -14.5rem !important;
  }

  .ms-xl-n18 {
    margin-left: -14.5rem !important;
  }

  .p-xl-18 {
    padding: 14.5rem !important;
  }

  .px-xl-18 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important;
  }

  .py-xl-18 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important;
  }

  .pt-xl-18 {
    padding-top: 14.5rem !important;
  }

  .pe-xl-18 {
    padding-right: 14.5rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 14.5rem !important;
  }

  .ps-xl-18 {
    padding-left: 14.5rem !important;
  }

  .m-xl-19 {
    margin: 15rem !important;
  }

  .mx-xl-19 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .my-xl-19 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .mt-xl-19 {
    margin-top: 15rem !important;
  }

  .me-xl-19 {
    margin-right: 15rem !important;
  }

  .mb-xl-19 {
    margin-bottom: 15rem !important;
  }

  .ms-xl-19 {
    margin-left: 15rem !important;
  }

  .m-xl-n19 {
    margin: -15rem !important;
  }

  .mx-xl-n19 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }

  .my-xl-n19 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .mt-xl-n19 {
    margin-top: -15rem !important;
  }

  .me-xl-n19 {
    margin-right: -15rem !important;
  }

  .mb-xl-n19 {
    margin-bottom: -15rem !important;
  }

  .ms-xl-n19 {
    margin-left: -15rem !important;
  }

  .p-xl-19 {
    padding: 15rem !important;
  }

  .px-xl-19 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .py-xl-19 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .pt-xl-19 {
    padding-top: 15rem !important;
  }

  .pe-xl-19 {
    padding-right: 15rem !important;
  }

  .pb-xl-19 {
    padding-bottom: 15rem !important;
  }

  .ps-xl-19 {
    padding-left: 15rem !important;
  }

  .m-xl-20 {
    margin: 15.5rem !important;
  }

  .mx-xl-20 {
    margin-right: 15.5rem !important;
    margin-left: 15.5rem !important;
  }

  .my-xl-20 {
    margin-top: 15.5rem !important;
    margin-bottom: 15.5rem !important;
  }

  .mt-xl-20 {
    margin-top: 15.5rem !important;
  }

  .me-xl-20 {
    margin-right: 15.5rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 15.5rem !important;
  }

  .ms-xl-20 {
    margin-left: 15.5rem !important;
  }

  .m-xl-n20 {
    margin: -15.5rem !important;
  }

  .mx-xl-n20 {
    margin-right: -15.5rem !important;
    margin-left: -15.5rem !important;
  }

  .my-xl-n20 {
    margin-top: -15.5rem !important;
    margin-bottom: -15.5rem !important;
  }

  .mt-xl-n20 {
    margin-top: -15.5rem !important;
  }

  .me-xl-n20 {
    margin-right: -15.5rem !important;
  }

  .mb-xl-n20 {
    margin-bottom: -15.5rem !important;
  }

  .ms-xl-n20 {
    margin-left: -15.5rem !important;
  }

  .p-xl-20 {
    padding: 15.5rem !important;
  }

  .px-xl-20 {
    padding-right: 15.5rem !important;
    padding-left: 15.5rem !important;
  }

  .py-xl-20 {
    padding-top: 15.5rem !important;
    padding-bottom: 15.5rem !important;
  }

  .pt-xl-20 {
    padding-top: 15.5rem !important;
  }

  .pe-xl-20 {
    padding-right: 15.5rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 15.5rem !important;
  }

  .ps-xl-20 {
    padding-left: 15.5rem !important;
  }

  .m-xl-21 {
    margin: 18.5rem !important;
  }

  .mx-xl-21 {
    margin-right: 18.5rem !important;
    margin-left: 18.5rem !important;
  }

  .my-xl-21 {
    margin-top: 18.5rem !important;
    margin-bottom: 18.5rem !important;
  }

  .mt-xl-21 {
    margin-top: 18.5rem !important;
  }

  .me-xl-21 {
    margin-right: 18.5rem !important;
  }

  .mb-xl-21 {
    margin-bottom: 18.5rem !important;
  }

  .ms-xl-21 {
    margin-left: 18.5rem !important;
  }

  .m-xl-n21 {
    margin: -18.5rem !important;
  }

  .mx-xl-n21 {
    margin-right: -18.5rem !important;
    margin-left: -18.5rem !important;
  }

  .my-xl-n21 {
    margin-top: -18.5rem !important;
    margin-bottom: -18.5rem !important;
  }

  .mt-xl-n21 {
    margin-top: -18.5rem !important;
  }

  .me-xl-n21 {
    margin-right: -18.5rem !important;
  }

  .mb-xl-n21 {
    margin-bottom: -18.5rem !important;
  }

  .ms-xl-n21 {
    margin-left: -18.5rem !important;
  }

  .p-xl-21 {
    padding: 18.5rem !important;
  }

  .px-xl-21 {
    padding-right: 18.5rem !important;
    padding-left: 18.5rem !important;
  }

  .py-xl-21 {
    padding-top: 18.5rem !important;
    padding-bottom: 18.5rem !important;
  }

  .pt-xl-21 {
    padding-top: 18.5rem !important;
  }

  .pe-xl-21 {
    padding-right: 18.5rem !important;
  }

  .pb-xl-21 {
    padding-bottom: 18.5rem !important;
  }

  .ps-xl-21 {
    padding-left: 18.5rem !important;
  }

  .m-xl-22 {
    margin: 3.75rem !important;
  }

  .mx-xl-22 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .my-xl-22 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .mt-xl-22 {
    margin-top: 3.75rem !important;
  }

  .me-xl-22 {
    margin-right: 3.75rem !important;
  }

  .mb-xl-22 {
    margin-bottom: 3.75rem !important;
  }

  .ms-xl-22 {
    margin-left: 3.75rem !important;
  }

  .m-xl-n22 {
    margin: -3.75rem !important;
  }

  .mx-xl-n22 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .my-xl-n22 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .mt-xl-n22 {
    margin-top: -3.75rem !important;
  }

  .me-xl-n22 {
    margin-right: -3.75rem !important;
  }

  .mb-xl-n22 {
    margin-bottom: -3.75rem !important;
  }

  .ms-xl-n22 {
    margin-left: -3.75rem !important;
  }

  .p-xl-22 {
    padding: 3.75rem !important;
  }

  .px-xl-22 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .py-xl-22 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .pt-xl-22 {
    padding-top: 3.75rem !important;
  }

  .pe-xl-22 {
    padding-right: 3.75rem !important;
  }

  .pb-xl-22 {
    padding-bottom: 3.75rem !important;
  }

  .ps-xl-22 {
    padding-left: 3.75rem !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0rem !important;
  }

  .mx-xxl-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }

  .my-xxl-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  .mt-xxl-0 {
    margin-top: 0rem !important;
  }

  .me-xxl-0 {
    margin-right: 0rem !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0rem !important;
  }

  .ms-xxl-0 {
    margin-left: 0rem !important;
  }

  .m-xxl-n0 {
    margin: -0rem !important;
  }

  .mx-xxl-n0 {
    margin-right: -0rem !important;
    margin-left: -0rem !important;
  }

  .my-xxl-n0 {
    margin-top: -0rem !important;
    margin-bottom: -0rem !important;
  }

  .mt-xxl-n0 {
    margin-top: -0rem !important;
  }

  .me-xxl-n0 {
    margin-right: -0rem !important;
  }

  .mb-xxl-n0 {
    margin-bottom: -0rem !important;
  }

  .ms-xxl-n0 {
    margin-left: -0rem !important;
  }

  .p-xxl-0 {
    padding: 0rem !important;
  }

  .px-xxl-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .py-xxl-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0rem !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.625rem !important;
  }

  .mx-xxl-3 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }

  .my-xxl-3 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .mt-xxl-3 {
    margin-top: 0.625rem !important;
  }

  .me-xxl-3 {
    margin-right: 0.625rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 0.625rem !important;
  }

  .ms-xxl-3 {
    margin-left: 0.625rem !important;
  }

  .m-xxl-n3 {
    margin: -0.625rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }

  .my-xxl-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -0.625rem !important;
  }

  .me-xxl-n3 {
    margin-right: -0.625rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -0.625rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -0.625rem !important;
  }

  .p-xxl-3 {
    padding: 0.625rem !important;
  }

  .px-xxl-3 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }

  .py-xxl-3 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .pt-xxl-3 {
    padding-top: 0.625rem !important;
  }

  .pe-xxl-3 {
    padding-right: 0.625rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 0.625rem !important;
  }

  .ps-xxl-3 {
    padding-left: 0.625rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 2rem !important;
  }

  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2rem !important;
  }

  .me-xxl-5 {
    margin-right: 2rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2rem !important;
  }

  .m-xxl-n5 {
    margin: -2rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }

  .me-xxl-n5 {
    margin-right: -2rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }

  .p-xxl-5 {
    padding: 2rem !important;
  }

  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2rem !important;
  }

  .m-xxl-6 {
    margin: 2.5rem !important;
  }

  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }

  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }

  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }

  .m-xxl-n6 {
    margin: -2.5rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -2.5rem !important;
  }

  .me-xxl-n6 {
    margin-right: -2.5rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -2.5rem !important;
  }

  .p-xxl-6 {
    padding: 2.5rem !important;
  }

  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }

  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }

  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }

  .m-xxl-7 {
    margin: 3rem !important;
  }

  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .mt-xxl-7 {
    margin-top: 3rem !important;
  }

  .me-xxl-7 {
    margin-right: 3rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }

  .ms-xxl-7 {
    margin-left: 3rem !important;
  }

  .m-xxl-n7 {
    margin: -3rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -3rem !important;
  }

  .me-xxl-n7 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -3rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -3rem !important;
  }

  .p-xxl-7 {
    padding: 3rem !important;
  }

  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-7 {
    padding-top: 3rem !important;
  }

  .pe-xxl-7 {
    padding-right: 3rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-7 {
    padding-left: 3rem !important;
  }

  .m-xxl-8 {
    margin: 4.5rem !important;
  }

  .mx-xxl-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .my-xxl-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .mt-xxl-8 {
    margin-top: 4.5rem !important;
  }

  .me-xxl-8 {
    margin-right: 4.5rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 4.5rem !important;
  }

  .ms-xxl-8 {
    margin-left: 4.5rem !important;
  }

  .m-xxl-n8 {
    margin: -4.5rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .my-xxl-n8 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -4.5rem !important;
  }

  .me-xxl-n8 {
    margin-right: -4.5rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -4.5rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -4.5rem !important;
  }

  .p-xxl-8 {
    padding: 4.5rem !important;
  }

  .px-xxl-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-xxl-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xxl-8 {
    padding-top: 4.5rem !important;
  }

  .pe-xxl-8 {
    padding-right: 4.5rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xxl-8 {
    padding-left: 4.5rem !important;
  }

  .m-xxl-9 {
    margin: 6.25rem !important;
  }

  .mx-xxl-9 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }

  .my-xxl-9 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }

  .mt-xxl-9 {
    margin-top: 6.25rem !important;
  }

  .me-xxl-9 {
    margin-right: 6.25rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 6.25rem !important;
  }

  .ms-xxl-9 {
    margin-left: 6.25rem !important;
  }

  .m-xxl-n9 {
    margin: -6.25rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }

  .my-xxl-n9 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -6.25rem !important;
  }

  .me-xxl-n9 {
    margin-right: -6.25rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -6.25rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -6.25rem !important;
  }

  .p-xxl-9 {
    padding: 6.25rem !important;
  }

  .px-xxl-9 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }

  .py-xxl-9 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }

  .pt-xxl-9 {
    padding-top: 6.25rem !important;
  }

  .pe-xxl-9 {
    padding-right: 6.25rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 6.25rem !important;
  }

  .ps-xxl-9 {
    padding-left: 6.25rem !important;
  }

  .m-xxl-10 {
    margin: 9.375rem !important;
  }

  .mx-xxl-10 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }

  .my-xxl-10 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }

  .mt-xxl-10 {
    margin-top: 9.375rem !important;
  }

  .me-xxl-10 {
    margin-right: 9.375rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 9.375rem !important;
  }

  .ms-xxl-10 {
    margin-left: 9.375rem !important;
  }

  .m-xxl-n10 {
    margin: -9.375rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }

  .my-xxl-n10 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -9.375rem !important;
  }

  .me-xxl-n10 {
    margin-right: -9.375rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -9.375rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -9.375rem !important;
  }

  .p-xxl-10 {
    padding: 9.375rem !important;
  }

  .px-xxl-10 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }

  .py-xxl-10 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }

  .pt-xxl-10 {
    padding-top: 9.375rem !important;
  }

  .pe-xxl-10 {
    padding-right: 9.375rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 9.375rem !important;
  }

  .ps-xxl-10 {
    padding-left: 9.375rem !important;
  }

  .m-xxl-11 {
    margin: 10rem !important;
  }

  .mx-xxl-11 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .my-xxl-11 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .mt-xxl-11 {
    margin-top: 10rem !important;
  }

  .me-xxl-11 {
    margin-right: 10rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 10rem !important;
  }

  .ms-xxl-11 {
    margin-left: 10rem !important;
  }

  .m-xxl-n11 {
    margin: -10rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-xxl-n11 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -10rem !important;
  }

  .me-xxl-n11 {
    margin-right: -10rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -10rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -10rem !important;
  }

  .p-xxl-11 {
    padding: 10rem !important;
  }

  .px-xxl-11 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-xxl-11 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-xxl-11 {
    padding-top: 10rem !important;
  }

  .pe-xxl-11 {
    padding-right: 10rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 10rem !important;
  }

  .ps-xxl-11 {
    padding-left: 10rem !important;
  }

  .m-xxl-12 {
    margin: 10.5rem !important;
  }

  .mx-xxl-12 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }

  .my-xxl-12 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }

  .mt-xxl-12 {
    margin-top: 10.5rem !important;
  }

  .me-xxl-12 {
    margin-right: 10.5rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 10.5rem !important;
  }

  .ms-xxl-12 {
    margin-left: 10.5rem !important;
  }

  .m-xxl-n12 {
    margin: -10.5rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -10.5rem !important;
    margin-left: -10.5rem !important;
  }

  .my-xxl-n12 {
    margin-top: -10.5rem !important;
    margin-bottom: -10.5rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -10.5rem !important;
  }

  .me-xxl-n12 {
    margin-right: -10.5rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -10.5rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -10.5rem !important;
  }

  .p-xxl-12 {
    padding: 10.5rem !important;
  }

  .px-xxl-12 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }

  .py-xxl-12 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }

  .pt-xxl-12 {
    padding-top: 10.5rem !important;
  }

  .pe-xxl-12 {
    padding-right: 10.5rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 10.5rem !important;
  }

  .ps-xxl-12 {
    padding-left: 10.5rem !important;
  }

  .m-xxl-13 {
    margin: 12rem !important;
  }

  .mx-xxl-13 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .my-xxl-13 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .mt-xxl-13 {
    margin-top: 12rem !important;
  }

  .me-xxl-13 {
    margin-right: 12rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 12rem !important;
  }

  .ms-xxl-13 {
    margin-left: 12rem !important;
  }

  .m-xxl-n13 {
    margin: -12rem !important;
  }

  .mx-xxl-n13 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .my-xxl-n13 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .mt-xxl-n13 {
    margin-top: -12rem !important;
  }

  .me-xxl-n13 {
    margin-right: -12rem !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -12rem !important;
  }

  .ms-xxl-n13 {
    margin-left: -12rem !important;
  }

  .p-xxl-13 {
    padding: 12rem !important;
  }

  .px-xxl-13 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .py-xxl-13 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .pt-xxl-13 {
    padding-top: 12rem !important;
  }

  .pe-xxl-13 {
    padding-right: 12rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 12rem !important;
  }

  .ps-xxl-13 {
    padding-left: 12rem !important;
  }

  .m-xxl-14 {
    margin: 12.5rem !important;
  }

  .mx-xxl-14 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }

  .my-xxl-14 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }

  .mt-xxl-14 {
    margin-top: 12.5rem !important;
  }

  .me-xxl-14 {
    margin-right: 12.5rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 12.5rem !important;
  }

  .ms-xxl-14 {
    margin-left: 12.5rem !important;
  }

  .m-xxl-n14 {
    margin: -12.5rem !important;
  }

  .mx-xxl-n14 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }

  .my-xxl-n14 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -12.5rem !important;
  }

  .me-xxl-n14 {
    margin-right: -12.5rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -12.5rem !important;
  }

  .ms-xxl-n14 {
    margin-left: -12.5rem !important;
  }

  .p-xxl-14 {
    padding: 12.5rem !important;
  }

  .px-xxl-14 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }

  .py-xxl-14 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  .pt-xxl-14 {
    padding-top: 12.5rem !important;
  }

  .pe-xxl-14 {
    padding-right: 12.5rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 12.5rem !important;
  }

  .ps-xxl-14 {
    padding-left: 12.5rem !important;
  }

  .m-xxl-15 {
    margin: 13rem !important;
  }

  .mx-xxl-15 {
    margin-right: 13rem !important;
    margin-left: 13rem !important;
  }

  .my-xxl-15 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .mt-xxl-15 {
    margin-top: 13rem !important;
  }

  .me-xxl-15 {
    margin-right: 13rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 13rem !important;
  }

  .ms-xxl-15 {
    margin-left: 13rem !important;
  }

  .m-xxl-n15 {
    margin: -13rem !important;
  }

  .mx-xxl-n15 {
    margin-right: -13rem !important;
    margin-left: -13rem !important;
  }

  .my-xxl-n15 {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .mt-xxl-n15 {
    margin-top: -13rem !important;
  }

  .me-xxl-n15 {
    margin-right: -13rem !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -13rem !important;
  }

  .ms-xxl-n15 {
    margin-left: -13rem !important;
  }

  .p-xxl-15 {
    padding: 13rem !important;
  }

  .px-xxl-15 {
    padding-right: 13rem !important;
    padding-left: 13rem !important;
  }

  .py-xxl-15 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .pt-xxl-15 {
    padding-top: 13rem !important;
  }

  .pe-xxl-15 {
    padding-right: 13rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 13rem !important;
  }

  .ps-xxl-15 {
    padding-left: 13rem !important;
  }

  .m-xxl-16 {
    margin: 13.5rem !important;
  }

  .mx-xxl-16 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important;
  }

  .my-xxl-16 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important;
  }

  .mt-xxl-16 {
    margin-top: 13.5rem !important;
  }

  .me-xxl-16 {
    margin-right: 13.5rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 13.5rem !important;
  }

  .ms-xxl-16 {
    margin-left: 13.5rem !important;
  }

  .m-xxl-n16 {
    margin: -13.5rem !important;
  }

  .mx-xxl-n16 {
    margin-right: -13.5rem !important;
    margin-left: -13.5rem !important;
  }

  .my-xxl-n16 {
    margin-top: -13.5rem !important;
    margin-bottom: -13.5rem !important;
  }

  .mt-xxl-n16 {
    margin-top: -13.5rem !important;
  }

  .me-xxl-n16 {
    margin-right: -13.5rem !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -13.5rem !important;
  }

  .ms-xxl-n16 {
    margin-left: -13.5rem !important;
  }

  .p-xxl-16 {
    padding: 13.5rem !important;
  }

  .px-xxl-16 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important;
  }

  .py-xxl-16 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important;
  }

  .pt-xxl-16 {
    padding-top: 13.5rem !important;
  }

  .pe-xxl-16 {
    padding-right: 13.5rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 13.5rem !important;
  }

  .ps-xxl-16 {
    padding-left: 13.5rem !important;
  }

  .m-xxl-17 {
    margin: 14rem !important;
  }

  .mx-xxl-17 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .my-xxl-17 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .mt-xxl-17 {
    margin-top: 14rem !important;
  }

  .me-xxl-17 {
    margin-right: 14rem !important;
  }

  .mb-xxl-17 {
    margin-bottom: 14rem !important;
  }

  .ms-xxl-17 {
    margin-left: 14rem !important;
  }

  .m-xxl-n17 {
    margin: -14rem !important;
  }

  .mx-xxl-n17 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .my-xxl-n17 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .mt-xxl-n17 {
    margin-top: -14rem !important;
  }

  .me-xxl-n17 {
    margin-right: -14rem !important;
  }

  .mb-xxl-n17 {
    margin-bottom: -14rem !important;
  }

  .ms-xxl-n17 {
    margin-left: -14rem !important;
  }

  .p-xxl-17 {
    padding: 14rem !important;
  }

  .px-xxl-17 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .py-xxl-17 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .pt-xxl-17 {
    padding-top: 14rem !important;
  }

  .pe-xxl-17 {
    padding-right: 14rem !important;
  }

  .pb-xxl-17 {
    padding-bottom: 14rem !important;
  }

  .ps-xxl-17 {
    padding-left: 14rem !important;
  }

  .m-xxl-18 {
    margin: 14.5rem !important;
  }

  .mx-xxl-18 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important;
  }

  .my-xxl-18 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important;
  }

  .mt-xxl-18 {
    margin-top: 14.5rem !important;
  }

  .me-xxl-18 {
    margin-right: 14.5rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 14.5rem !important;
  }

  .ms-xxl-18 {
    margin-left: 14.5rem !important;
  }

  .m-xxl-n18 {
    margin: -14.5rem !important;
  }

  .mx-xxl-n18 {
    margin-right: -14.5rem !important;
    margin-left: -14.5rem !important;
  }

  .my-xxl-n18 {
    margin-top: -14.5rem !important;
    margin-bottom: -14.5rem !important;
  }

  .mt-xxl-n18 {
    margin-top: -14.5rem !important;
  }

  .me-xxl-n18 {
    margin-right: -14.5rem !important;
  }

  .mb-xxl-n18 {
    margin-bottom: -14.5rem !important;
  }

  .ms-xxl-n18 {
    margin-left: -14.5rem !important;
  }

  .p-xxl-18 {
    padding: 14.5rem !important;
  }

  .px-xxl-18 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important;
  }

  .py-xxl-18 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important;
  }

  .pt-xxl-18 {
    padding-top: 14.5rem !important;
  }

  .pe-xxl-18 {
    padding-right: 14.5rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 14.5rem !important;
  }

  .ps-xxl-18 {
    padding-left: 14.5rem !important;
  }

  .m-xxl-19 {
    margin: 15rem !important;
  }

  .mx-xxl-19 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .my-xxl-19 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .mt-xxl-19 {
    margin-top: 15rem !important;
  }

  .me-xxl-19 {
    margin-right: 15rem !important;
  }

  .mb-xxl-19 {
    margin-bottom: 15rem !important;
  }

  .ms-xxl-19 {
    margin-left: 15rem !important;
  }

  .m-xxl-n19 {
    margin: -15rem !important;
  }

  .mx-xxl-n19 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }

  .my-xxl-n19 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .mt-xxl-n19 {
    margin-top: -15rem !important;
  }

  .me-xxl-n19 {
    margin-right: -15rem !important;
  }

  .mb-xxl-n19 {
    margin-bottom: -15rem !important;
  }

  .ms-xxl-n19 {
    margin-left: -15rem !important;
  }

  .p-xxl-19 {
    padding: 15rem !important;
  }

  .px-xxl-19 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .py-xxl-19 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .pt-xxl-19 {
    padding-top: 15rem !important;
  }

  .pe-xxl-19 {
    padding-right: 15rem !important;
  }

  .pb-xxl-19 {
    padding-bottom: 15rem !important;
  }

  .ps-xxl-19 {
    padding-left: 15rem !important;
  }

  .m-xxl-20 {
    margin: 15.5rem !important;
  }

  .mx-xxl-20 {
    margin-right: 15.5rem !important;
    margin-left: 15.5rem !important;
  }

  .my-xxl-20 {
    margin-top: 15.5rem !important;
    margin-bottom: 15.5rem !important;
  }

  .mt-xxl-20 {
    margin-top: 15.5rem !important;
  }

  .me-xxl-20 {
    margin-right: 15.5rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 15.5rem !important;
  }

  .ms-xxl-20 {
    margin-left: 15.5rem !important;
  }

  .m-xxl-n20 {
    margin: -15.5rem !important;
  }

  .mx-xxl-n20 {
    margin-right: -15.5rem !important;
    margin-left: -15.5rem !important;
  }

  .my-xxl-n20 {
    margin-top: -15.5rem !important;
    margin-bottom: -15.5rem !important;
  }

  .mt-xxl-n20 {
    margin-top: -15.5rem !important;
  }

  .me-xxl-n20 {
    margin-right: -15.5rem !important;
  }

  .mb-xxl-n20 {
    margin-bottom: -15.5rem !important;
  }

  .ms-xxl-n20 {
    margin-left: -15.5rem !important;
  }

  .p-xxl-20 {
    padding: 15.5rem !important;
  }

  .px-xxl-20 {
    padding-right: 15.5rem !important;
    padding-left: 15.5rem !important;
  }

  .py-xxl-20 {
    padding-top: 15.5rem !important;
    padding-bottom: 15.5rem !important;
  }

  .pt-xxl-20 {
    padding-top: 15.5rem !important;
  }

  .pe-xxl-20 {
    padding-right: 15.5rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 15.5rem !important;
  }

  .ps-xxl-20 {
    padding-left: 15.5rem !important;
  }

  .m-xxl-21 {
    margin: 18.5rem !important;
  }

  .mx-xxl-21 {
    margin-right: 18.5rem !important;
    margin-left: 18.5rem !important;
  }

  .my-xxl-21 {
    margin-top: 18.5rem !important;
    margin-bottom: 18.5rem !important;
  }

  .mt-xxl-21 {
    margin-top: 18.5rem !important;
  }

  .me-xxl-21 {
    margin-right: 18.5rem !important;
  }

  .mb-xxl-21 {
    margin-bottom: 18.5rem !important;
  }

  .ms-xxl-21 {
    margin-left: 18.5rem !important;
  }

  .m-xxl-n21 {
    margin: -18.5rem !important;
  }

  .mx-xxl-n21 {
    margin-right: -18.5rem !important;
    margin-left: -18.5rem !important;
  }

  .my-xxl-n21 {
    margin-top: -18.5rem !important;
    margin-bottom: -18.5rem !important;
  }

  .mt-xxl-n21 {
    margin-top: -18.5rem !important;
  }

  .me-xxl-n21 {
    margin-right: -18.5rem !important;
  }

  .mb-xxl-n21 {
    margin-bottom: -18.5rem !important;
  }

  .ms-xxl-n21 {
    margin-left: -18.5rem !important;
  }

  .p-xxl-21 {
    padding: 18.5rem !important;
  }

  .px-xxl-21 {
    padding-right: 18.5rem !important;
    padding-left: 18.5rem !important;
  }

  .py-xxl-21 {
    padding-top: 18.5rem !important;
    padding-bottom: 18.5rem !important;
  }

  .pt-xxl-21 {
    padding-top: 18.5rem !important;
  }

  .pe-xxl-21 {
    padding-right: 18.5rem !important;
  }

  .pb-xxl-21 {
    padding-bottom: 18.5rem !important;
  }

  .ps-xxl-21 {
    padding-left: 18.5rem !important;
  }

  .m-xxl-22 {
    margin: 3.75rem !important;
  }

  .mx-xxl-22 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .my-xxl-22 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .mt-xxl-22 {
    margin-top: 3.75rem !important;
  }

  .me-xxl-22 {
    margin-right: 3.75rem !important;
  }

  .mb-xxl-22 {
    margin-bottom: 3.75rem !important;
  }

  .ms-xxl-22 {
    margin-left: 3.75rem !important;
  }

  .m-xxl-n22 {
    margin: -3.75rem !important;
  }

  .mx-xxl-n22 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .my-xxl-n22 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .mt-xxl-n22 {
    margin-top: -3.75rem !important;
  }

  .me-xxl-n22 {
    margin-right: -3.75rem !important;
  }

  .mb-xxl-n22 {
    margin-bottom: -3.75rem !important;
  }

  .ms-xxl-n22 {
    margin-left: -3.75rem !important;
  }

  .p-xxl-22 {
    padding: 3.75rem !important;
  }

  .px-xxl-22 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .py-xxl-22 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .pt-xxl-22 {
    padding-top: 3.75rem !important;
  }

  .pe-xxl-22 {
    padding-right: 3.75rem !important;
  }

  .pb-xxl-22 {
    padding-bottom: 3.75rem !important;
  }

  .ps-xxl-22 {
    padding-left: 3.75rem !important;
  }
}
/********** 
5. Navbar Styles 
**********/
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.9rem;
  padding-right: 1.25rem;
  padding-bottom: 1.9rem;
  padding-left: 1.25rem;
  background-color: transparent;
  z-index: 1000;
  transition: all 0.3s cubic-bezier(0.36, 0.03, 0, 0.91);
}

@media (max-width: 991.98px) {
  .navbar {
    margin: 1rem;
    padding: 1.5rem 0;
    border-radius: 0.5rem;
    width: calc(100% - 32px) !important;
  }
}
@media (max-width: 575.98px) {
  .navbar {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  white-space: nowrap;
  line-height: 1.5;
}

.navbar-brand-two .white-logo {
  display: block;
}
.navbar-brand-two .logo {
  display: none;
}

@media (max-width: 575.98px) {
  .navbar-brand {
    flex: 1;
  }
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: center;
  align-items: center;
  /* Dropdown Menu for large screen */
  /* Dropdown Menu for Small screen */
}
.navbar-nav .nav-link {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
  display: flex;
  align-items: center;
}
.navbar-nav .nav-link.active {
  color: #060A11;
  font-weight: bold;
}
.navbar-nav .nav-link:hover {
  color: #060A11;
}
.navbar-nav .dropdown .dropdown-menu {
  box-sizing: content-box;
  background: #fff;
  min-width: 188px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #e8e8e8;
  border-radius: 0.5rem;
  padding: 30px 30px 40px;
  left: -70%;
}
.navbar-nav .dropdown-toggle:after {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  border-top: none;
  padding: 0;
  margin-right: 0 !important;
}
.navbar-nav .dropdown li.dropdown-megamenu-column-items {
  margin-bottom: 1.4rem;
}
.navbar-nav .dropdown li.dropdown-megamenu-column-items:last-child {
  margin-bottom: 0;
}
.navbar-nav .dropdown-item {
  margin-bottom: 0;
  color: #060A11;
  text-align: left;
  padding: 0;
}
.navbar-nav .dropdown .nav-arrow {
  position: absolute;
  top: -10px;
  right: 15px;
}
@media (min-width: 992px) {
  .navbar-nav .dropdown .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: scaleY(0);
    top: 100%;
    transform-origin: 0 0 0;
  }
  .navbar-nav .dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    transform-origin: 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .navbar-nav {
    margin-top: 1.5rem !important;
  }
  .navbar-nav .nav-link {
    margin-bottom: 0.3rem;
    position: relative;
    display: flex;
    justify-content: center;
    color: #060A11;
    font-weight: 800;
    font-family: "Manrope", sans-serif;
  }
  .navbar-nav .dropdown {
    width: calc(100% + 28px);
  }
  .navbar-nav .dropdown .dropdown-menu {
    display: none;
    height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0);
    top: 100%;
    transform-origin: 0 0 0;
    left: 0;
    transition: all 0.3s ease-in-out;
  }
  .navbar-nav .dropdown .dropdown-menu.show {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    transform-origin: 0 0 0;
    height: auto;
    text-align: center;
    background: #f3f3f3;
    width: auto;
    border-radius: 0;
  }
  .navbar-nav .dropdown-toggle:after {
    font-size: 1rem;
    position: relative;
    transform: translateY(-5%);
    padding: 0;
    margin: 0;
  }
  .navbar-nav .dropdown-item {
    text-align: center;
  }
}

.navbar-two .nav-link {
  color: #dfdfdf;
}
.navbar-two .nav-link:hover {
  color: #fff;
}
@media (max-width: 991.98px) {
  .navbar-two .nav-link:hover {
    color: #060A11;
  }
}
.navbar-two .btn-links {
  color: #fff;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid transparent;
}
.navbar-two .btn-links span {
  color: #fff;
}
.navbar-two .btn-links span:nth-child(1) svg path {
  stroke: #fff;
}
.navbar-two .btn-links:hover {
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.navbar-two .btn-links:hover span {
  color: #fff;
}
.navbar-two .btn-links:hover i {
  color: #fff;
}

.sticky-nav {
  padding: 1rem 2rem !important;
  background: #fff;
  box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.04);
}
.sticky-nav .btn-gray-dark {
  background: #fff;
}
.sticky-nav .navbar-brand-two .white-logo {
  display: none;
}
.sticky-nav .navbar-brand-two .logo {
  display: block;
}
.sticky-nav .navbar-two .nav-link {
  color: #060A11;
}
.sticky-nav .navbar-two .btn-links {
  background: rgba(7, 12, 19, 0.04);
  color: #060A11;
  border: 1px solid transparent;
}
.sticky-nav .navbar-two .btn-links span {
  color: #060A11;
}
.sticky-nav .navbar-two .btn-links span:nth-child(1) svg path {
  stroke: #060A11;
}

@media (max-width: 991.98px) {
  .navbar {
    background: #fff;
  }

  .navbar-brand-two .white-logo {
    display: none;
  }
  .navbar-brand-two .logo {
    display: block;
  }

  .navbar-two .nav-link {
    color: #404152;
  }
  .navbar-two .btn-links {
    background: rgba(7, 12, 19, 0.04);
    border: 1px solid transparent;
  }
  .navbar-two .btn-links span {
    color: #060A11;
  }

  .sticky-nav .navbar-nav .dropdown {
    width: calc(100% + 92px);
  }
}
@media (max-width: 575.98px) {
  .sticky-nav {
    padding: 1rem !important;
  }
  .sticky-nav .navbar-nav .dropdown {
    width: calc(100% + 60px);
  }
}
@media (max-width: 991.98px) {
  .navbar {
    background: #fff;
  }
}
.navbar-toggler {
  background-color: #fff;
  border: 1px solid #aaaab7;
}

.navbar-toggler[aria-expanded=true] .navbar-toggler-default {
  display: none;
}

.navbar-toggler .navbar-toggler-default {
  display: -ms-flexbox;
  display: flex;
}

.navbar-toggler[aria-expanded=true] .navbar-toggler-toggled {
  display: -ms-flexbox;
  display: flex;
}

.navbar-toggler .navbar-toggler-toggled {
  display: none;
}

.dropdown-toggle::after {
  margin-left: 0;
}

/********** 
 6. Hero Styles 
**********/
.hero {
  position: relative;
  background: linear-gradient(239.24deg, #f4f7fe 22.34%, #fbfcff 83.1%);
}
@media (max-width: 767.98px) {
  .hero {
    overflow: hidden;
  }
}
.hero-shape-wrapper {
  overflow: hidden;
  bottom: 0%;
}
.hero-shape-wrapper .shape-1,
.hero-shape-wrapper .shape-2,
.hero-shape-wrapper .shape-3,
.hero-shape-wrapper .shape-4 {
  position: absolute;
  border-radius: 50%;
  top: -40%;
  width: 686px;
  height: 686px;
  right: 15%;
  mix-blend-mode: multiply;
  background: #fffbda;
  filter: blur(60px);
}
@media (max-width: 1399.98px) {
  .hero-shape-wrapper .shape-1,
.hero-shape-wrapper .shape-2,
.hero-shape-wrapper .shape-3,
.hero-shape-wrapper .shape-4 {
    right: -5%;
  }
}
@media (max-width: 991.98px) {
  .hero-shape-wrapper .shape-1,
.hero-shape-wrapper .shape-2,
.hero-shape-wrapper .shape-3,
.hero-shape-wrapper .shape-4 {
    right: 10%;
    top: -30%;
  }
}
@media (max-width: 767.98px) {
  .hero-shape-wrapper .shape-1,
.hero-shape-wrapper .shape-2,
.hero-shape-wrapper .shape-3,
.hero-shape-wrapper .shape-4 {
    right: -18%;
    top: 30%;
  }
}
.hero-shape-wrapper .shape-2 {
  top: 31%;
  left: 50%;
  width: 418px;
  height: 418px;
  background: #85fff8;
  opacity: 0.7;
}
@media (max-width: 1399.98px) {
  .hero-shape-wrapper .shape-2 {
    left: 45%;
  }
}
@media (max-width: 991.98px) {
  .hero-shape-wrapper .shape-2 {
    left: 10%;
    top: 60%;
  }
}
@media (max-width: 767.98px) {
  .hero-shape-wrapper .shape-2 {
    left: 5%;
  }
}
@media (max-width: 575.98px) {
  .hero-shape-wrapper .shape-2 {
    left: 0%;
  }
}
.hero-shape-wrapper .shape-3 {
  top: 0%;
  right: -10%;
  width: 418px;
  height: 418px;
  background: #cdf6ff;
}
@media (max-width: 991.98px) {
  .hero-shape-wrapper .shape-3 {
    right: -20%;
  }
}
@media (max-width: 767.98px) {
  .hero-shape-wrapper .shape-3 {
    right: -30%;
  }
}
.hero-shape-wrapper .shape-4 {
  top: -30%;
  left: -10%;
  opacity: 0.5;
  background: #b9ffe2;
}
@media (max-width: 1399.98px) {
  .hero-shape-wrapper .shape-4 {
    top: -42%;
    left: -25%;
  }
}
@media (max-width: 991.98px) {
  .hero-shape-wrapper .shape-4 {
    top: -27%;
    left: -50%;
  }
}
@media (max-width: 767.98px) {
  .hero-shape-wrapper .shape-4 {
    top: -30%;
    left: -63%;
  }
}
.hero-banner-wraper {
  bottom: 0%;
}
.hero-banner-wraper .shape {
  position: absolute;
  border-radius: 50%;
  top: 20%;
  right: 15%;
  width: 629px;
  height: 630px;
  background: #9785ff;
  filter: blur(60px);
  mix-blend-mode: multiply;
  opacity: 0.8;
}
@media (max-width: 1399.98px) {
  .hero-banner-wraper .shape {
    right: 5%;
    top: 15%;
  }
}
@media (max-width: 1199.98px) {
  .hero-banner-wraper .shape {
    right: 0%;
    top: 10%;
  }
}
@media (max-width: 991.98px) {
  .hero-banner-wraper .shape {
    top: 50%;
    right: 7%;
  }
}
@media (max-width: 767.98px) {
  .hero-banner-wraper .shape {
    right: 0%;
  }
}
@media (max-width: 575.98px) {
  .hero-banner-wraper .shape {
    right: -50%;
  }
}
@media (max-width: 1199.98px) {
  .hero-content h1 {
    font-size: 3rem;
  }
}
@media (max-width: 575.98px) {
  .hero-content h1 {
    font-size: 2rem;
  }
}
.hero-content p {
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.7;
}
.hero-banner-image {
  position: relative;
}
.hero-banner-image img {
  width: 100%;
}
.hero-two {
  background-color: #060a11;
}
.hero-two-shape-wrapper {
  overflow: hidden;
  bottom: 0%;
}
.hero-two-shape-wrapper .shape-1,
.hero-two-shape-wrapper .shape-2,
.hero-two-shape-wrapper .shape-3 {
  position: absolute;
  border-radius: 50%;
  top: -30%;
  width: 760px;
  height: 760px;
  left: -15%;
  background: #0047b0;
  opacity: 0.7;
  filter: blur(60px);
}
@media (max-width: 1399.98px) {
  .hero-two-shape-wrapper .shape-1,
.hero-two-shape-wrapper .shape-2,
.hero-two-shape-wrapper .shape-3 {
    left: -30%;
    top: -45%;
  }
}
@media (max-width: 991.98px) {
  .hero-two-shape-wrapper .shape-1,
.hero-two-shape-wrapper .shape-2,
.hero-two-shape-wrapper .shape-3 {
    left: -40%;
    top: -50%;
  }
}
.hero-two-shape-wrapper .shape-2 {
  top: -10%;
  left: 80%;
  width: 686px;
  height: 686px;
  background: #00b09b;
  mix-blend-mode: screen;
  opacity: 0.5;
}
@media (max-width: 991.98px) {
  .hero-two-shape-wrapper .shape-2 {
    left: 70%;
  }
}
.hero-two-shape-wrapper .shape-3 {
  top: 15%;
  left: 70%;
  width: 615px;
  height: 615px;
  background: #6300b0;
  opacity: 0.5;
}
.hero-two-content .banner {
  margin-bottom: 2rem;
  position: relative;
}
.hero-two-content .banner img {
  max-width: 100%;
  border-radius: 100px;
}
.hero-two-content .banner::before {
  position: absolute;
  content: "";
  width: 186px;
  height: 186px;
  background: #f1b000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -36%);
  z-index: -1;
  border-radius: 50%;
}
.hero-two-content h1 {
  color: #fff;
}
@media (max-width: 575.98px) {
  .hero-two-content h1 {
    font-size: 2rem;
  }
}
.hero-two-content p {
  color: #dfdfdf;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.7;
}
.hero-three {
  background: linear-gradient(239.24deg, #f4f7fe 22.34%, #fbfcff 83.1%);
}
.hero-three-shape-wrapper {
  overflow: hidden;
  bottom: 0%;
}
.hero-three-shape-wrapper .shape-1,
.hero-three-shape-wrapper .shape-2,
.hero-three-shape-wrapper .shape-3 {
  position: absolute;
  border-radius: 50%;
  top: -60%;
  width: 686px;
  height: 686px;
  left: 50%;
  background: #fffbda;
  mix-blend-mode: multiply;
  filter: blur(60px);
}
@media (max-width: 1199.98px) {
  .hero-three-shape-wrapper .shape-1,
.hero-three-shape-wrapper .shape-2,
.hero-three-shape-wrapper .shape-3 {
    left: 40%;
  }
}
@media (max-width: 991.98px) {
  .hero-three-shape-wrapper .shape-1,
.hero-three-shape-wrapper .shape-2,
.hero-three-shape-wrapper .shape-3 {
    top: -30%;
    left: 15%;
  }
}
@media (max-width: 767.98px) {
  .hero-three-shape-wrapper .shape-1,
.hero-three-shape-wrapper .shape-2,
.hero-three-shape-wrapper .shape-3 {
    left: 5%;
  }
}
.hero-three-shape-wrapper .shape-2 {
  top: -20%;
  left: 85%;
  background: #cdf6ff;
}
@media (max-width: 991.98px) {
  .hero-three-shape-wrapper .shape-2 {
    top: -20%;
    left: 70%;
  }
}
.hero-three-shape-wrapper .shape-3 {
  top: -35%;
  left: -10%;
  opacity: 0.5;
  background: #b9ffe2;
}
@media (max-width: 1399.98px) {
  .hero-three-shape-wrapper .shape-3 {
    top: -45%;
    left: -27%;
  }
}
@media (max-width: 991.98px) {
  .hero-three-shape-wrapper .shape-3 {
    top: -20%;
    left: -50%;
  }
}

/********** 
 7. Services Page Styles
**********/
.services,
.services-two {
  position: relative;
  padding-top: 5.5rem;
}
.services-shape-wrapper,
.services-two-shape-wrapper {
  bottom: 0%;
}
@media (max-width: 1399.98px) {
  .services-shape-wrapper,
.services-two-shape-wrapper {
    overflow: hidden;
  }
}
.services-shape-wrapper .shape-1,
.services-shape-wrapper .shape-2,
.services-two-shape-wrapper .shape-1,
.services-two-shape-wrapper .shape-2 {
  position: absolute;
  border-radius: 50%;
  width: 686px;
  height: 686px;
  top: 30%;
  mix-blend-mode: multiply;
  opacity: 0.2;
  filter: blur(60px);
}
.services-shape-wrapper .shape-1,
.services-two-shape-wrapper .shape-1 {
  left: 0%;
  background: #d5ffc6;
}
@media (max-width: 1399.98px) {
  .services-shape-wrapper .shape-1,
.services-two-shape-wrapper .shape-1 {
    left: -15%;
  }
}
.services-shape-wrapper .shape-2,
.services-two-shape-wrapper .shape-2 {
  right: 0%;
  top: 0;
  background: #b6fffb;
}
@media (max-width: 1399.98px) {
  .services-shape-wrapper .shape-2,
.services-two-shape-wrapper .shape-2 {
    right: -15%;
  }
}
@media (max-width: 1399.98px) {
  .services,
.services-two {
    padding-top: 2.5rem;
  }
}
@media (max-width: 1199.98px) {
  .services,
.services-two {
    padding-top: 0;
  }
}
@media (max-width: 991.98px) {
  .services,
.services-two {
    padding: 0;
  }
}
.services .card,
.services-two .card {
  transition: all 0.3s ease-in-out;
  text-align: center;
  padding: 3.75rem 1.25rem 3.2rem;
  border-radius: 5px;
  box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.04);
  background: #fff;
  z-index: 100;
  margin-top: 0.75rem;
}
.services .card-icon,
.services-two .card-icon {
  margin-bottom: 2.5rem;
}
.services .card-body,
.services-two .card-body {
  padding: 0;
}
.services .card-body .card-title,
.services-two .card-body .card-title {
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  margin-bottom: 2rem;
}
.services .card-body h4,
.services-two .card-body h4 {
  color: #404152;
}
@media (max-width: 1199.98px) {
  .services .card,
.services-two .card {
    padding: 2rem 1rem 3.2rem;
  }
}
@media (max-width: 991.98px) {
  .services .card,
.services-two .card {
    margin-bottom: 1rem;
    padding: 3.75rem 1.25rem 3.2rem;
  }
}
@media (max-width: 767.98px) {
  .services .card,
.services-two .card {
    padding: 1.5rem 1rem 2rem;
  }
}
.services-two .card,
.services-two-two .card {
  margin-bottom: 2rem;
}
@media (max-width: 991.98px) {
  .services-two .card,
.services-two-two .card {
    margin-bottom: 1rem;
  }
}

/********** 
8. Resume Section Styles
**********/
.resume,
.resume-two {
  position: relative;
  z-index: 2;
  padding-bottom: 8rem;
}
.resume-shape-wrapper,
.resume-two-shape-wrapper {
  bottom: 0%;
  overflow: hidden;
}
.resume-shape-wrapper .shape-1,
.resume-shape-wrapper .shape-2,
.resume-shape-wrapper .shape-3,
.resume-shape-wrapper .shape-4,
.resume-shape-wrapper .shape-5,
.resume-two-shape-wrapper .shape-1,
.resume-two-shape-wrapper .shape-2,
.resume-two-shape-wrapper .shape-3,
.resume-two-shape-wrapper .shape-4,
.resume-two-shape-wrapper .shape-5 {
  position: absolute;
  border-radius: 50%;
  width: 660px;
  height: 660px;
  left: 52%;
  top: 50%;
  background: #c4dbfe;
  mix-blend-mode: multiply;
  opacity: 0.65;
  filter: blur(60px);
}
@media (max-width: 991.98px) {
  .resume-shape-wrapper .shape-1,
.resume-shape-wrapper .shape-2,
.resume-shape-wrapper .shape-3,
.resume-shape-wrapper .shape-4,
.resume-shape-wrapper .shape-5,
.resume-two-shape-wrapper .shape-1,
.resume-two-shape-wrapper .shape-2,
.resume-two-shape-wrapper .shape-3,
.resume-two-shape-wrapper .shape-4,
.resume-two-shape-wrapper .shape-5 {
    left: 30%;
  }
}
@media (max-width: 767.98px) {
  .resume-shape-wrapper .shape-1,
.resume-shape-wrapper .shape-2,
.resume-shape-wrapper .shape-3,
.resume-shape-wrapper .shape-4,
.resume-shape-wrapper .shape-5,
.resume-two-shape-wrapper .shape-1,
.resume-two-shape-wrapper .shape-2,
.resume-two-shape-wrapper .shape-3,
.resume-two-shape-wrapper .shape-4,
.resume-two-shape-wrapper .shape-5 {
    left: 0%;
  }
}
.resume-shape-wrapper .shape-2,
.resume-two-shape-wrapper .shape-2 {
  left: 13%;
  background: #c4dbfe;
}
@media (max-width: 1399.98px) {
  .resume-shape-wrapper .shape-2,
.resume-two-shape-wrapper .shape-2 {
    left: 0%;
    top: 52%;
  }
}
@media (max-width: 991.98px) {
  .resume-shape-wrapper .shape-2,
.resume-two-shape-wrapper .shape-2 {
    left: 6%;
  }
}
@media (max-width: 767.98px) {
  .resume-shape-wrapper .shape-2,
.resume-two-shape-wrapper .shape-2 {
    left: 0%;
  }
}
.resume-shape-wrapper .shape-3,
.resume-two-shape-wrapper .shape-3 {
  top: 7%;
  left: 34%;
  opacity: 0.8;
  background: #d8ff85;
}
@media (max-width: 1399.98px) {
  .resume-shape-wrapper .shape-3,
.resume-two-shape-wrapper .shape-3 {
    left: 25%;
    top: 3%;
  }
}
@media (max-width: 991.98px) {
  .resume-shape-wrapper .shape-3,
.resume-two-shape-wrapper .shape-3 {
    left: 10%;
  }
}
@media (max-width: 767.98px) {
  .resume-shape-wrapper .shape-3,
.resume-two-shape-wrapper .shape-3 {
    left: 0%;
    top: 20%;
  }
}
.resume-shape-wrapper .shape-4,
.resume-two-shape-wrapper .shape-4 {
  top: 12%;
  left: 18%;
  opacity: 0.3;
  background: #ffc700;
}
@media (max-width: 1399.98px) {
  .resume-shape-wrapper .shape-4,
.resume-two-shape-wrapper .shape-4 {
    left: 5%;
    top: 6%;
  }
}
@media (max-width: 991.98px) {
  .resume-shape-wrapper .shape-4,
.resume-two-shape-wrapper .shape-4 {
    left: 0%;
  }
}
@media (max-width: 767.98px) {
  .resume-shape-wrapper .shape-4,
.resume-two-shape-wrapper .shape-4 {
    top: 20%;
  }
}
.resume-shape-wrapper .shape-5,
.resume-two-shape-wrapper .shape-5 {
  top: 12%;
  left: 48%;
  opacity: 0.3;
  background: #ffc700;
}
@media (max-width: 1399.98px) {
  .resume-shape-wrapper .shape-5,
.resume-two-shape-wrapper .shape-5 {
    top: 8%;
    left: 40%;
  }
}
@media (max-width: 991.98px) {
  .resume-shape-wrapper .shape-5,
.resume-two-shape-wrapper .shape-5 {
    left: 15%;
  }
}
@media (max-width: 767.98px) {
  .resume-shape-wrapper .shape-5,
.resume-two-shape-wrapper .shape-5 {
    left: 0%;
  }
}
.resume .section-header,
.resume-two .section-header {
  padding: 0;
}
.resume .section-header h2,
.resume-two .section-header h2 {
  font-size: 2.5rem;
  background: linear-gradient(to right, #4c32e0 0%, #16b4f2 10%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.75rem;
}
@media (max-width: 991.98px) {
  .resume .section-header h2,
.resume-two .section-header h2 {
    background: linear-gradient(to right, #4c32e0 30%, #16b4f2 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
  }
}
.resume .card,
.resume-two .card {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  box-shadow: none;
  padding: 6.75rem 5rem 0rem;
}
@media (max-width: 1399.98px) {
  .resume .card,
.resume-two .card {
    padding: 3.75rem 5rem 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .resume .card,
.resume-two .card {
    padding: 2rem;
    text-align: center;
  }
}
.resume .card-body,
.resume-two .card-body {
  padding: 3rem 0 0 0;
}
@media (max-width: 767.98px) {
  .resume .card-body,
.resume-two .card-body {
    padding-top: 1.5rem;
  }
}
.resume .card-body .title,
.resume-two .card-body .title {
  padding-bottom: 2rem;
  margin-bottom: 3.75rem;
  border-bottom: 1px solid #e8e8e8;
}
@media (max-width: 991.98px) {
  .resume .card-body .title,
.resume-two .card-body .title {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
}
.resume .card-body .title h5,
.resume-two .card-body .title h5 {
  color: #aaaab7;
  font-weight: 500;
}
.resume .card-body .experiencEducation,
.resume .card-body .skillsTools,
.resume-two .card-body .experiencEducation,
.resume-two .card-body .skillsTools {
  display: flex;
}
@media (max-width: 767.98px) {
  .resume .card-body .experiencEducation,
.resume .card-body .skillsTools,
.resume-two .card-body .experiencEducation,
.resume-two .card-body .skillsTools {
    display: block;
  }
}
.resume .card-body .content,
.resume-two .card-body .content {
  margin-bottom: 5.5rem;
}
@media (max-width: 1399.98px) {
  .resume .card-body .content,
.resume-two .card-body .content {
    margin-bottom: 2.5rem;
  }
}
.resume .card-body .content h4,
.resume-two .card-body .content h4 {
  margin-bottom: 1rem;
}
.resume .card-body .content p,
.resume-two .card-body .content p {
  font-size: 18px;
  font-weight: 500;
  color: #404152;
  margin-bottom: 0;
}
.resume .card-body .content li,
.resume-two .card-body .content li {
  font-size: 20px;
  font-weight: 800;
  color: #404152;
  margin-bottom: 2rem;
  transition: all 0.3s ease-in-out;
  font-family: "Manrope", sans-serif;
}
.resume .card-body .content li:last-child,
.resume-two .card-body .content li:last-child {
  margin-bottom: 0;
}
.resume .card-body .experience,
.resume .card-body .skills,
.resume-two .card-body .experience,
.resume-two .card-body .skills {
  width: 60%;
}
@media (max-width: 1199.98px) {
  .resume .card-body .experience,
.resume .card-body .skills,
.resume-two .card-body .experience,
.resume-two .card-body .skills {
    width: 50%;
  }
}
@media (max-width: 991.98px) {
  .resume .card-body .experience,
.resume .card-body .skills,
.resume-two .card-body .experience,
.resume-two .card-body .skills {
    width: 100%;
  }
}
.resume .card-body .education,
.resume .card-body .tools,
.resume-two .card-body .education,
.resume-two .card-body .tools {
  width: 40%;
}
@media (max-width: 1199.98px) {
  .resume .card-body .education,
.resume .card-body .tools,
.resume-two .card-body .education,
.resume-two .card-body .tools {
    width: 50%;
  }
}
@media (max-width: 991.98px) {
  .resume .card-body .education,
.resume .card-body .tools,
.resume-two .card-body .education,
.resume-two .card-body .tools {
    width: 100%;
  }
}
.resume .card-body .education-wrapper,
.resume .card-body .tools-wrapper,
.resume-two .card-body .education-wrapper,
.resume-two .card-body .tools-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 40px;
}
@media (max-width: 991.98px) {
  .resume .card-body .education-wrapper,
.resume .card-body .tools-wrapper,
.resume-two .card-body .education-wrapper,
.resume-two .card-body .tools-wrapper {
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
}
.resume .card-body .freelance,
.resume-two .card-body .freelance {
  margin-bottom: 5rem;
}
@media (max-width: 767.98px) {
  .resume .card-body .freelance,
.resume-two .card-body .freelance {
    margin-bottom: 3rem;
  }
}
.resume .card-body .freelance h5,
.resume-two .card-body .freelance h5 {
  font-size: 18px;
}
.resume .card-body .freelance-wrapper,
.resume-two .card-body .freelance-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 50px;
}
@media (max-width: 767.98px) {
  .resume .card-body .freelance-wrapper,
.resume-two .card-body .freelance-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}
.resume-two,
.resume-two-two {
  position: relative;
  background: #4c32e0;
  padding-bottom: 180px;
}
@media (max-width: 1399.98px) {
  .resume-two,
.resume-two-two {
    padding-bottom: 100px;
  }
}
@media (max-width: 1199.98px) {
  .resume-two,
.resume-two-two {
    padding-bottom: 50px;
  }
}
.resume-two-shape-wrapper,
.resume-two-two-shape-wrapper {
  overflow: hidden;
  bottom: 0%;
}
.resume-two-shape-wrapper .shape-1,
.resume-two-shape-wrapper .shape-2,
.resume-two-shape-wrapper .shape-3,
.resume-two-shape-wrapper .shape-4,
.resume-two-two-shape-wrapper .shape-1,
.resume-two-two-shape-wrapper .shape-2,
.resume-two-two-shape-wrapper .shape-3,
.resume-two-two-shape-wrapper .shape-4 {
  position: absolute;
  border-radius: 50%;
  width: 906px;
  height: 910px;
  left: -30%;
  top: -30%;
  background: #88a2ff;
  mix-blend-mode: screen;
  filter: blur(60px);
}
@media (max-width: 1399.98px) {
  .resume-two-shape-wrapper .shape-1,
.resume-two-shape-wrapper .shape-2,
.resume-two-shape-wrapper .shape-3,
.resume-two-shape-wrapper .shape-4,
.resume-two-two-shape-wrapper .shape-1,
.resume-two-two-shape-wrapper .shape-2,
.resume-two-two-shape-wrapper .shape-3,
.resume-two-two-shape-wrapper .shape-4 {
    left: -42%;
  }
}
.resume-two-shape-wrapper .shape-2,
.resume-two-two-shape-wrapper .shape-2 {
  width: 1823px;
  height: 1832px;
  top: -30%;
  left: -27%;
  mix-blend-mode: multiply;
  background: #0026ad;
  opacity: 0.4;
}
@media (max-width: 1399.98px) {
  .resume-two-shape-wrapper .shape-2,
.resume-two-two-shape-wrapper .shape-2 {
    left: -100%;
  }
}
.resume-two-shape-wrapper .shape-3,
.resume-two-two-shape-wrapper .shape-3 {
  width: 1357px;
  height: 1364px;
  top: -15%;
  left: 64%;
  mix-blend-mode: normal;
  background: #1e75f8;
  opacity: 0.3;
}
.resume-two-shape-wrapper .shape-4,
.resume-two-two-shape-wrapper .shape-4 {
  top: 48%;
  left: 45%;
  width: 1291px;
  height: 1249px;
  mix-blend-mode: normal;
  background: #1b7aff;
  opacity: 0.3;
}
.resume-two .section-header,
.resume-two-two .section-header {
  padding: 0.5rem 0 2.5rem;
}
.resume-two .section-header h2,
.resume-two-two .section-header h2 {
  font-size: 3rem;
  color: #fff;
  -webkit-text-fill-color: #fff;
}
@media (max-width: 575.98px) {
  .resume-two .section-header h2,
.resume-two-two .section-header h2 {
    font-size: 2rem;
  }
}
.resume-two .card,
.resume-two-two .card {
  background: none;
  border: none;
  padding: 6rem 0 0 0;
}
@media (max-width: 1399.98px) {
  .resume-two .card,
.resume-two-two .card {
    padding: 4rem 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .resume-two .card,
.resume-two-two .card {
    padding: 3rem 0 0 0;
  }
}
@media (max-width: 575.98px) {
  .resume-two .card,
.resume-two-two .card {
    padding: 2rem 0 0 0;
  }
}
.resume-two .card-body,
.resume-two-two .card-body {
  padding: 0;
}
.resume-two .card-body .title,
.resume-two-two .card-body .title {
  border-bottom: 1px solid rgba(223, 223, 223, 0.2);
}
.resume-two .card-body .title h5,
.resume-two-two .card-body .title h5 {
  color: #dfdfdf;
}
@media (max-width: 1199.98px) {
  .resume-two .card-body .content,
.resume-two-two .card-body .content {
    margin-bottom: 2.5rem;
  }
}
.resume-two .card-body .content h4,
.resume-two-two .card-body .content h4 {
  color: #fff;
}
.resume-two .card-body .content p,
.resume-two-two .card-body .content p {
  font-weight: 500;
  color: #dfdfdf;
}
.resume-two .card-body .content li,
.resume-two-two .card-body .content li {
  color: #fff;
}

/********** 
9. Project Page styles
**********/
.project,
.project-two,
.project-three {
  position: relative;
}
.project-shape-wrapper,
.project-two-shape-wrapper,
.project-three-shape-wrapper {
  overflow: hidden;
  bottom: 0%;
}
.project-shape-wrapper .shape-1,
.project-shape-wrapper .shape-2,
.project-shape-wrapper .shape-3,
.project-two-shape-wrapper .shape-1,
.project-two-shape-wrapper .shape-2,
.project-two-shape-wrapper .shape-3,
.project-three-shape-wrapper .shape-1,
.project-three-shape-wrapper .shape-2,
.project-three-shape-wrapper .shape-3 {
  position: absolute;
  border-radius: 50%;
  width: 686px;
  height: 686px;
  left: -5%;
  top: 3%;
  background: #b8ffe1;
  mix-blend-mode: multiply;
  opacity: 0.3;
  filter: blur(60px);
  z-index: -1;
}
.project-shape-wrapper .shape-2,
.project-two-shape-wrapper .shape-2,
.project-three-shape-wrapper .shape-2 {
  top: 5%;
  left: 35%;
  opacity: 0.35;
  background: #fbcaff;
}
@media (max-width: 991.98px) {
  .project-shape-wrapper .shape-2,
.project-two-shape-wrapper .shape-2,
.project-three-shape-wrapper .shape-2 {
    top: 2%;
    left: 20%;
  }
}
.project-shape-wrapper .shape-3,
.project-two-shape-wrapper .shape-3,
.project-three-shape-wrapper .shape-3 {
  top: 60%;
  left: 53%;
  background: #b8ffe1;
  opacity: 0.3;
}
@media (max-width: 991.98px) {
  .project-shape-wrapper .shape-3,
.project-two-shape-wrapper .shape-3,
.project-three-shape-wrapper .shape-3 {
    top: 85%;
    left: 15%;
  }
}
.project .section-header,
.project-two .section-header,
.project-three .section-header {
  padding-top: 3rem;
  padding-bottom: 5rem;
}
@media (max-width: 1399.98px) {
  .project .section-header,
.project-two .section-header,
.project-three .section-header {
    padding: 3rem 0 3rem;
  }
}
@media (max-width: 767.98px) {
  .project .section-header,
.project-two .section-header,
.project-three .section-header {
    padding-bottom: 4rem;
  }
}
.project .one,
.project-two .one,
.project-three .one {
  background-color: #ffefb5;
  padding: 4rem 4rem 0rem 5rem;
  position: relative;
  border-radius: 5px;
}
@media (max-width: 991.98px) {
  .project .one,
.project-two .one,
.project-three .one {
    padding: 2rem 2rem 0rem 2rem;
  }
}
.project .one p,
.project-two .one p,
.project-three .one p {
  color: #404152;
  margin-bottom: 5.5rem;
}
@media (max-width: 1399.98px) {
  .project .one p,
.project-two .one p,
.project-three .one p {
    margin-bottom: 3rem;
  }
}
@media (max-width: 991.98px) {
  .project .one p,
.project-two .one p,
.project-three .one p {
    margin-bottom: 1rem;
  }
}
.project-item,
.project-two-item,
.project-three-item {
  overflow: hidden;
  display: flex;
  background-color: #e6f1ff;
  padding: 2rem 2rem 0rem 3rem;
  border-radius: 5px;
}
.project-item.bg-light-dark,
.project-two-item.bg-light-dark,
.project-three-item.bg-light-dark {
  background-color: #5960ff !important;
}
@media (max-width: 991.98px) {
  .project-item,
.project-two-item,
.project-three-item {
    display: block;
    text-align: center;
    border-radius: 5px;
    padding: 2rem 2rem 0rem 2rem;
  }
}
.project-item-content,
.project-two-item-content,
.project-three-item-content {
  position: relative;
  width: 40%;
  padding: 4.5rem 0 0;
}
@media (max-width: 1399.98px) {
  .project-item-content,
.project-two-item-content,
.project-three-item-content {
    padding: 3.5rem 0 0;
  }
}
@media (max-width: 1199.98px) {
  .project-item-content,
.project-two-item-content,
.project-three-item-content {
    padding: 1.5rem 0 0;
  }
}
@media (max-width: 991.98px) {
  .project-item-content,
.project-two-item-content,
.project-three-item-content {
    width: 100%;
    margin-bottom: 2rem;
    padding: 2rem 0 0;
  }
}
.project-item-content h2,
.project-two-item-content h2,
.project-three-item-content h2 {
  margin-bottom: 1.25rem;
}
.project-item-content p,
.project-two-item-content p,
.project-three-item-content p {
  color: #404152;
  margin-bottom: 2.5rem;
}
@media (max-width: 1399.98px) {
  .project-item-content p,
.project-two-item-content p,
.project-three-item-content p {
    margin-bottom: 3rem;
  }
}
@media (max-width: 991.98px) {
  .project-item-content p,
.project-two-item-content p,
.project-three-item-content p {
    margin-bottom: 1rem;
  }
}
.project-item-content h5,
.project-two-item-content h5,
.project-three-item-content h5 {
  font-size: 22px;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.project-item-content a,
.project-two-item-content a,
.project-three-item-content a {
  font-size: 14px;
}
.project-item-banner,
.project-two-item-banner,
.project-three-item-banner {
  width: 60%;
  align-self: flex-end;
}
@media (max-width: 991.98px) {
  .project-item-banner,
.project-two-item-banner,
.project-three-item-banner {
    width: 100%;
  }
}
.project-item-banner img,
.project-two-item-banner img,
.project-three-item-banner img {
  filter: drop-shadow(0px 40px 94px rgba(0, 0, 0, 0.19));
}
.project-item-two,
.project-two-item-two,
.project-three-item-two {
  position: relative;
}
@media (max-width: 991.98px) {
  .project-item-two,
.project-two-item-two,
.project-three-item-two {
    margin-bottom: 2rem;
  }
}
.project-item-two-content,
.project-two-item-two-content,
.project-three-item-two-content {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.project-item-two-content h5,
.project-two-item-two-content h5,
.project-three-item-two-content h5 {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.project-item-two-content p,
.project-two-item-two-content p,
.project-three-item-two-content p {
  color: #fff;
  margin-bottom: 2.5rem;
}
@media (max-width: 1399.98px) {
  .project-item-two-content p,
.project-two-item-two-content p,
.project-three-item-two-content p {
    margin-bottom: 3rem;
  }
}
@media (max-width: 991.98px) {
  .project-item-two-content p,
.project-two-item-two-content p,
.project-three-item-two-content p {
    margin-bottom: 1rem;
  }
}
.project-item-two-content a,
.project-two-item-two-content a,
.project-three-item-two-content a {
  font-size: 14px;
}
.project-item-two-banner,
.project-two-item-two-banner,
.project-three-item-two-banner {
  position: relative;
  align-self: flex-end;
  border-radius: 5px;
  overflow: hidden;
}
.project-item-two-banner::before,
.project-two-item-two-banner::before,
.project-three-item-two-banner::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #004aff;
  opacity: 0.7;
}
.project-item-two-left .project-item-two-banner::before,
.project-two-item-two-left .project-item-two-banner::before,
.project-three-item-two-left .project-item-two-banner::before {
  background: #002b95;
}
.project-two-shape-wrapper,
.project-two-two-shape-wrapper,
.project-three-two-shape-wrapper {
  overflow: hidden;
  bottom: 0%;
}
.project-two-shape-wrapper .shape-1,
.project-two-shape-wrapper .shape-2,
.project-two-shape-wrapper .shape-3,
.project-two-shape-wrapper .shape-4,
.project-two-two-shape-wrapper .shape-1,
.project-two-two-shape-wrapper .shape-2,
.project-two-two-shape-wrapper .shape-3,
.project-two-two-shape-wrapper .shape-4,
.project-three-two-shape-wrapper .shape-1,
.project-three-two-shape-wrapper .shape-2,
.project-three-two-shape-wrapper .shape-3,
.project-three-two-shape-wrapper .shape-4 {
  position: absolute;
  border-radius: 50%;
  width: 686px;
  height: 686px;
  left: -10%;
  top: -10%;
  background: #b9ffe2;
  mix-blend-mode: multiply;
  opacity: 0.5;
  filter: blur(60px);
  z-index: -1;
}
@media (max-width: 1399.98px) {
  .project-two-shape-wrapper .shape-1,
.project-two-shape-wrapper .shape-2,
.project-two-shape-wrapper .shape-3,
.project-two-shape-wrapper .shape-4,
.project-two-two-shape-wrapper .shape-1,
.project-two-two-shape-wrapper .shape-2,
.project-two-two-shape-wrapper .shape-3,
.project-two-two-shape-wrapper .shape-4,
.project-three-two-shape-wrapper .shape-1,
.project-three-two-shape-wrapper .shape-2,
.project-three-two-shape-wrapper .shape-3,
.project-three-two-shape-wrapper .shape-4 {
    left: -30%;
    top: -20%;
  }
}
@media (max-width: 991.98px) {
  .project-two-shape-wrapper .shape-1,
.project-two-shape-wrapper .shape-2,
.project-two-shape-wrapper .shape-3,
.project-two-shape-wrapper .shape-4,
.project-two-two-shape-wrapper .shape-1,
.project-two-two-shape-wrapper .shape-2,
.project-two-two-shape-wrapper .shape-3,
.project-two-two-shape-wrapper .shape-4,
.project-three-two-shape-wrapper .shape-1,
.project-three-two-shape-wrapper .shape-2,
.project-three-two-shape-wrapper .shape-3,
.project-three-two-shape-wrapper .shape-4 {
    left: -40%;
    top: -10%;
  }
}
@media (max-width: 575.98px) {
  .project-two-shape-wrapper .shape-1,
.project-two-shape-wrapper .shape-2,
.project-two-shape-wrapper .shape-3,
.project-two-shape-wrapper .shape-4,
.project-two-two-shape-wrapper .shape-1,
.project-two-two-shape-wrapper .shape-2,
.project-two-two-shape-wrapper .shape-3,
.project-two-two-shape-wrapper .shape-4,
.project-three-two-shape-wrapper .shape-1,
.project-three-two-shape-wrapper .shape-2,
.project-three-two-shape-wrapper .shape-3,
.project-three-two-shape-wrapper .shape-4 {
    left: -100%;
    top: -17%;
  }
}
.project-two-shape-wrapper .shape-2,
.project-two-two-shape-wrapper .shape-2,
.project-three-two-shape-wrapper .shape-2 {
  top: -15%;
  left: 80%;
  opacity: 1;
  background: #cdf6ff;
}
@media (max-width: 1399.98px) {
  .project-two-shape-wrapper .shape-2,
.project-two-two-shape-wrapper .shape-2,
.project-three-two-shape-wrapper .shape-2 {
    top: -20%;
  }
}
@media (max-width: 991.98px) {
  .project-two-shape-wrapper .shape-2,
.project-two-two-shape-wrapper .shape-2,
.project-three-two-shape-wrapper .shape-2 {
    top: -5%;
    left: 70%;
  }
}
@media (max-width: 575.98px) {
  .project-two-shape-wrapper .shape-2,
.project-two-two-shape-wrapper .shape-2,
.project-three-two-shape-wrapper .shape-2 {
    top: -15%;
    left: 60%;
  }
}
.project-two-shape-wrapper .shape-3,
.project-two-two-shape-wrapper .shape-3,
.project-three-two-shape-wrapper .shape-3 {
  top: 45%;
  left: -13%;
  background: #b8ffe1;
  opacity: 0.3;
}
.project-two-shape-wrapper .shape-4,
.project-two-two-shape-wrapper .shape-4,
.project-three-two-shape-wrapper .shape-4 {
  top: 65%;
  left: 55%;
  background: #b8ffe1;
  opacity: 0.3;
}
@media (max-width: 1399.98px) {
  .project-two-shape-wrapper .shape-4,
.project-two-two-shape-wrapper .shape-4,
.project-three-two-shape-wrapper .shape-4 {
    top: 50%;
  }
}
.project-two .section-header,
.project-two-two .section-header,
.project-three-two .section-header {
  padding-bottom: 6.75rem;
}
@media (max-width: 1399.98px) {
  .project-two .section-header,
.project-two-two .section-header,
.project-three-two .section-header {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
}
@media (max-width: 991.98px) {
  .project-two .section-header,
.project-two-two .section-header,
.project-three-two .section-header {
    padding-top: 1rem;
  }
}
.project-two .section-header h2,
.project-two-two .section-header h2,
.project-three-two .section-header h2 {
  font-size: 4rem;
}
@media (max-width: 991.98px) {
  .project-two .section-header h2,
.project-two-two .section-header h2,
.project-three-two .section-header h2 {
    font-size: 3rem;
  }
}
@media (max-width: 575.98px) {
  .project-two .section-header h2,
.project-two-two .section-header h2,
.project-three-two .section-header h2 {
    font-size: 2rem;
  }
}
.project-two .link,
.project-two-two .link,
.project-three-two .link {
  padding-top: 9rem;
}
@media (max-width: 1399.98px) {
  .project-two .link,
.project-two-two .link,
.project-three-two .link {
    padding-top: 5rem;
  }
}
@media (max-width: 991.98px) {
  .project-two .link,
.project-two-two .link,
.project-three-two .link {
    padding-top: 3rem;
  }
}
.project-two .link a,
.project-two-two .link a,
.project-three-two .link a {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #060A11;
  transition: all 0.3s ease-in-out;
}
.project-two .link a i,
.project-two-two .link a i,
.project-three-two .link a i {
  padding-right: 1rem;
}
.project-two .link a:hover,
.project-two-two .link a:hover,
.project-three-two .link a:hover {
  color: #404152;
}
.project-three,
.project-two-three,
.project-three-three {
  padding: 6.5rem 0 3.5rem;
}
@media (max-width: 1399.98px) {
  .project-three,
.project-two-three,
.project-three-three {
    padding: 1rem 0 0rem;
  }
}
@media (max-width: 1199.98px) {
  .project-three,
.project-two-three,
.project-three-three {
    padding-top: 2rem;
  }
}
.project-three .section-header,
.project-two-three .section-header,
.project-three-three .section-header {
  padding-bottom: 3rem;
}
@media (max-width: 1399.98px) {
  .project-three .section-header,
.project-two-three .section-header,
.project-three-three .section-header {
    padding: 3rem 0rem;
  }
}
@media (max-width: 991.98px) {
  .project-three .section-header,
.project-two-three .section-header,
.project-three-three .section-header {
    padding-top: 1rem;
  }
}
.project-desc-header,
.project-two-desc-header,
.project-three-desc-header {
  padding: 7.5rem 0;
}
@media (max-width: 1399.98px) {
  .project-desc-header,
.project-two-desc-header,
.project-three-desc-header {
    padding: 4.5rem 0;
  }
}
@media (max-width: 991.98px) {
  .project-desc-header,
.project-two-desc-header,
.project-three-desc-header {
    padding: 2rem 0;
    text-align: center;
  }
}
.project-desc-header h3,
.project-two-desc-header h3,
.project-three-desc-header h3 {
  margin-bottom: 2.5rem;
}
.project-desc-header p,
.project-two-desc-header p,
.project-three-desc-header p {
  margin-bottom: 0;
}
.project-desc-header:last-child,
.project-two-desc-header:last-child,
.project-three-desc-header:last-child {
  padding-top: 0;
}
.project-desc-content,
.project-desc .banner,
.project-two-desc-content,
.project-two-desc .banner,
.project-three-desc-content,
.project-three-desc .banner {
  padding-bottom: 7.5rem;
}
@media (max-width: 1399.98px) {
  .project-desc-content,
.project-desc .banner,
.project-two-desc-content,
.project-two-desc .banner,
.project-three-desc-content,
.project-three-desc .banner {
    padding-bottom: 4.5rem;
  }
}
@media (max-width: 1199.98px) {
  .project-desc-content,
.project-desc .banner,
.project-two-desc-content,
.project-two-desc .banner,
.project-three-desc-content,
.project-three-desc .banner {
    padding-bottom: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .project-desc-content,
.project-desc .banner,
.project-two-desc-content,
.project-two-desc .banner,
.project-three-desc-content,
.project-three-desc .banner {
    padding-bottom: 2rem;
  }
}
.project-desc-content h3,
.project-desc .banner h3,
.project-two-desc-content h3,
.project-two-desc .banner h3,
.project-three-desc-content h3,
.project-three-desc .banner h3 {
  margin-bottom: 2.5rem;
}
.project-desc-content ol,
.project-desc .banner ol,
.project-two-desc-content ol,
.project-two-desc .banner ol,
.project-three-desc-content ol,
.project-three-desc .banner ol {
  padding-left: 1rem;
  margin-bottom: 0;
}
.project-desc-content ol li,
.project-desc .banner ol li,
.project-two-desc-content ol li,
.project-two-desc .banner ol li,
.project-three-desc-content ol li,
.project-three-desc .banner ol li {
  padding-bottom: 0.5rem;
}
.project-related,
.project-two-related,
.project-three-related {
  background-color: #ececec !important;
}
.project-related .section-header,
.project-two-related .section-header,
.project-three-related .section-header {
  padding: 3.75rem 0;
}
.project-related .section-header h2,
.project-two-related .section-header h2,
.project-three-related .section-header h2 {
  color: #000;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
}
.project-related .section-header p,
.project-two-related .section-header p,
.project-three-related .section-header p {
  margin-bottom: 0;
}
.project-related-item img,
.project-two-related-item img,
.project-three-related-item img {
  width: 100%;
}
@media (max-width: 991.98px) {
  .project-related-item img,
.project-two-related-item img,
.project-three-related-item img {
    margin-bottom: 2rem;
  }
}

/********** 
10. Counter UP Section
**********/
.counter-up {
  padding-top: 7.5rem;
}
@media (max-width: 1399.98px) {
  .counter-up {
    padding-top: 5.5rem;
  }
}
@media (max-width: 991.98px) {
  .counter-up {
    padding-top: 3.5rem;
  }
}
.counter-up .counter-wraper {
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(223, 223, 223, 0.5);
}
.counter-up-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .counter-up-content {
    margin-bottom: 1.25rem;
  }
}
.counter-up-content-item {
  text-align: center;
}
@media (max-width: 767.98px) {
  .counter-up-content-item {
    margin-bottom: 1.25rem;
  }
}
.counter-up-content-item .counter-percent {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 0.5rem;
}
@media (max-width: 767.98px) {
  .counter-up-content-item .counter-percent {
    justify-content: center;
  }
}
.counter-up-content-item .counter-percent span.counter,
.counter-up-content-item .counter-percent span.percent {
  font-size: 50px;
  line-height: 1;
  color: #000;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
}
@media (max-width: 767.98px) {
  .counter-up-content-item .counter-percent span.counter,
.counter-up-content-item .counter-percent span.percent {
    font-size: 52px;
  }
}
@media (max-width: 575.98px) {
  .counter-up-content-item .counter-percent span.counter,
.counter-up-content-item .counter-percent span.percent {
    font-size: 32px;
  }
}
.counter-up-content-item .counter-percent span.percent {
  margin-left: 5px;
}
.counter-up-content-item h5 {
  text-transform: uppercase;
}
@media (max-width: 991.98px) {
  .counter-up-content-item h5 {
    font-size: 1rem;
  }
}

/********** 
11. feedback Section Styles
**********/
.feedback {
  background-color: #070c13;
  position: relative;
}
.feedback .counter-wraper {
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(232, 232, 232, 0.1);
}
.feedback .counter-up-content-item .counter-percent span.counter,
.feedback .counter-up-content-item .counter-percent span.percent {
  color: #fffbd3;
  margin-bottom: 2px;
}
.feedback .counter-up-content-item .counter-percent span.percent {
  color: #fffbd3;
}
.feedback .counter-up-content-item h5 {
  color: #fff;
}
.feedback .section-header {
  margin: 1rem 0;
}
@media (max-width: 1199.98px) {
  .feedback .section-header {
    margin: 0rem;
  }
}
@media (max-width: 991.98px) {
  .feedback-slider {
    text-align: center;
  }
}
.feedback-slider-item .icon img {
  max-width: 100%;
}
@media (max-width: 991.98px) {
  .feedback-slider-item .icon img {
    margin: 0 auto;
  }
}
.feedback-slider-item .content {
  padding-top: 10px;
  margin-bottom: 3rem;
}
.feedback-slider-item .content h3 {
  color: #fff;
  font-weight: 700;
  line-height: 46.6px;
}
.feedback-slider-item .review,
.feedback-slider-item .profile,
.feedback-slider-item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 991.98px) {
  .feedback-slider-item .review,
.feedback-slider-item .profile,
.feedback-slider-item .info {
    display: block;
    text-align: center;
  }
}
.feedback-slider-item .review .profile img,
.feedback-slider-item .profile .profile img,
.feedback-slider-item .info .profile img {
  width: 86px;
  height: 86px;
  margin: 0 auto;
  border-radius: 50%;
}
@media (max-width: 991.98px) {
  .feedback-slider-item .review .profile .info,
.feedback-slider-item .profile .profile .info,
.feedback-slider-item .info .profile .info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
  }
}
.feedback-slider-item .review .profile .info h5,
.feedback-slider-item .profile .profile .info h5,
.feedback-slider-item .info .profile .info h5 {
  margin-left: 1.5rem;
  color: #fff;
  margin-bottom: 0;
}
.feedback-slider-item .review .profile .info span,
.feedback-slider-item .profile .profile .info span,
.feedback-slider-item .info .profile .info span {
  color: #dddddd;
  margin: 0 0.5rem;
}
.feedback-slider-item .review .profile .info h6,
.feedback-slider-item .profile .profile .info h6,
.feedback-slider-item .info .profile .info h6 {
  margin-bottom: 0;
  color: #dfdfdf;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.feedback-slider .slick-dots {
  list-style: none;
  position: absolute;
  right: 0%;
  bottom: 0;
}
.feedback-slider .slick-dots li {
  display: inline-block;
  margin-right: 10px;
}
.feedback-slider .slick-dots li:last-child {
  margin-right: 0;
}
.feedback-slider .slick-dots li button {
  display: block;
  overflow: hidden;
  position: relative;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
}
.feedback-slider .slick-dots li button img {
  width: 100%;
  height: 100%;
}

/********** 
12. Blog  Page Styles 
**********/
.blog,
.related-blog,
.taxonomy {
  position: relative;
}
.blog-shape-wrapper,
.related-blog-shape-wrapper,
.taxonomy-shape-wrapper {
  overflow: hidden;
  bottom: 0%;
}
.blog-shape-wrapper .shape-1,
.blog-shape-wrapper .shape-2,
.blog-shape-wrapper .shape-3,
.blog-shape-wrapper .shape-4,
.blog-shape-wrapper .shape-5,
.related-blog-shape-wrapper .shape-1,
.related-blog-shape-wrapper .shape-2,
.related-blog-shape-wrapper .shape-3,
.related-blog-shape-wrapper .shape-4,
.related-blog-shape-wrapper .shape-5,
.taxonomy-shape-wrapper .shape-1,
.taxonomy-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-4,
.taxonomy-shape-wrapper .shape-5 {
  position: absolute;
  border-radius: 50%;
  width: 686px;
  height: 686px;
  left: -13%;
  top: -10%;
  z-index: -1;
  background: #fffa8d;
  mix-blend-mode: multiply;
  opacity: 0.5;
  filter: blur(60px);
}
@media (max-width: 1399.98px) {
  .blog-shape-wrapper .shape-1,
.blog-shape-wrapper .shape-2,
.blog-shape-wrapper .shape-3,
.blog-shape-wrapper .shape-4,
.blog-shape-wrapper .shape-5,
.related-blog-shape-wrapper .shape-1,
.related-blog-shape-wrapper .shape-2,
.related-blog-shape-wrapper .shape-3,
.related-blog-shape-wrapper .shape-4,
.related-blog-shape-wrapper .shape-5,
.taxonomy-shape-wrapper .shape-1,
.taxonomy-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-4,
.taxonomy-shape-wrapper .shape-5 {
    left: -20%;
    top: -15%;
  }
}
@media (max-width: 991.98px) {
  .blog-shape-wrapper .shape-1,
.blog-shape-wrapper .shape-2,
.blog-shape-wrapper .shape-3,
.blog-shape-wrapper .shape-4,
.blog-shape-wrapper .shape-5,
.related-blog-shape-wrapper .shape-1,
.related-blog-shape-wrapper .shape-2,
.related-blog-shape-wrapper .shape-3,
.related-blog-shape-wrapper .shape-4,
.related-blog-shape-wrapper .shape-5,
.taxonomy-shape-wrapper .shape-1,
.taxonomy-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-4,
.taxonomy-shape-wrapper .shape-5 {
    top: -5%;
    left: -40%;
  }
}
@media (max-width: 767.98px) {
  .blog-shape-wrapper .shape-1,
.blog-shape-wrapper .shape-2,
.blog-shape-wrapper .shape-3,
.blog-shape-wrapper .shape-4,
.blog-shape-wrapper .shape-5,
.related-blog-shape-wrapper .shape-1,
.related-blog-shape-wrapper .shape-2,
.related-blog-shape-wrapper .shape-3,
.related-blog-shape-wrapper .shape-4,
.related-blog-shape-wrapper .shape-5,
.taxonomy-shape-wrapper .shape-1,
.taxonomy-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-4,
.taxonomy-shape-wrapper .shape-5 {
    left: -70%;
  }
}
@media (max-width: 575.98px) {
  .blog-shape-wrapper .shape-1,
.blog-shape-wrapper .shape-2,
.blog-shape-wrapper .shape-3,
.blog-shape-wrapper .shape-4,
.blog-shape-wrapper .shape-5,
.related-blog-shape-wrapper .shape-1,
.related-blog-shape-wrapper .shape-2,
.related-blog-shape-wrapper .shape-3,
.related-blog-shape-wrapper .shape-4,
.related-blog-shape-wrapper .shape-5,
.taxonomy-shape-wrapper .shape-1,
.taxonomy-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-4,
.taxonomy-shape-wrapper .shape-5 {
    left: -130%;
  }
}
.blog-shape-wrapper .shape-2,
.related-blog-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-2 {
  left: 80%;
  top: -10%;
  background: #e2ddff;
  opacity: 1;
}
@media (max-width: 1399.98px) {
  .blog-shape-wrapper .shape-2,
.related-blog-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-2 {
    left: 70%;
    top: -15%;
  }
}
@media (max-width: 991.98px) {
  .blog-shape-wrapper .shape-2,
.related-blog-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-2 {
    top: -5%;
    left: 60%;
  }
}
.blog-shape-wrapper .shape-3,
.related-blog-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-3 {
  top: 35%;
  left: -15%;
  background: #b8ffe1;
  opacity: 0.3;
}
.blog-shape-wrapper .shape-4,
.related-blog-shape-wrapper .shape-4,
.taxonomy-shape-wrapper .shape-4 {
  top: 26%;
  left: 40%;
  background: #fbcaff;
  opacity: 0.35;
  z-index: 1;
}
.blog-shape-wrapper .shape-5,
.related-blog-shape-wrapper .shape-5,
.taxonomy-shape-wrapper .shape-5 {
  top: 56%;
  left: 50%;
  background: #b8ffe1;
  opacity: 0.3;
  z-index: 1;
}
.blog .section-header h2,
.related-blog .section-header h2,
.taxonomy .section-header h2 {
  font-size: 4rem;
}
@media (max-width: 991.98px) {
  .blog .section-header h2,
.related-blog .section-header h2,
.taxonomy .section-header h2 {
    font-size: 3rem;
  }
}
@media (max-width: 575.98px) {
  .blog .section-header h2,
.related-blog .section-header h2,
.taxonomy .section-header h2 {
    font-size: 2rem;
  }
}
.blog .card,
.related-blog .card,
.taxonomy .card {
  align-items: center;
  box-shadow: none;
  border-radius: 0;
  padding: 0rem;
  transition: all 0.3s ease-in-out;
  background: none;
  overflow: hidden;
  z-index: 100;
}
@media (max-width: 575.98px) {
  .blog .card,
.related-blog .card,
.taxonomy .card {
    margin-bottom: 1.5rem;
  }
}
.blog .card .blog-post-item-thumb,
.related-blog .card .blog-post-item-thumb,
.taxonomy .card .blog-post-item-thumb {
  width: 50%;
  margin-right: 2rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
  border-radius: 5px;
}
@media (max-width: 1199.98px) {
  .blog .card .blog-post-item-thumb,
.related-blog .card .blog-post-item-thumb,
.taxonomy .card .blog-post-item-thumb {
    margin-right: 2rem;
  }
}
@media (max-width: 991.98px) {
  .blog .card .blog-post-item-thumb,
.related-blog .card .blog-post-item-thumb,
.taxonomy .card .blog-post-item-thumb {
    width: 100%;
    margin-right: 0rem;
  }
}
.blog .card .blog-post-item-thumb img,
.related-blog .card .blog-post-item-thumb img,
.taxonomy .card .blog-post-item-thumb img {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}
.blog .card .blog-post-content,
.blog .card .card-body,
.related-blog .card .blog-post-content,
.related-blog .card .card-body,
.taxonomy .card .blog-post-content,
.taxonomy .card .card-body {
  width: 50%;
  z-index: 9;
}
@media (max-width: 991.98px) {
  .blog .card .blog-post-content,
.blog .card .card-body,
.related-blog .card .blog-post-content,
.related-blog .card .card-body,
.taxonomy .card .blog-post-content,
.taxonomy .card .card-body {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
}
@media (max-width: 575.98px) {
  .blog .card .blog-post-content,
.blog .card .card-body,
.related-blog .card .blog-post-content,
.related-blog .card .card-body,
.taxonomy .card .blog-post-content,
.taxonomy .card .card-body {
    margin-bottom: 0rem;
  }
}
.blog .card .blog-post-content .blog-post-meta ul,
.blog .card .card-body .blog-post-meta ul,
.related-blog .card .blog-post-content .blog-post-meta ul,
.related-blog .card .card-body .blog-post-meta ul,
.taxonomy .card .blog-post-content .blog-post-meta ul,
.taxonomy .card .card-body .blog-post-meta ul {
  display: flex;
  align-items: center;
}
@media (max-width: 991.98px) {
  .blog .card .blog-post-content .blog-post-meta ul,
.blog .card .card-body .blog-post-meta ul,
.related-blog .card .blog-post-content .blog-post-meta ul,
.related-blog .card .card-body .blog-post-meta ul,
.taxonomy .card .blog-post-content .blog-post-meta ul,
.taxonomy .card .card-body .blog-post-meta ul {
    justify-content: center;
  }
}
.blog .card .blog-post-content .blog-post-meta ul li,
.blog .card .card-body .blog-post-meta ul li,
.related-blog .card .blog-post-content .blog-post-meta ul li,
.related-blog .card .card-body .blog-post-meta ul li,
.taxonomy .card .blog-post-content .blog-post-meta ul li,
.taxonomy .card .card-body .blog-post-meta ul li {
  font-weight: normal;
  color: #aaaab7;
}
.blog .card .blog-post-content .blog-post-meta ul li a,
.blog .card .card-body .blog-post-meta ul li a,
.related-blog .card .blog-post-content .blog-post-meta ul li a,
.related-blog .card .card-body .blog-post-meta ul li a,
.taxonomy .card .blog-post-content .blog-post-meta ul li a,
.taxonomy .card .card-body .blog-post-meta ul li a {
  transition: all 0.3s ease-in-out;
  color: #aaaab7;
}
.blog .card .blog-post-content .blog-post-meta ul li a:hover,
.blog .card .card-body .blog-post-meta ul li a:hover,
.related-blog .card .blog-post-content .blog-post-meta ul li a:hover,
.related-blog .card .card-body .blog-post-meta ul li a:hover,
.taxonomy .card .blog-post-content .blog-post-meta ul li a:hover,
.taxonomy .card .card-body .blog-post-meta ul li a:hover {
  color: #060A11;
}
.blog .card .blog-post-content .blog-post-meta ul li span,
.blog .card .card-body .blog-post-meta ul li span,
.related-blog .card .blog-post-content .blog-post-meta ul li span,
.related-blog .card .card-body .blog-post-meta ul li span,
.taxonomy .card .blog-post-content .blog-post-meta ul li span,
.taxonomy .card .card-body .blog-post-meta ul li span {
  padding-left: 1rem;
  position: relative;
  color: #aaaab7;
}
.blog .card .blog-post-content .blog-post-meta ul li span::before,
.blog .card .card-body .blog-post-meta ul li span::before,
.related-blog .card .blog-post-content .blog-post-meta ul li span::before,
.related-blog .card .card-body .blog-post-meta ul li span::before,
.taxonomy .card .blog-post-content .blog-post-meta ul li span::before,
.taxonomy .card .card-body .blog-post-meta ul li span::before {
  position: absolute;
  content: "";
  background-color: #aaaab7;
  width: 20px;
  height: 2px;
  top: 10px;
  left: -5px;
  transform: rotate(118deg);
}
.blog .card .blog-post-content .blog-post-body-title a h2,
.blog .card .card-body .blog-post-body-title a h2,
.related-blog .card .blog-post-content .blog-post-body-title a h2,
.related-blog .card .card-body .blog-post-body-title a h2,
.taxonomy .card .blog-post-content .blog-post-body-title a h2,
.taxonomy .card .card-body .blog-post-body-title a h2 {
  transition: all 0.3s ease-in-out;
  line-height: 60px;
  margin-bottom: 2.5rem;
}
@media (max-width: 1399.98px) {
  .blog .card .blog-post-content .blog-post-body-title a h2,
.blog .card .card-body .blog-post-body-title a h2,
.related-blog .card .blog-post-content .blog-post-body-title a h2,
.related-blog .card .card-body .blog-post-body-title a h2,
.taxonomy .card .blog-post-content .blog-post-body-title a h2,
.taxonomy .card .card-body .blog-post-body-title a h2 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 1199.98px) {
  .blog .card .blog-post-content .blog-post-body-title a h2,
.blog .card .card-body .blog-post-body-title a h2,
.related-blog .card .blog-post-content .blog-post-body-title a h2,
.related-blog .card .card-body .blog-post-body-title a h2,
.taxonomy .card .blog-post-content .blog-post-body-title a h2,
.taxonomy .card .card-body .blog-post-body-title a h2 {
    margin-bottom: 1rem;
  }
}
.blog .card .blog-post-content .blog-post-body-title a span,
.blog .card .card-body .blog-post-body-title a span,
.related-blog .card .blog-post-content .blog-post-body-title a span,
.related-blog .card .card-body .blog-post-body-title a span,
.taxonomy .card .blog-post-content .blog-post-body-title a span,
.taxonomy .card .card-body .blog-post-body-title a span {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.blog .card .blog-post-content .blog-post-body-title a span i,
.blog .card .card-body .blog-post-body-title a span i,
.related-blog .card .blog-post-content .blog-post-body-title a span i,
.related-blog .card .card-body .blog-post-body-title a span i,
.taxonomy .card .blog-post-content .blog-post-body-title a span i,
.taxonomy .card .card-body .blog-post-body-title a span i {
  margin-right: 0.5rem;
}
.blog .card .blog-post-content .blog-post-body-title p,
.blog .card .card-body .blog-post-body-title p,
.related-blog .card .blog-post-content .blog-post-body-title p,
.related-blog .card .card-body .blog-post-body-title p,
.taxonomy .card .blog-post-content .blog-post-body-title p,
.taxonomy .card .card-body .blog-post-body-title p {
  margin-bottom: 0;
}
.blog .card .card-body,
.related-blog .card .card-body,
.taxonomy .card .card-body {
  padding: 2rem 0 1.5rem;
  width: 100%;
}
@media (max-width: 575.98px) {
  .blog .card .card-body,
.related-blog .card .card-body,
.taxonomy .card .card-body {
    padding: 1rem 0;
  }
}
.blog .card .card-body h5,
.related-blog .card .card-body h5,
.taxonomy .card .card-body h5 {
  transition: all 0.3s ease-in-out;
}
.blog .card .card-body .blog-post-meta,
.related-blog .card .card-body .blog-post-meta,
.taxonomy .card .card-body .blog-post-meta {
  margin-bottom: 1.3rem;
}
@media (max-width: 575.98px) {
  .blog .card .card-body .blog-post-meta,
.related-blog .card .card-body .blog-post-meta,
.taxonomy .card .card-body .blog-post-meta {
    margin-bottom: 0;
  }
}
.blog .card:hover img,
.related-blog .card:hover img,
.taxonomy .card:hover img {
  transform: scale(1.1);
}
.blog .card:hover a h2,
.blog .card:hover a h5,
.related-blog .card:hover a h2,
.related-blog .card:hover a h5,
.taxonomy .card:hover a h2,
.taxonomy .card:hover a h5 {
  color: #404152;
}
.blog .card-img,
.related-blog .card-img,
.taxonomy .card-img {
  overflow: hidden;
  border-radius: 5px;
}
.blog .card-img img,
.related-blog .card-img img,
.taxonomy .card-img img {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}
.blog-details,
.related-blog-details,
.taxonomy-details {
  position: relative;
}
.blog-details-shape-wrapper,
.related-blog-details-shape-wrapper,
.taxonomy-details-shape-wrapper {
  overflow: hidden;
  z-index: -1;
  bottom: 0%;
}
.blog-details-shape-wrapper .shape-1,
.blog-details-shape-wrapper .shape-2,
.related-blog-details-shape-wrapper .shape-1,
.related-blog-details-shape-wrapper .shape-2,
.taxonomy-details-shape-wrapper .shape-1,
.taxonomy-details-shape-wrapper .shape-2 {
  position: absolute;
  border-radius: 50%;
  width: 686px;
  height: 686px;
  left: -10%;
  top: -2%;
  background: #d8b9ff;
  mix-blend-mode: multiply;
  opacity: 0.5;
  filter: blur(60px);
}
@media (max-width: 1399.98px) {
  .blog-details-shape-wrapper .shape-1,
.blog-details-shape-wrapper .shape-2,
.related-blog-details-shape-wrapper .shape-1,
.related-blog-details-shape-wrapper .shape-2,
.taxonomy-details-shape-wrapper .shape-1,
.taxonomy-details-shape-wrapper .shape-2 {
    left: -15%;
    top: -5%;
  }
}
@media (max-width: 1199.98px) {
  .blog-details-shape-wrapper .shape-1,
.blog-details-shape-wrapper .shape-2,
.related-blog-details-shape-wrapper .shape-1,
.related-blog-details-shape-wrapper .shape-2,
.taxonomy-details-shape-wrapper .shape-1,
.taxonomy-details-shape-wrapper .shape-2 {
    left: -20%;
  }
}
@media (max-width: 991.98px) {
  .blog-details-shape-wrapper .shape-1,
.blog-details-shape-wrapper .shape-2,
.related-blog-details-shape-wrapper .shape-1,
.related-blog-details-shape-wrapper .shape-2,
.taxonomy-details-shape-wrapper .shape-1,
.taxonomy-details-shape-wrapper .shape-2 {
    left: -40%;
  }
}
@media (max-width: 767.98px) {
  .blog-details-shape-wrapper .shape-1,
.blog-details-shape-wrapper .shape-2,
.related-blog-details-shape-wrapper .shape-1,
.related-blog-details-shape-wrapper .shape-2,
.taxonomy-details-shape-wrapper .shape-1,
.taxonomy-details-shape-wrapper .shape-2 {
    left: -70%;
  }
}
@media (max-width: 575.98px) {
  .blog-details-shape-wrapper .shape-1,
.blog-details-shape-wrapper .shape-2,
.related-blog-details-shape-wrapper .shape-1,
.related-blog-details-shape-wrapper .shape-2,
.taxonomy-details-shape-wrapper .shape-1,
.taxonomy-details-shape-wrapper .shape-2 {
    left: -100%;
  }
}
.blog-details-shape-wrapper .shape-2,
.related-blog-details-shape-wrapper .shape-2,
.taxonomy-details-shape-wrapper .shape-2 {
  left: 77%;
  top: 0;
  background: #fffdcd;
}
@media (max-width: 1399.98px) {
  .blog-details-shape-wrapper .shape-2,
.related-blog-details-shape-wrapper .shape-2,
.taxonomy-details-shape-wrapper .shape-2 {
    left: 70%;
    top: -2%;
  }
}
@media (max-width: 991.98px) {
  .blog-details-shape-wrapper .shape-2,
.related-blog-details-shape-wrapper .shape-2,
.taxonomy-details-shape-wrapper .shape-2 {
    left: 60%;
    top: -5%;
  }
}
@media (max-width: 575.98px) {
  .blog-details-shape-wrapper .shape-2,
.related-blog-details-shape-wrapper .shape-2,
.taxonomy-details-shape-wrapper .shape-2 {
    left: 30%;
  }
}
.blog-details .card,
.related-blog-details .card,
.taxonomy-details .card {
  box-shadow: none;
  border-radius: 0;
  padding: 0rem;
  background: none;
}
@media (max-width: 1199.98px) {
  .blog-details .card,
.related-blog-details .card,
.taxonomy-details .card {
    padding: 1rem 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .blog-details .card,
.related-blog-details .card,
.taxonomy-details .card {
    text-align: center;
    padding: 4rem 0 0 0;
  }
}
.blog-details .card .blog-des-hero,
.related-blog-details .card .blog-des-hero,
.taxonomy-details .card .blog-des-hero {
  width: 72%;
  margin-bottom: 4rem;
}
@media (max-width: 1199.98px) {
  .blog-details .card .blog-des-hero,
.related-blog-details .card .blog-des-hero,
.taxonomy-details .card .blog-des-hero {
    width: 80%;
    margin-bottom: 3rem;
  }
}
@media (max-width: 991.98px) {
  .blog-details .card .blog-des-hero,
.related-blog-details .card .blog-des-hero,
.taxonomy-details .card .blog-des-hero {
    width: 100%;
  }
}
.blog-details .card .blog-des-hero h2,
.related-blog-details .card .blog-des-hero h2,
.taxonomy-details .card .blog-des-hero h2 {
  margin-bottom: 2.5rem;
}
@media (max-width: 991.98px) {
  .blog-details .card .blog-des-hero-content,
.related-blog-details .card .blog-des-hero-content,
.taxonomy-details .card .blog-des-hero-content {
    justify-content: center;
  }
}
.blog-details .card .blog-des-hero-content span,
.related-blog-details .card .blog-des-hero-content span,
.taxonomy-details .card .blog-des-hero-content span {
  font-size: 18px;
  font-weight: 500;
  color: #404152;
  display: block;
  margin-bottom: 0.5rem;
}
.blog-details .card .blog-des-hero-content .title,
.related-blog-details .card .blog-des-hero-content .title,
.taxonomy-details .card .blog-des-hero-content .title {
  font-size: 20px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
}
.blog-details .card .feature-image,
.related-blog-details .card .feature-image,
.taxonomy-details .card .feature-image {
  margin-bottom: 8.5rem;
}
@media (max-width: 1399.98px) {
  .blog-details .card .feature-image,
.related-blog-details .card .feature-image,
.taxonomy-details .card .feature-image {
    margin-bottom: 5.5rem;
  }
}
@media (max-width: 1199.98px) {
  .blog-details .card .feature-image,
.related-blog-details .card .feature-image,
.taxonomy-details .card .feature-image {
    margin-bottom: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .blog-details .card .feature-image,
.related-blog-details .card .feature-image,
.taxonomy-details .card .feature-image {
    margin-bottom: 3rem;
  }
}
@media (max-width: 767.98px) {
  .blog-details .card .feature-image,
.related-blog-details .card .feature-image,
.taxonomy-details .card .feature-image {
    margin-bottom: 2rem;
  }
}
.blog-details .card .feature-image img,
.related-blog-details .card .feature-image img,
.taxonomy-details .card .feature-image img {
  border-radius: 10px;
  margin-bottom: 0rem;
}
.blog-details .card ul,
.related-blog-details .card ul,
.taxonomy-details .card ul {
  margin-bottom: 7.5rem;
}
@media (max-width: 1399.98px) {
  .blog-details .card ul,
.related-blog-details .card ul,
.taxonomy-details .card ul {
    margin-bottom: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .blog-details .card ul,
.related-blog-details .card ul,
.taxonomy-details .card ul {
    text-align: left;
    margin-bottom: 3rem;
  }
}
.blog-details .card ul li,
.related-blog-details .card ul li,
.taxonomy-details .card ul li {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.blog-details .card ul li:last-child,
.related-blog-details .card ul li:last-child,
.taxonomy-details .card ul li:last-child {
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .blog-details .card ul li,
.related-blog-details .card ul li,
.taxonomy-details .card ul li {
    align-items: start;
  }
}
.blog-details .card ul li i,
.related-blog-details .card ul li i,
.taxonomy-details .card ul li i {
  font-size: 1.25rem;
  color: #060A11;
  padding-right: 2rem;
}
.blog-details .card img,
.related-blog-details .card img,
.taxonomy-details .card img {
  border-radius: 5px;
  margin-bottom: 7.5rem;
}
@media (max-width: 1399.98px) {
  .blog-details .card img,
.related-blog-details .card img,
.taxonomy-details .card img {
    margin-bottom: 3.5rem;
  }
}
@media (max-width: 1199.98px) {
  .blog-details .card img,
.related-blog-details .card img,
.taxonomy-details .card img {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .blog-details .card img,
.related-blog-details .card img,
.taxonomy-details .card img {
    margin-bottom: 3rem;
  }
}
@media (max-width: 767.98px) {
  .blog-details .card img,
.related-blog-details .card img,
.taxonomy-details .card img {
    margin-bottom: 2rem;
  }
}
.blog-details .card h3,
.related-blog-details .card h3,
.taxonomy-details .card h3 {
  width: 85%;
  font-weight: 700;
  margin-bottom: 3.75rem;
}
@media (max-width: 1199.98px) {
  .blog-details .card h3,
.related-blog-details .card h3,
.taxonomy-details .card h3 {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .blog-details .card h3,
.related-blog-details .card h3,
.taxonomy-details .card h3 {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .blog-details .card h3,
.related-blog-details .card h3,
.taxonomy-details .card h3 {
    margin-bottom: 2rem;
  }
}
.blog-details .card p,
.related-blog-details .card p,
.taxonomy-details .card p {
  margin-bottom: 3.75rem;
  line-height: 2;
}
@media (max-width: 991.98px) {
  .blog-details .card p,
.related-blog-details .card p,
.taxonomy-details .card p {
    margin-bottom: 2rem;
  }
}
.blog-details .banner,
.related-blog-details .banner,
.taxonomy-details .banner {
  gap: 2rem;
}
.blog .recent-blog-title,
.related-blog .recent-blog-title,
.taxonomy .recent-blog-title {
  font-size: 3rem;
}

.taxonomy {
  padding-top: 15rem;
}
@media (max-width: 1399.98px) {
  .taxonomy {
    padding-top: 10rem;
  }
}
@media (max-width: 575.98px) {
  .taxonomy {
    padding-top: 8rem;
  }
}
.taxonomy-shape-wrapper {
  overflow: hidden;
  bottom: 0%;
}
.taxonomy-shape-wrapper .shape-1,
.taxonomy-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-4 {
  position: absolute;
  border-radius: 50%;
  width: 686px;
  height: 686px;
  left: -13%;
  top: -10%;
  z-index: -1;
  background: #fffa8d;
  mix-blend-mode: multiply;
  opacity: 0.5;
  filter: blur(60px);
}
@media (max-width: 1399.98px) {
  .taxonomy-shape-wrapper .shape-1,
.taxonomy-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-4 {
    top: -25%;
  }
}
@media (max-width: 1199.98px) {
  .taxonomy-shape-wrapper .shape-1,
.taxonomy-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-4 {
    top: -30%;
    left: -25%;
  }
}
@media (max-width: 991.98px) {
  .taxonomy-shape-wrapper .shape-1,
.taxonomy-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-4 {
    top: 0;
    left: -30%;
  }
}
@media (max-width: 767.98px) {
  .taxonomy-shape-wrapper .shape-1,
.taxonomy-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-4 {
    left: -70%;
  }
}
@media (max-width: 575.98px) {
  .taxonomy-shape-wrapper .shape-1,
.taxonomy-shape-wrapper .shape-2,
.taxonomy-shape-wrapper .shape-3,
.taxonomy-shape-wrapper .shape-4 {
    left: -130%;
  }
}
.taxonomy-shape-wrapper .shape-2 {
  left: 80%;
  top: -4%;
  opacity: 1;
  background: #e2ddff;
}
@media (max-width: 1399.98px) {
  .taxonomy-shape-wrapper .shape-2 {
    top: -25%;
    left: 60%;
  }
}
@media (max-width: 1199.98px) {
  .taxonomy-shape-wrapper .shape-2 {
    left: 65%;
  }
}
@media (max-width: 991.98px) {
  .taxonomy-shape-wrapper .shape-2 {
    top: 0;
    left: 60%;
  }
}
.taxonomy-shape-wrapper .shape-3 {
  top: 25%;
  left: -15%;
  background: #b8ffe1;
  opacity: 0.3;
}
.taxonomy-shape-wrapper .shape-4 {
  top: 26%;
  left: 40%;
  background: #fbcaff;
  opacity: 0.35;
  z-index: 1;
}
.taxonomy .page-hadding {
  position: relative;
  padding-bottom: 8.5rem;
}
@media (max-width: 1399.98px) {
  .taxonomy .page-hadding {
    padding-bottom: 3.5rem;
  }
}
@media (max-width: 575.98px) {
  .taxonomy .page-hadding {
    padding-bottom: 2.5rem;
  }
}
.taxonomy .page-hadding a,
.taxonomy .page-hadding span {
  color: #060A11;
  font-size: 4rem;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
}
@media (max-width: 991.98px) {
  .taxonomy .page-hadding a,
.taxonomy .page-hadding span {
    font-size: 2rem;
  }
}
.taxonomy .page-hadding span {
  position: relative;
  margin-left: 4rem;
}
@media (max-width: 991.98px) {
  .taxonomy .page-hadding span {
    margin-left: 2rem;
  }
}
.taxonomy .page-hadding span::before {
  position: absolute;
  content: "";
  background-color: #060A11;
  width: 60px;
  height: 8px;
  top: 50%;
  left: -30%;
  transform: rotate(118deg);
}
@media (max-width: 991.98px) {
  .taxonomy .page-hadding span::before {
    width: 35px;
    height: 5px;
    left: -30%;
  }
}
.taxonomy .card .card-body {
  padding: 2rem 0 1.5rem;
  width: 100%;
}
.taxonomy .card .card-body .blog-post-meta ul li span {
  padding-left: 0;
}
.taxonomy .card .card-body .blog-post-meta ul li span::before {
  display: none;
}
.taxonomy .card:hover .blog-post-meta ul li a {
  color: #060A11;
}
.taxonomy .card:hover a h2,
.taxonomy .card:hover a h5 {
  color: #404152;
}

/********** 
13. Pagination Section Styles
**********/
.pagination {
  align-items: center;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .pagination {
    justify-content: center;
  }
}
.pagination .page-item {
  margin-right: 0.5rem;
}
@media (max-width: 575.98px) {
  .pagination .page-item {
    margin-right: 0.25rem;
  }
}
.pagination .page-item:first-child {
  margin-right: 45rem;
  border: none;
}
@media (max-width: 1399.98px) {
  .pagination .page-item:first-child {
    margin-right: 39rem;
  }
}
@media (max-width: 1199.98px) {
  .pagination .page-item:first-child {
    margin-right: 30rem;
  }
}
@media (max-width: 991.98px) {
  .pagination .page-item:first-child {
    margin-right: 15rem;
  }
}
@media (max-width: 767.98px) {
  .pagination .page-item:first-child {
    margin-right: 2rem;
  }
}
@media (max-width: 575.98px) {
  .pagination .page-item:first-child {
    margin-right: 0rem;
    display: none;
  }
}
.pagination .page-item:first-child .page-link:hover {
  background-color: rgba(7, 12, 19, 0);
  border-color: transparent;
}
.pagination .page-item:last-child {
  margin-right: 0;
}
.pagination .page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.pagination .page-item:last-child .page-link {
  display: block;
}
.pagination .page-item:last-child .page-link svg {
  transform: translate(-5px, 4px);
}
@media (max-width: 575.98px) {
  .pagination .page-item:last-child .page-link svg {
    transform: translate(-6px, 3px);
    width: 15px;
    height: 15px;
  }
}
.pagination .page-item .page-link {
  font-size: 20px;
  line-height: 1.5;
  color: #060A11;
  padding: 0.2rem 0.8rem;
  border: 1px solid rgba(7, 12, 19, 0);
  text-decoration: none;
  position: relative;
  display: block;
  background-color: #fff;
  border-radius: 8px;
  font-weight: 700;
  width: 40px;
  height: 40px;
}
.pagination .page-item .page-link .icon {
  margin-left: 1rem;
}
@media (max-width: 575.98px) {
  .pagination .page-item .page-link .icon {
    margin-left: 0rem;
  }
}
.pagination .page-item .page-link svg {
  display: block;
}
.pagination .page-item .page-link:hover {
  background-color: rgba(7, 12, 19, 0.04);
  border-color: transparent;
}
.pagination .page-item .page-link:focus {
  background-color: #fff;
  color: #060A11;
  box-shadow: none;
}
.pagination .page-item.active .page-link {
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 3;
  background-color: rgba(7, 12, 19, 0.04);
  border-color: rgba(7, 12, 19, 0.1);
}
.pagination .page-item.disabled .page-link {
  width: 100%;
  display: flex;
  align-items: center;
  color: #060A11;
  pointer-events: painted;
  font-weight: 700 !important;
  font-size: 20px !important;
  background-color: #fff;
  border-color: rgba(7, 12, 19, 0);
  padding: 0.2rem 0 !important;
}
.pagination .page-item.disabled .page-link:hover {
  background-color: rgba(7, 12, 19, 0);
  border-color: transparent;
}

/********** 
14. contact Page Styles
**********/
/* Defult Form Control styles */
.form-control {
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7;
  color: #404152;
  background-color: #fff;
  border: 1px solid #cbd4db;
  appearance: none;
}
.form-control:focus {
  border-color: #060a11;
  outline: 0;
  box-shadow: none;
}
.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #404152;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7;
  color: #404152;
}
.form-control:-ms-input-placeholder {
  /* Internet Explorer */
  color: #404152;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7;
  color: #404152;
}
.form-control::placeholder {
  color: #404152;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7;
  color: #404152;
}

/* Default Form Control styles */
.form-select {
  display: block;
  width: 100%;
  padding: 1rem 2.25rem 1rem 0.75rem;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7;
  color: #404152;
  background-color: #fff;
  border: 1px solid #cbd4db;
}
.form-select:focus {
  border-color: #060a11;
  outline: 0;
  box-shadow: none;
  outline: 0;
}

/* Contact Page Form Style */
.contact-form {
  position: relative;
}
.contact-form-shape-wrapper {
  overflow: hidden;
  z-index: -1;
  bottom: 0%;
}
.contact-form-shape-wrapper .shape-1,
.contact-form-shape-wrapper .shape-2,
.contact-form-shape-wrapper .shape-3,
.contact-form-shape-wrapper .shape-4,
.contact-form-shape-wrapper .shape-5 {
  position: absolute;
  border-radius: 50%;
  width: 686px;
  height: 686px;
  left: -12%;
  top: -20%;
  background: #b9ffe2;
  mix-blend-mode: multiply;
  opacity: 0.5;
  filter: blur(60px);
}
@media (max-width: 1399.98px) {
  .contact-form-shape-wrapper .shape-1,
.contact-form-shape-wrapper .shape-2,
.contact-form-shape-wrapper .shape-3,
.contact-form-shape-wrapper .shape-4,
.contact-form-shape-wrapper .shape-5 {
    left: -18%;
    top: -25%;
  }
}
@media (max-width: 1199.98px) {
  .contact-form-shape-wrapper .shape-1,
.contact-form-shape-wrapper .shape-2,
.contact-form-shape-wrapper .shape-3,
.contact-form-shape-wrapper .shape-4,
.contact-form-shape-wrapper .shape-5 {
    left: -30%;
    top: -30%;
  }
}
@media (max-width: 991.98px) {
  .contact-form-shape-wrapper .shape-1,
.contact-form-shape-wrapper .shape-2,
.contact-form-shape-wrapper .shape-3,
.contact-form-shape-wrapper .shape-4,
.contact-form-shape-wrapper .shape-5 {
    left: -40%;
    top: -20%;
  }
}
.contact-form-shape-wrapper .shape-2 {
  left: 77%;
  top: 0;
  opacity: 1;
  background: #cdf6ff;
}
@media (max-width: 1199.98px) {
  .contact-form-shape-wrapper .shape-2 {
    left: 70%;
    top: -30%;
  }
}
@media (max-width: 991.98px) {
  .contact-form-shape-wrapper .shape-2 {
    left: 50%;
    top: -20%;
  }
}
.contact-form-shape-wrapper .shape-3 {
  left: 5%;
  top: 20%;
  background: #fffdcd;
}
@media (max-width: 1399.98px) {
  .contact-form-shape-wrapper .shape-3 {
    left: 15%;
    top: 12%;
  }
}
.contact-form-shape-wrapper .shape-4 {
  left: 40%;
  top: 30%;
  width: 898px;
  height: 807px;
  background: #fbcaff;
  opacity: 0.35;
}
@media (max-width: 1399.98px) {
  .contact-form-shape-wrapper .shape-4 {
    left: 25%;
  }
}
@media (max-width: 1199.98px) {
  .contact-form-shape-wrapper .shape-4 {
    left: 10%;
  }
}
.contact-form-shape-wrapper .shape-5 {
  left: 6%;
  top: 65%;
  width: 486px;
  height: 486px;
  background: #86ffcc;
  opacity: 0.3;
}
@media (max-width: 1399.98px) {
  .contact-form-shape-wrapper .shape-5 {
    left: 0%;
    top: 60%;
  }
}
.contact-form .section-header {
  padding-bottom: 7.5rem;
}
@media (max-width: 1399.98px) {
  .contact-form .section-header {
    padding-bottom: 3.5rem;
  }
}
.contact-form .section-header h2 {
  font-size: 4rem;
}
@media (max-width: 991.98px) {
  .contact-form .section-header h2 {
    font-size: 2rem;
  }
}
.contact-form form {
  background-color: #fff;
  padding: 3.75rem;
  margin-right: 2px;
  border-radius: 5px;
}
@media (max-width: 991.98px) {
  .contact-form form {
    padding: 2rem;
    text-align: center;
    margin: 0 0 1rem 0;
  }
}
@media (max-width: 767.98px) {
  .contact-form form {
    padding: 2rem 0;
  }
}
.contact-form form h3 {
  color: #404152;
  font-weight: 700;
  margin: 0rem 0 3.5rem;
}
.contact-form form .form-control,
.contact-form form .form-select {
  margin-bottom: 1.25rem;
  padding-left: 1.5rem;
  border-radius: 8px;
}
.contact-form-widget {
  background-color: #fff;
  border-radius: 5px;
  padding: 4rem 2.5rem 2rem;
}
@media (max-width: 1199.98px) {
  .contact-form-widget {
    padding: 4rem 1.5rem 2rem;
  }
}
@media (max-width: 991.98px) {
  .contact-form-widget {
    text-align: center;
    padding-bottom: 0;
  }
}
.contact-form-widget img {
  margin-bottom: 1rem;
}
.contact-form-widget h6 {
  color: #404152;
  font-size: 18px;
  margin-bottom: 5rem;
}
@media (max-width: 1399.98px) {
  .contact-form-widget h6 {
    margin-bottom: 3rem;
  }
}
@media (max-width: 1199.98px) {
  .contact-form-widget h6 {
    margin-bottom: 2rem;
  }
}
.contact-form-widget li {
  margin-bottom: 1.25rem;
}
.contact-form-widget li:last-child {
  margin-bottom: 0;
}
.contact-form-widget li span {
  display: block;
}
.contact-form-widget li a {
  color: #404152;
  font-size: 1.25rem;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
}

/********** 
15.  404 Page Styles
**********/
.error {
  position: relative;
  background: linear-gradient(239.24deg, #f4f7fe 22.34%, #fbfcff 83.1%);
}
.error-shape-wrapper {
  overflow: hidden;
  bottom: 0%;
}
.error-shape-wrapper .shape-1,
.error-shape-wrapper .shape-2 {
  position: absolute;
  border-radius: 50%;
  width: 686px;
  height: 580px;
  left: -10%;
  top: -15%;
  background: #b9ffe2;
  mix-blend-mode: multiply;
  opacity: 0.5;
  filter: blur(60px);
}
@media (max-width: 1399.98px) {
  .error-shape-wrapper .shape-1,
.error-shape-wrapper .shape-2 {
    left: -20%;
    top: -20%;
  }
}
@media (max-width: 1199.98px) {
  .error-shape-wrapper .shape-1,
.error-shape-wrapper .shape-2 {
    left: -30%;
    top: -30%;
  }
}
.error-shape-wrapper .shape-2 {
  width: 712px;
  height: 712px;
  left: 70%;
  top: -10%;
  opacity: 1;
  background: #cdf6ff;
}
@media (max-width: 1399.98px) {
  .error-shape-wrapper .shape-2 {
    left: 75%;
    top: -15%;
  }
}
@media (max-width: 1199.98px) {
  .error-shape-wrapper .shape-2 {
    top: -30%;
  }
}
.error-content {
  position: relative;
}
.error-content h3 {
  color: #404152;
  font-weight: 700;
}
.error-content img {
  max-width: 100%;
}

/********** 
16.  Hero inner Page Section Styles
**********/
.page-hero {
  position: relative;
  padding-top: 13.75rem;
  padding-bottom: 8.75rem;
}
.page-hero-shape-wrapper {
  overflow: hidden;
  z-index: -1;
  bottom: 0%;
}
.page-hero-shape-wrapper .shape-1,
.page-hero-shape-wrapper .shape-2 {
  position: absolute;
  border-radius: 50%;
  width: 686px;
  height: 580px;
  left: -10%;
  top: -10%;
  background: #ffb9b9;
  mix-blend-mode: multiply;
  opacity: 0.5;
  filter: blur(60px);
}
@media (max-width: 1399.98px) {
  .page-hero-shape-wrapper .shape-1,
.page-hero-shape-wrapper .shape-2 {
    left: -20%;
    top: -30%;
  }
}
@media (max-width: 991.98px) {
  .page-hero-shape-wrapper .shape-1,
.page-hero-shape-wrapper .shape-2 {
    left: -40%;
    top: -40%;
  }
}
@media (max-width: 767.98px) {
  .page-hero-shape-wrapper .shape-1,
.page-hero-shape-wrapper .shape-2 {
    left: -70%;
  }
}
@media (max-width: 575.98px) {
  .page-hero-shape-wrapper .shape-1,
.page-hero-shape-wrapper .shape-2 {
    left: -130%;
  }
}
.page-hero-shape-wrapper .shape-2 {
  left: 80%;
  top: 0%;
  opacity: 0.9;
  background: #e3ffcd;
}
@media (max-width: 1199.98px) {
  .page-hero-shape-wrapper .shape-2 {
    left: 70%;
    top: -30%;
  }
}
@media (max-width: 991.98px) {
  .page-hero-shape-wrapper .shape-2 {
    top: -40%;
  }
}
@media (max-width: 767.98px) {
  .page-hero-shape-wrapper .shape-2 {
    left: 60%;
  }
}
@media (max-width: 1399.98px) {
  .page-hero {
    padding-top: 10rem;
    padding-bottom: 4rem;
  }
}
@media (max-width: 991.98px) {
  .page-hero {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .page-hero {
    padding-top: 9rem;
  }
}
@media (max-width: 575.98px) {
  .page-hero {
    padding-bottom: 1.5rem;
  }
}
.page-hero .page-heading-title {
  margin-bottom: 6.5rem;
}
@media (max-width: 1399.98px) {
  .page-hero .page-heading-title {
    margin-bottom: 4.5rem;
  }
}
@media (max-width: 575.98px) {
  .page-hero .page-heading-title {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .page-hero .page-heading-title h1 {
    font-size: 2rem;
  }
}
.page-hero .page-heading-content .item:nth-child(1), .page-hero .page-heading-content .item:nth-child(2), .page-hero .page-heading-content .item:nth-child(3) {
  margin-bottom: 3.5rem;
}
@media (max-width: 575.98px) {
  .page-hero .page-heading-content .item:nth-child(1), .page-hero .page-heading-content .item:nth-child(2), .page-hero .page-heading-content .item:nth-child(3) {
    margin-bottom: 2rem;
  }
}
.page-hero .page-heading-content .item:nth-child(2) {
  margin-bottom: 0rem;
}
@media (max-width: 575.98px) {
  .page-hero .page-heading-content .item:nth-child(2) {
    margin-bottom: 2rem;
  }
}
.page-hero .page-heading-content .item span {
  font-size: 18px;
  font-weight: 500;
  color: #404152;
  margin-bottom: 0.5rem;
  display: block;
}
.page-hero .page-heading-content .item a {
  font-family: "Manrope", sans-serif;
  color: #404152;
}
.page-hero .page-heading-content .item .title {
  font-size: 20px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
}

/********** 
17.  Footer Section Styles
**********/
.footer {
  position: relative;
  padding-bottom: 90px;
  background: #fff;
}
@media (max-width: 991.98px) {
  .footer {
    padding-bottom: 60px;
  }
}
.footer-shape-wrapper {
  overflow: hidden;
  bottom: 0%;
}
.footer-shape-wrapper .shape-1,
.footer-shape-wrapper .shape-2,
.footer-shape-wrapper .shape-3 {
  position: absolute;
  border-radius: 50%;
  width: 1277px;
  height: 1286px;
  left: 18%;
  top: 20%;
  background: #f6ff8e;
  mix-blend-mode: multiply;
  opacity: 0.4;
  filter: blur(60px);
}
@media (max-width: 1399.98px) {
  .footer-shape-wrapper .shape-1,
.footer-shape-wrapper .shape-2,
.footer-shape-wrapper .shape-3 {
    left: 0%;
  }
}
@media (max-width: 991.98px) {
  .footer-shape-wrapper .shape-1,
.footer-shape-wrapper .shape-2,
.footer-shape-wrapper .shape-3 {
    left: -20%;
  }
}
@media (max-width: 767.98px) {
  .footer-shape-wrapper .shape-1,
.footer-shape-wrapper .shape-2,
.footer-shape-wrapper .shape-3 {
    left: -70%;
  }
}
.footer-shape-wrapper .shape-2,
.footer-shape-wrapper .shape-3 {
  width: 735px;
  height: 740px;
  left: 25%;
  top: 10%;
  background: #8eebff;
  mix-blend-mode: screen;
  opacity: 0.65;
}
@media (max-width: 991.98px) {
  .footer-shape-wrapper .shape-2,
.footer-shape-wrapper .shape-3 {
    left: 0%;
  }
}
@media (max-width: 767.98px) {
  .footer-shape-wrapper .shape-2,
.footer-shape-wrapper .shape-3 {
    left: -60%;
  }
}
.footer-shape-wrapper .shape-3 {
  left: 61%;
  background: #8eebff;
}
@media (max-width: 991.98px) {
  .footer-shape-wrapper .shape-3 {
    left: 20%;
  }
}
@media (max-width: 767.98px) {
  .footer-shape-wrapper .shape-3 {
    left: 40%;
  }
}
.footer h2 {
  font-size: 4rem;
}
@media (max-width: 991.98px) {
  .footer h2 {
    font-size: 3rem;
  }
}
@media (max-width: 767.98px) {
  .footer h2 {
    font-size: 2rem;
  }
}
@media (max-width: 575.98px) {
  .footer h2 {
    font-size: 1.75rem;
  }
}
.footer-wraper {
  position: relative;
  padding-top: 4rem;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 100;
}
@media (max-width: 991.98px) {
  .footer-wraper {
    display: block;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .footer-wraper {
    padding-top: 3rem;
  }
}
@media (max-width: 575.98px) {
  .footer-wraper {
    padding-top: 4rem;
  }
}
@media (max-width: 991.98px) {
  .footer-description {
    margin-bottom: 3rem;
  }
}
.footer-description ul {
  margin-bottom: 0rem;
  display: flex;
  align-items: center;
}
@media (max-width: 991.98px) {
  .footer-description ul {
    justify-content: center;
  }
}
.footer-description ul li {
  display: inline-block;
  margin-right: 1rem;
}
.footer-description ul li:last-child {
  margin-right: 0rem;
}
.footer-description ul li a {
  background-color: #404152;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0px);
  transition: all 0.3s ease-in-out;
}
.footer-description ul li a svg path {
  transition: all 0.3s ease-in-out;
  fill: #fff;
}
.footer-description ul li a:hover {
  background-color: #060A11;
  transform: translateY(-10px);
}
.footer-description ul li a:hover svg path {
  fill: #fff;
}
.footer-widget {
  text-align: right;
}
@media (max-width: 991.98px) {
  .footer-widget {
    text-align: center;
  }
}
.footer-widget ul {
  margin-bottom: 0rem;
}
.footer-widget ul li {
  display: inline-block;
}
.footer-widget ul li a {
  color: #404152;
  transition: all 0.3s ease-in-out;
}
.footer-widget ul li a:hover {
  color: #060A11;
}