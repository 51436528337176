/********** 
12. Blog  Page Styles 
**********/
@use "sass:math";
.blog,
.related-blog,
.taxonomy {
  position: relative;
  &-shape-wrapper {
    overflow: hidden;
    bottom: 0%;
    .shape-1,
    .shape-2,
    .shape-3,
    .shape-4,
    .shape-5 {
      position: absolute;
      border-radius: 50%;
      width: 686px;
      height: 686px;
      left: -13%;
      top: -10%;
      z-index: -1;
      background: #fffa8d;
      mix-blend-mode: multiply;
      opacity: 0.5;
      filter: blur(60px);
      @include media-breakpoint-down-xxl {
        left: -20%;
        top: -15%;
      }
      @include media-breakpoint-down-lg {
        top: -5%;
        left: -40%;
      }
      @include media-breakpoint-down-md {
        left: -70%;
      }
      @include media-breakpoint-down-sm {
        left: -130%;
      }
    }
    .shape-2 {
      left: 80%;
      top: -10%;
      background: #e2ddff;
      opacity: 1;
      @include media-breakpoint-down-xxl {
        left: 70%;
        top: -15%;
      }
      @include media-breakpoint-down-lg {
        top: -5%;
        left: 60%;
      }
    }
    .shape-3 {
      top: 35%;
      left: -15%;
      background: #b8ffe1;
      opacity: 0.3;
    }
    .shape-4 {
      top: 26%;
      left: 40%;
      background: #fbcaff;
      opacity: 0.35;
      z-index: 1;
    }

    .shape-5 {
      top: 56%;
      left: 50%;
      background: #b8ffe1;
      opacity: 0.3;
      z-index: 1;
    }
  }
  .section-header {
    h2 {
      font-size: $base-font-size * 4;
      @include media-breakpoint-down-lg {
        font-size: $base-font-size * 3;
      }
      @include media-breakpoint-down-sm {
        font-size: $base-font-size * 2;
      }
    }
  }
  .card {
    align-items: center;
    box-shadow: none;
    border-radius: 0;
    padding: $base-padding * 0;
    transition: $base-transition;
    background: none;
    overflow: hidden;
    z-index: 100;
    @include media-breakpoint-down-sm {
      margin-bottom: $base-margin * 1.5;
    }
    .blog-post-item-thumb {
      width: 50%;
      margin-right: $base-margin * 2;
      margin-bottom: $base-margin * 1.5;
      overflow: hidden;
      border-radius: 5px;
      @include media-breakpoint-down-xl {
        margin-right: $base-margin * 2;
      }
      @include media-breakpoint-down-lg {
        width: 100%;
        margin-right: $base-margin * 0;
      }
      img {
        transform: scale(1);
        transition: $base-transition;
      }
    }
    .blog-post-content,
    .card-body {
      width: 50%;
      z-index: 9;
      @include media-breakpoint-down-lg {
        width: 100%;
        text-align: center;
        margin-bottom: $base-margin;
      }
      @include media-breakpoint-down-sm {
        margin-bottom: $base-margin * 0;
      }
      .blog-post-meta {
        ul {
          display: flex;
          align-items: center;
          @include media-breakpoint-down-lg {
            justify-content: center;
          }
          li {
            font-weight: normal;
            color: #aaaab7;
            a {
              transition: $base-transition;
              color: #aaaab7;
              &:hover {
                color: $color-header;
              }
            }
            span {
              padding-left: $base-padding;
              position: relative;
              color: #aaaab7;
              &::before {
                position: absolute;
                content: "";
                background-color: #aaaab7;
                width: 20px;
                height: 2px;
                top: 10px;
                left: -5px;
                transform: rotate(118deg);
              }
            }
          }
        }
      }
      .blog-post-body {
        &-title {
          a {
            h2 {
              transition: $base-transition;
              line-height: 60px;
              margin-bottom: $base-margin * 2.5;
              @include media-breakpoint-down-xxl {
                font-size: 32px;
                line-height: 40px;
              }
              @include media-breakpoint-down-xl {
                margin-bottom: $base-margin * 1;
              }
            }

            span {
              display: flex;
              align-items: center;
              transition: $base-transition;
              i {
                margin-right: math.div($base-margin, 2);
              }
            }
          }
          p {
            margin-bottom: 0;
          }
        }
      }
    }
    // Recent Blog style
    .card-body {
      padding: 2rem 0 1.5rem;
      width: 100%;
      @include media-breakpoint-down-sm {
        padding: 1rem 0;
      }
      h5 {
        transition: $base-transition;
      }
      .blog-post-meta {
        margin-bottom: $base-margin * 1.3;
        @include media-breakpoint-down-sm {
          margin-bottom: 0;
        }
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
      a {
        h2,
        h5 {
          color: #404152;
        }
      }
    }
    &-img {
      overflow: hidden;
      border-radius: 5px;
      img {
        transform: scale(1);
        transition: $base-transition;
      }
    }
  }

  // Start blog-details style
  &-details {
    position: relative;
    &-shape-wrapper {
      overflow: hidden;
      z-index: -1;
      bottom: 0%;
      .shape-1,
      .shape-2 {
        position: absolute;
        border-radius: 50%;
        width: 686px;
        height: 686px;
        left: -10%;
        top: -2%;
        background: #d8b9ff;
        mix-blend-mode: multiply;
        opacity: 0.5;
        filter: blur(60px);
        @include media-breakpoint-down-xxl {
          left: -15%;
          top: -5%;
        }
        @include media-breakpoint-down-xl {
          left: -20%;
        }
        @include media-breakpoint-down-lg {
          left: -40%;
        }
        @include media-breakpoint-down-md {
          left: -70%;
        }
        @include media-breakpoint-down-sm {
          left: -100%;
        }
      }
      .shape-2 {
        left: 77%;
        top: 0;
        background: #fffdcd;
        @include media-breakpoint-down-xxl {
          left: 70%;
          top: -2%;
        }
        @include media-breakpoint-down-lg {
          left: 60%;
          top: -5%;
        }
        @include media-breakpoint-down-sm {
          left: 30%;
        }
      }
    }
    .card {
      box-shadow: none;
      border-radius: 0;
      padding: $base-padding * 0;
      background: none;
      @include media-breakpoint-down-xl {
        padding: $base-padding * 1 0 0 0;
      }
      @include media-breakpoint-down-lg {
        text-align: center;
        padding: $base-padding * 4 0 0 0;
      }
      .blog-des-hero {
        width: 72%;
        margin-bottom: $base-margin * 4;
        @include media-breakpoint-down-xl {
          width: 80%;
          margin-bottom: $base-margin * 3;
        }
        @include media-breakpoint-down-lg {
          width: 100%;
        }
        h2 {
          margin-bottom: $base-margin * 2.5;
        }
        &-content {
          @include media-breakpoint-down-lg {
            justify-content: center;
          }
          span {
            font-size: 18px;
            font-weight: $medium-font-weight;
            color: $color-text;
            display: block;
            margin-bottom: math.div($base-margin, 2);
          }
          .title {
            font-size: 20px;
            font-weight: $extbold-font-weight;
            font-family: $font-secondary;
          }
        }
      }
      .feature-image {
        margin-bottom: $base-margin * 8.5;
        @include media-breakpoint-down-xxl {
          margin-bottom: $base-margin * 5.5;
        }
        @include media-breakpoint-down-xl {
          margin-bottom: $base-margin * 3.5;
        }
        @include media-breakpoint-down-lg {
          margin-bottom: $base-margin * 3;
        }
        @include media-breakpoint-down-md {
          margin-bottom: $base-margin * 2;
        }
        img {
          border-radius: 10px;
          margin-bottom: $base-margin * 0;
        }
      }
      ul {
        margin-bottom: $base-margin * 7.5;
        @include media-breakpoint-down-xxl {
          margin-bottom: $base-margin * 3.5;
        }
        @include media-breakpoint-down-lg {
          text-align: left;
          margin-bottom: $base-margin * 3;
        }
        li {
          display: flex;
          align-items: center;
          margin-bottom: $base-margin * 2;
          &:last-child {
            margin-bottom: 0;
          }
          @include media-breakpoint-down-lg {
            align-items: start;
          }
          i {
            font-size: $base-font-size * 1.25;
            color: $color-header;
            padding-right: $base-padding * 2;
          }
        }
      }
      img {
        border-radius: 5px;
        margin-bottom: $base-margin * 7.5;
        @include media-breakpoint-down-xxl {
          margin-bottom: $base-margin * 3.5;
        }
        @include media-breakpoint-down-xl {
          margin-bottom: $base-margin * 2.5;
        }
        @include media-breakpoint-down-lg {
          margin-bottom: $base-margin * 3;
        }
        @include media-breakpoint-down-md {
          margin-bottom: $base-margin * 2;
        }
      }
      h3 {
        width: 85%;
        font-weight: $bold-font-weight;
        margin-bottom: $base-margin * 3.75;
        @include media-breakpoint-down-xl {
          margin-bottom: $base-margin * 2.5;
        }
        @include media-breakpoint-down-lg {
          width: 100%;
        }
        @include media-breakpoint-down-md {
          margin-bottom: $base-margin * 2;
        }
      }
      p {
        margin-bottom: $base-margin * 3.75;
        line-height: 2;
        @include media-breakpoint-down-lg {
          margin-bottom: $base-margin * 2;
        }
      }
    }
    .banner{
      gap:2rem;
    }
  }
  // End blog-details style
  .recent-blog {
    &-title {
      font-size: $base-font-size * 3;
    }
  }
}
// Start taxonomy style
.taxonomy {
  padding-top: $base-padding * 15;
  @include media-breakpoint-down-xxl {
    padding-top: $base-padding * 10;
  }
  @include media-breakpoint-down-sm {
    padding-top: $base-padding * 8;
  }
  &-shape-wrapper {
    overflow: hidden;
    bottom: 0%;
    .shape-1,
    .shape-2,
    .shape-3,
    .shape-4 {
      position: absolute;
      border-radius: 50%;
      width: 686px;
      height: 686px;
      left: -13%;
      top: -10%;
      z-index: -1;
      background: #fffa8d;
      mix-blend-mode: multiply;
      opacity: 0.5;
      filter: blur(60px);
      @include media-breakpoint-down-xxl {
        top: -25%;
      }
      @include media-breakpoint-down-xl {
        top: -30%;
        left: -25%;
      }
      @include media-breakpoint-down-lg {
        top: 0;
        left: -30%;
      }
      @include media-breakpoint-down-md {
        left: -70%;
      }
      @include media-breakpoint-down-sm {
        left: -130%;
      }
    }
    .shape-2 {
      left: 80%;
      top: -4%;
      opacity: 1;
      background: #e2ddff;
      @include media-breakpoint-down-xxl {
        top: -25%;
        left: 60%;
      }
      @include media-breakpoint-down-xl {
        left: 65%;
      }
      @include media-breakpoint-down-lg {
        top: 0;
        left: 60%;
      }
    }
    .shape-3 {
      top: 25%;
      left: -15%;
      background: #b8ffe1;
      opacity: 0.3;
    }
    .shape-4 {
      top: 26%;
      left: 40%;
      background: #fbcaff;
      opacity: 0.35;
      z-index: 1;
    }
  }
  .page-hadding {
    position: relative;
    padding-bottom: $base-padding * 8.5;
    @include media-breakpoint-down-xxl {
      padding-bottom: $base-padding * 3.5;
    }
    @include media-breakpoint-down-sm {
      padding-bottom: $base-padding * 2.5;
    }
    a,
    span {
      color: $color-header;
      font-size: $base-font-size * 4;
      font-family: $font-secondary;
      font-weight: $extbold-font-weight;
      @include media-breakpoint-down-lg {
        font-size: $base-font-size * 2;
      }
    }
    span {
      position: relative;
      margin-left: $base-margin * 4;
      @include media-breakpoint-down-lg {
        margin-left: $base-margin * 2;
      }
      &::before {
        position: absolute;
        content: "";
        background-color: $color-header;
        width: 60px;
        height: 8px;
        top: 50%;
        left: -30%;
        transform: rotate(118deg);
        @include media-breakpoint-down-lg {
          width: 35px;
          height: 5px;
          left: -30%;
        }
      }
    }
  }
  .card {
    .card-body {
      padding: 2rem 0 1.5rem;
      width: 100%;
      .blog-post-meta {
        ul {
          li {
            span {
              padding-left: 0;
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
    &:hover {
      .blog-post-meta {
        ul {
          li {
            a {
              color: $color-header;
            }
          }
        }
      }
      a {
        h2,
        h5 {
          color: #404152;
        }
      }
    }
  }
}
// End taxonomy style
