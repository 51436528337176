/********** 
 3. Grid  Styles 
**********/
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.875rem);
  padding-left: var(--bs-gutter-x, 0.875rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1080px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1170px;
  }
}

@for $i from 2 to 21 {
  .w-#{$i*5} {
    width: #{$i * 5%} !important;
  }
}

@include media-breakpoint-up-sm {
  @for $i from 2 to 21 {
    .w-sm-#{$i*5} {
      width: #{$i * 5%} !important;
    }
  }
}
@include media-breakpoint-up-md {
  @for $i from 2 to 21 {
    .w-md-#{$i*5} {
      width: #{$i * 5%} !important;
    }
  }
}
@include media-breakpoint-up-lg{
  @for $i from 2 to 21 {
    .w-lg-#{$i*5} {
      width: #{$i * 5%} !important;
    }
  }
}
@include media-breakpoint-up-xl{
  @for $i from 2 to 21 {
    .w-xl-#{$i*5} {
      width: #{$i * 5%} !important;
    }
  }
}

@include media-breakpoint-up-xxl {
  @for $i from 2 to 21 {
    .w-xxl-#{$i*5} {
      width: #{$i * 5%} !important;
    }
  }
}

